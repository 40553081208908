import styled from "styled-components";
import { minHeight, desktopWidth } from "../../constants/settings";
import { backgroundGradient, minMediaWidth } from "../../constants/mixins";
import { colors } from "../../core/theme";

const LoginGrid = styled.div<{
  columns: string[];
}>`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
  min-height: ${minHeight}px;

  ${({ columns }) => {
    const rules = `
      display: grid;
      grid-template-rows: none;
      grid-template-columns:${columns.join(" ")};
    `;
    return minMediaWidth(desktopWidth, rules);
  }}
`;

const LoginCell = styled.div<{
  color: string;
  gradient?: string[];
  backgroundColor?: string;
}>`
  color: ${(props) => props.color};
  ${({ gradient }) => gradient && backgroundGradient(gradient, "to right")}
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
`;

const LoginColumn = styled.div<{
  alignItems?: string;
  justifyContent?: string;
  gap?: string;
  padding?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 1rem;
  ${({ padding }) => padding && `padding: ${padding}; box-sizing: border-box;`}
  ${({ gap, alignItems, justifyContent }) => {
    const rules = `
      ${gap && `gap: ${gap};`}
      ${alignItems && `align-items: ${alignItems};`}
      ${justifyContent && `justify-content: ${justifyContent};`}
    `;
    return minMediaWidth(desktopWidth, rules);
  }}
`;

const LoginWelcomeMessage = styled.div`
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  color: inherit;
  ${() => {
    const rules = `
      font-size: 5vw;
      text-align: left;
    `;
    return minMediaWidth(desktopWidth, rules);
  }}
`;

const LoginErrorMessage = styled.div`
  background-color: #ffe2db;
  color: #9d3126;
  font-size: 1.2rem;
  padding: 1em 2em;
  box-sizing: border-box;
  text-align: center;
  grid-column: span 2;
  border-radius: 0.25em;
`;
const LoginSuccessMesage = styled.div`
  background-color: #c0e8ab;
  color: #136913;
  font-size: 1.2rem;
  padding: 1em 2em;
  box-sizing: border-box;
  text-align: center;
  grid-column: span 2;
  border-radius: 0.25em;
`;

const LoginAnchor = styled.a`
  color: inherit;
  font-weight: 500;
  &.right {
    text-align: right;
  }
  &.gray {
    color: #9c9ea0;
  }
  margin: 0;
`;

const Icon = styled.i`
  width: 32px;
  height: 30px;
  justify-self: end;
  position: absolute;
  margin-right: 20px;
`;

const LoginFromText = styled.div`
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
  color: #9c9ea0;
`;

const LoginHints = styled.div`
  color: ${colors.darkGray};
  font-size: 1rem;
  line-height: 1.25;
`;

const LoginForm = styled.form<{
  columns?: string[];
}>`
  width: calc(100% - 6rem);
  max-width: 700px;
  gap: 12px;
  align-items: center;
  ${({ columns }) => {
    if (columns) {
      return `
        display: block;
        grid-template-columns: ${columns.join(" ")};
        &>input:not([type="submit"]) { grid-column: span ${columns.length}};
      `;
    } else {
      return `
        display: flex;
        flex-direction: column;
      `;
    }
  }}
  ${() => minMediaWidth(desktopWidth, "gap: 24px;")}
`;

const LoginInputText = styled.input<{
  fontSize: string;
}>`
  width: 100%;
  border: none;
  margin: 10px 0 16px 0;
  background-color: white;
  color: black;
  border-radius: 0.5em;
  padding: 0.75em 1.5em;
  font-size: 1.25rem;
  ${({ fontSize }) => {
    const rules = `
      font-size: ${fontSize};
    `;
    return minMediaWidth(desktopWidth, rules);
  }}
`;
const Row = styled.div`
  width: 100%;
  display: grid;
  margin-bottom: 5px;
  align-items: center;
`;

const LoginButton = styled.input<{
  fontSize: string;
}>`
  border: none;
  margin-right: 13.1%;
  width: 57.9%;
  color: white;
  border-radius: 0.5em;
  padding: 0.5em 1em;
  font-size: 1.25rem;
  text-align: center;
  cursor: pointer;
  transition: 0.25s;
  background-color: #56beed;
  background-size: 150% 100%;
  background-position: right 120% center;
  background-repeat: no-repeat;
  ${() => backgroundGradient(["transparent", "#0098D8", "#0087C1"], "to right")}
  ${({ fontSize }) => minMediaWidth(desktopWidth, `font-size: ${fontSize};`)}
  &:hover, &:focus {
    background-position: right 0 center;
  }
  &:disabled {
    background-color: ${colors.darkGray};
    background-image: none;
    color: ${colors.lightGray};
    cursor: default;
  }
`;

export {
  LoginGrid,
  LoginCell,
  LoginColumn,
  LoginWelcomeMessage,
  LoginErrorMessage,
  LoginSuccessMesage,
  LoginAnchor,
  Icon,
  Row,
  LoginFromText,
  LoginForm,
  LoginInputText,
  LoginButton,
  LoginHints,
};

import React, { ReactElement, useEffect, useState } from "react";
import { SettingMenuOption } from "../types/settings-menu-option.type";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation } from "react-router-dom";
import { selectFirstActiveFeature } from "../utils/settings-menu.util";

type NavItemProps = {
  menuOption: SettingMenuOption;
};

const transitionClass = ` transition-all ease-out-in duration-200 delay-100 `;
const navItemClass = transitionClass + "cursor-pointer flex gap-1 w-full items-center p-2 text-base font-normal text-gray-400 rounded-lg group hover:text-app-blue-light hover:bg-sky-100";
const navActiveClass = transitionClass+ " text-app-blue-light bg-sky-100 "

const NavItem = ({
    menuOption
}: NavItemProps): ReactElement => {
    const { link, hasSubMenu, label, options, icon, isAvailable, customLabel } = menuOption;
    const { pathname } = useLocation();
    const [isNavLinkActive, setIsNavLinkActive] = useState<boolean>(pathname.includes(link));


    function redirectFirstAvailable(): string {
        if (!hasSubMenu || !options?.length) {
            return link;
        }

        if(link.endsWith("/") && hasSubMenu && isNavLinkActive) return "/settings"

        return selectFirstActiveFeature(options).link;
    }

    useEffect(() => {
        if(link.endsWith("/")) {
            setIsNavLinkActive(pathname.includes(link));
            return;
        }
        
        setIsNavLinkActive(pathname.endsWith(link));
    }, [pathname]);
    

  return (
    <>
      <NavLink role="listitem"
        to={redirectFirstAvailable()}
        key={link + label}
        data-qa-id={`menu${label}`}
        className={`${navItemClass} ${isNavLinkActive ? navActiveClass : ""}`}
        aria-checked={isNavLinkActive}
        aria-disabled={!isAvailable}
        hidden={!isAvailable}
      >
          <FA
            className={`${transitionClass} 
                w-5 h-5 text-gray-400 
                group-hover:text-app-blue-light 
                group-aria-checked:text-app-blue-light`}
            icon={icon}
          />
          <span
            className={`${transitionClass} 
                flex text-gray-400 gap-1 w-full justify-between items-center 
                group-hover:text-app-blue-light 
                group-aria-checked:text-app-blue-light`}
          >
          {customLabel ?? label}
          {hasSubMenu && (
              <FA key={link + label + "arrow"}
                  className={`${transitionClass} 
                    w-5 h-5 text-gray-400 
                    group-hover:text-app-blue-light
                    group-aria-checked:text-app-blue-light
                    group-aria-checked:rotate-90
                `}
                icon={faAngleRight}
              />
            )}
        </span>

      </NavLink>
      {hasSubMenu && isNavLinkActive &&(
        <ul role="list" className="flex flex-col pl-6 py-2 space-y-2">
          {options?.map(
            (subOption) =>
              subOption.isAvailable && (
                <NavItem
                  key={subOption.link + subOption.label}
                  menuOption={subOption}
                />
              )
            )}
        </ul>
      )}
    </>
  );
};

export default NavItem;

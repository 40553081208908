import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Box, Text } from "../../core/ui";
import { USZipCodePattern } from "../../core/utils/patterns/zipCodes";
import { UIButton, UIInputText, UITextArea } from "../../core/ui/UIElements";
import { Contact, ContactMetadata, Gender } from "../../types/ContactTypes";
import updateContactAsync from "../../api/Contacts/updateContactAsync";
import { UIRow } from "../../core/ui/UIStructures";
import { theme } from "../../core/theme";
import WeekdaysSelector from "./WeekdaysSelector";
import { showUIToast } from "../../core/ui/UIToast";
import LeaveVmModal from "../shared/LeaveVmModal";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons/faMicrophone";
import { faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons/faMicrophoneSlash";

const {
  colors: { lightGray },
} = theme;

const Fixed = styled.div`
  width: 100%;
  height: 10%;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

const FormContainer = styled.form`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: scroll;
  overflow-x: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 1849px) {
    display: grid;
    grid-template-columns: 100%;
  }
`;

const LabelContainer = styled.div`
  width: 95%;
  height: 60%;
  display: grid;
  place-items: center;
  grid-template-columns: 100%;
`;

const UIField = styled.div`
  flex: 1;
  min-width: 165px;
`;

const UILabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

// Tip: Some styles for this component was copied from UIInputText component
const SelectInput = styled.select`
  width: 100%;
  color: inherit;
  border: 1px solid ${lightGray};
  border-radius: 0.25rem;
  padding: 0.5em;
  font-size: 1.125rem;
  ${({ multiple }) =>
    multiple &&
    css`
      min-height: 120px;
      overflow-y: hidden;
    `}
`;

type FourMativProps = {
  onPhone: boolean;
  contact: Contact;
  setSubmittedInfo: (submitted: boolean) => void;
  clearDispositionInfo: () => void;
  transferCall: (contactId: string) => void;
  voiceDropsIds: [string] | undefined;
  callId: string;
  callStatus: string | undefined;
  toggleMute: () => void;
  isMuted: boolean;
  isSdr: boolean;
  showForm: boolean;
  submittedDisabled: boolean;
  setSubmittedDisabled: (submittedDisabled: boolean) => void;
  isTransferDisabled: boolean;
  setTransferDisabled: (transferDisabled: boolean) => void;
  isOngoing?: boolean;
};

const defaultFormData: ContactMetadata = {
  ssid: "",
  firstName: "",
  lastName: "",
  dateOfBirth: new Date("2021-01-01"),
  gender: "",
  grade: 0,
  returningHBA: "",
  firstParentGuardian: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    relationToChild: "",
  },
  secondParentGuardian: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    relationToChild: "",
  },
  phoneTwo: "",
  contactNameTwo: "",
  homeAddress: "",
  homeLineTwo: "",
  homeCity: "",
  homeZip: "",
  transportationStatus: "",
  amAddress: "",
  lineTwo: "",
  amCity: "",
  amZip: "",
  pmAddress: "",
  pmLineTwo: "",
  pmCity: "",
  pmZip: "",
  daysOfWeek: [],
  incomingSibling: "",
  additionalNotes: "",
  externalId: "",
  leavingReason: "",
};

const genderOptions = [
  { value: "", label: "Select gender" },
  { value: Gender.Male, label: "Male" },
  { value: Gender.Female, label: "Female" },
  { value: Gender.Other, label: "Other" },
];

const gradeOptions = [
  { value: "", label: "Select grade" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
];

const yesNoOptions = [
  { value: "", label: "Select an option" },
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
const yesNoMaybeOptions = [
  { value: "", label: "Select an option" },
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Maybe", label: "Maybe" },
];

const childRelationshipOptions = [
  { value: "", label: "Select an option" },
  { value: "Mother", label: "Mother" },
  { value: "Father", label: "Father" },
  { value: "Grandparent", label: "Grandparent" },
  { value: "Aunt/Uncle", label: "Aunt/Uncle" },
  { value: "Legal Guardian", label: "Legal Guardian" },
  { value: "Other", label: "Other" },
];

const transportationStatusOptions = [
  { value: "", label: "Select an option" },
  { value: "Riding", label: "Riding" },
  { value: "Not Riding", label: "Not Riding" },
];

const FourMativ = ({
  onPhone,
  contact,
  setSubmittedInfo,
  clearDispositionInfo,
  transferCall,
  voiceDropsIds,
  callId,
  callStatus,
  toggleMute,
  isMuted,
  isSdr,
  showForm,
  submittedDisabled,
  setSubmittedDisabled,
  isTransferDisabled,
  setTransferDisabled,
  isOngoing,
}: FourMativProps): JSX.Element => {
  const [formData, setFormData] =
    React.useState<ContactMetadata>(defaultFormData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showLeaveVmModal, setShowLeaveVmModal] = useState<boolean>(false);

  useEffect(() => {
    !!contact.meta ? setFormData(contact.meta) : setFormData(defaultFormData);
  }, [contact]);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleParentInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setFormData({
      ...formData,
      [e.target.id]: {
        ...formData[e.target.id],
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) return;
    const date = new Date(e.target.value);
    setFormData({ ...formData, dateOfBirth: date });
  };

  const handleDateValue = (date: string | Date | null | undefined): string => {
    if (
      typeof date === "object" &&
      date !== null &&
      date &&
      "toISOString" in date
    ) {
      return date.toISOString().substring(0, 10);
    }
    if (typeof date === "string" && "toISOString" in new Date(date)) {
      return date.substring(0, 10);
    }
    return "2021-01-01";
  };

  const isFormSubmitDisabled = (): boolean => {
    //  TODO: Add validations for required fields
    return submittedDisabled;
  };

  const handleSubmit = async () => {
    if (isFormSubmitDisabled()) {
      return;
    }
    const contactId = contact._id || contact.id;

    if (!contactId) {
      showUIToast({ type: "warning", text: "Contact ID is missing." });
      return;
    }

    const { data, success, error } = await updateContactAsync(contactId, {
      flagged:contact.flagged,
      meta: formData,
    });

    if (error && !success) {
      showUIToast({ type: "error", text: error?.message });
      return;
    }

    data &&
      showUIToast({
        type: "success",
        text: "Contact information was submitted.",
      });

    setFormData(defaultFormData);
    setSubmittedInfo(true);
    setSubmittedDisabled(true);
    if (!onPhone) {
      clearDispositionInfo();
    }
  };
  //Transfer function
  const onTransferClick = (e) => {
    e.preventDefault();
    if (!isTransferDisabled) {
      setTransferDisabled(true);
      transferCall(contact?.id);
    }
  };

  return (
    <>
      {showForm ? (
        <FormContainer>
          <UIRow gap={1.5}>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="ssid">SSID</UILabel>
                <UIInputText
                  id={"ssid"}
                  data-qa-id={"ssid"}
                  value={formData?.ssid || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="firstName">First Name</UILabel>
                <UIInputText
                  id={"firstName"}
                  data-qa-id={"firstName"}
                  value={formData?.firstName || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="lastName">Last Name</UILabel>
                <UIInputText
                  id={"lastName"}
                  data-qa-id={"lastName"}
                  value={formData?.lastName || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
            </UIRow>

            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="dateOfBirth">Date of Birth </UILabel>
                <UIInputText
                  type={"date"}
                  id={"dateOfBirth"}
                  data-qa-id={"dateOfBirth" + formData?.dateOfBirth}
                  value={handleDateValue(formData?.dateOfBirth)}
                  onChange={handleDateChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="gender">Gender</UILabel>
                <SelectInput
                  id={"gender"}
                  data-qa-id={"gender" + formData?.gender}
                  value={formData?.gender}
                  onChange={handleInputChange}
                >
                  {genderOptions.map((genderOpt) => (
                    <option
                      key={`${genderOpt.value}_gender_opt`}
                      value={genderOpt.value}
                    >
                      {genderOpt.label}
                    </option>
                  ))}
                </SelectInput>
              </UIField>
              <UIField>
                <UILabel htmlFor="grade">Grade (in the fall)</UILabel>
                <SelectInput
                  id={"grade"}
                  data-qa-id={"grade" + formData?.grade}
                  value={formData?.grade}
                  onChange={handleInputChange}
                >
                  {gradeOptions.map((gradeOpt) => (
                    <option
                      key={`${gradeOpt.value}_grade_opt`}
                      value={gradeOpt.value}
                    >
                      {gradeOpt.label}
                    </option>
                  ))}
                </SelectInput>
              </UIField>
            </UIRow>

            <UIRow gap={1} alignItems="center">
              <UIField>
                <UILabel htmlFor="returningHBA">
                  Will your child be returning to school for 2023-24?
                </UILabel>
                <SelectInput
                  id={"returningHBA"}
                  data-qa-id={"returningHBA" + formData?.returningHBA}
                  value={formData?.returningHBA || ""}
                  onChange={handleInputChange}
                >
                  {yesNoMaybeOptions.map((returningHBAOpt) => (
                    <option
                      key={`${returningHBAOpt.value}_returningHBA_opt`}
                      value={returningHBAOpt.value}
                    >
                      {returningHBAOpt.label}
                    </option>
                  ))}
                </SelectInput>
              </UIField>
            </UIRow>
            {formData?.returningHBA === "No" && (
              <UIRow gap={1}>
                <UIField>
                  <UILabel htmlFor="leavingReason">
                    If no, reason for leaving?
                  </UILabel>
                  <UITextArea
                    rows={3}
                    placeholder={"A completely reasonable reason for leaving"}
                    id={"leavingReason"}
                    data-qa-id={"leavingReason"}
                    value={formData?.leavingReason}
                    onChange={handleInputChange}
                  ></UITextArea>
                </UIField>
              </UIRow>
            )}
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="firstParentGuardian">
                  Parent/Guardian 1 First Name
                </UILabel>
                <UIInputText
                  id={"firstParentGuardian"}
                  data-qa-id={"firstParentGuardianFName"}
                  name={"firstName"}
                  value={formData?.firstParentGuardian?.firstName || ""}
                  onChange={handleParentInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="firstParentGuardian">
                  Parent/Guardian 1 Last Name
                </UILabel>
                <UIInputText
                  id={"firstParentGuardian"}
                  data-qa-id={"firstparentGuardianLName"}
                  name={"lastName"}
                  value={formData?.firstParentGuardian?.lastName || ""}
                  onChange={handleParentInputChange}
                ></UIInputText>
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="firstParentGuardian">Email</UILabel>
                <UIInputText
                  type={"email"}
                  id={"firstParentGuardian"}
                  data-qa-id={"firstParentGuardianEmail"}
                  name={"email"}
                  value={formData?.firstParentGuardian?.email || ""}
                  onChange={handleParentInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="firstParentGuardian">Phone Number</UILabel>
                <UIInputText
                  type={"tel"}
                  id={"firstParentGuardian"}
                  data-qa-id={"firstParentGuardianPhoneNum"}
                  name={"phoneNumber"}
                  value={formData?.firstParentGuardian?.phoneNumber || ""}
                  onChange={handleParentInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="firstParentGuardian">
                  Relation to child
                </UILabel>
                <SelectInput
                  id={"firstParentGuardian"}
                  data-qa-id={
                    "firstParentGuardianRelationToChild" +
                    formData?.firstParentGuardian?.relationToChild
                  }
                  name={"relationToChild"}
                  value={formData?.firstParentGuardian?.relationToChild || ""}
                  onChange={handleParentInputChange}
                >
                  {childRelationshipOptions.map((relationToChildOpt) => (
                    <option
                      key={`${relationToChildOpt.value}_firstParentGuardianRelationChild_opt`}
                      value={relationToChildOpt.value}
                    >
                      {relationToChildOpt.label}
                    </option>
                  ))}
                </SelectInput>
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="secondParentGuardian">
                  Parent/Guardian 2 First Name
                </UILabel>
                <UIInputText
                  id={"secondParentGuardian"}
                  data-qa-id={"secondParentGuardianFName"}
                  name={"firstName"}
                  value={formData?.secondParentGuardian?.firstName || ""}
                  onChange={handleParentInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="secondParentGuardian">
                  Parent/Guardian 2 Last Name
                </UILabel>
                <UIInputText
                  id={"secondParentGuardian"}
                  data-qa-id={"secondParentGuardianLName"}
                  name={"lastName"}
                  value={formData?.secondParentGuardian?.lastName || ""}
                  onChange={handleParentInputChange}
                ></UIInputText>
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="secondParentGuardian">Email</UILabel>
                <UIInputText
                  type={"email"}
                  id={"secondParentGuardian"}
                  data-qa-id={"secondParentGuardianEmail"}
                  name={"email"}
                  value={formData?.secondParentGuardian?.email || ""}
                  onChange={handleParentInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="secondParentGuardian">Phone Number</UILabel>
                <UIInputText
                  type={"tel"}
                  id={"secondParentGuardian"}
                  data-qa-id={"secondParentGuardianPhoneNum"}
                  name={"phoneNumber"}
                  value={formData?.secondParentGuardian?.phoneNumber || ""}
                  onChange={handleParentInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="secondParentGuardian">
                  Relation to child
                </UILabel>
                <SelectInput
                  id={"secondParentGuardian"}
                  data-qa-id={
                    "secondParentGuardianRelationToChild" +
                    formData?.secondParentGuardian?.relationToChild
                  }
                  name={"relationToChild"}
                  value={formData?.secondParentGuardian?.relationToChild || ""}
                  onChange={handleParentInputChange}
                >
                  {childRelationshipOptions.map((relationToChildOpt) => (
                    <option
                      key={`${relationToChildOpt.value}_secondParentGuardianRelationChild_opt`}
                      value={relationToChildOpt.value}
                    >
                      {relationToChildOpt.label}
                    </option>
                  ))}
                </SelectInput>
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="phoneTwo">Phone Number 2</UILabel>
                <UIInputText
                  type={"tel"}
                  id={"phoneTwo"}
                  data-qa-id={"phoneTwo"}
                  value={formData?.phoneTwo || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="contactNameTwo">Contact Name 2</UILabel>
                <UIInputText
                  id={"contactNameTwo"}
                  data-qa-id={"contactNameTwo"}
                  value={formData?.contactNameTwo || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="homeAddress">Home address</UILabel>
                <UIInputText
                  id={"homeAddress"}
                  data-qa-id={"homeAddress"}
                  value={formData?.homeAddress || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="homeLineTwo">Home Line</UILabel>
                <UIInputText
                  type={"tel"}
                  id={"homeLineTwo"}
                  data-qa-id={"homeLineTwo"}
                  value={formData?.homeLineTwo || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="homeCity">Home City</UILabel>
                <UIInputText
                  id={"homeCity"}
                  data-qa-id={"homeCity"}
                  value={formData?.homeCity || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="homeZip">Home ZIP</UILabel>
                <UIInputText
                  type={"text"}
                  id={"homeZip"}
                  data-qa-id={"homeZip"}
                  pattern={USZipCodePattern}
                  value={formData?.homeZip || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="transportationStatus">
                  Transportation Status
                </UILabel>
                <SelectInput
                  id={"transportationStatus"}
                  data-qa-id={
                    "transportationStatus" + formData?.transportationStatus
                  }
                  value={formData?.transportationStatus}
                  onChange={handleInputChange}
                >
                  {transportationStatusOptions.map((returningHBAOpt) => (
                    <option
                      key={`${returningHBAOpt.value}_transportationStatus_opt`}
                      value={returningHBAOpt.value}
                    >
                      {returningHBAOpt.label}
                    </option>
                  ))}
                </SelectInput>
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="amAddress">AM Address</UILabel>
                <UIInputText
                  id={"amAddress"}
                  data-qa-id={"amAddress"}
                  value={formData?.amAddress || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="lineTwo">AM Line</UILabel>
                <UIInputText
                  type={"tel"}
                  id={"lineTwo"}
                  data-qa-id={"lineTwo"}
                  value={formData?.lineTwo || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="amCity">AM City</UILabel>
                <UIInputText
                  id={"amCity"}
                  data-qa-id={"amCity"}
                  value={formData?.amCity || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="amZip">AM ZIP</UILabel>
                <UIInputText
                  type={"text"}
                  id={"amZip"}
                  data-qa-id={"amZip"}
                  pattern={USZipCodePattern}
                  value={formData?.amZip || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="pmAddress">PM Address</UILabel>
                <UIInputText
                  id={"pmAddress"}
                  data-qa-id={"pmAddress"}
                  value={formData?.pmAddress || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="pmLineTwo">PM Line</UILabel>
                <UIInputText
                  type={"tel"}
                  id={"pmLineTwo"}
                  data-qa-id={"pmLineTwo"}
                  value={formData?.pmLineTwo || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="pmCity">PM City</UILabel>
                <UIInputText
                  id={"pmCity"}
                  data-qa-id={"pmCity"}
                  value={formData?.pmCity || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
              <UIField>
                <UILabel htmlFor="pmZip">PM ZIP</UILabel>
                <UIInputText
                  type={"text"}
                  id={"pmZip"}
                  data-qa-id={"pmZip"}
                  pattern={USZipCodePattern}
                  value={formData?.pmZip || ""}
                  onChange={handleInputChange}
                ></UIInputText>
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="daysOfWeek">Days of Week</UILabel>
                <WeekdaysSelector
                  onDaySelected={(days: string[]) =>
                    setFormData({ ...formData, daysOfWeek: days })
                  }
                  selectedDays={formData?.daysOfWeek || []}
                />
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="incomingSibling">Incoming sibling?</UILabel>
                <SelectInput
                  id={"incomingSibling"}
                  data-qa-id={"incomingSibling" + formData?.incomingSibling}
                  value={formData?.incomingSibling || ""}
                  onChange={handleInputChange}
                >
                  {yesNoOptions.map((opt) => (
                    <option key={`${opt.value}_sibling_opt`} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </SelectInput>
              </UIField>
            </UIRow>
            <UIRow gap={1}>
              <UIField>
                <UILabel htmlFor="additionalNotes">Additional Notes</UILabel>
                <UITextArea
                  rows={5}
                  cols={10}
                  placeholder={"Any note will be written here"}
                  id={"additionalNotes"}
                  data-qa-id={"additionalNotes"}
                  value={formData?.additionalNotes || ""}
                  onChange={handleInputChange}
                ></UITextArea>
              </UIField>
            </UIRow>
          </UIRow>
        </FormContainer>
      ) : (
        <LabelContainer>
          <Text
            fontSize="clamp(20px, 1.5vw, 24px)"
            lineHeight="1.5"
            textAlign="center"
          >
            Your SDR will continue with the form filling
          </Text>
        </LabelContainer>
      )}

      <Fixed>
        {!isSdr && (
          <UIButton
            data-qa-id={"transferbtn"}
            disabled={
              isSdr
                ? !onPhone
                : !callId || callStatus === "paused" || isTransferDisabled
            }
            onClick={onTransferClick}
          >
            Transfer
          </UIButton>
        )}

        <UIButton
          onClick={() => setShowLeaveVmModal((prev) => !prev)}
          disabled={
            isSdr ? !onPhone : !callId || isTransferDisabled || !isOngoing
          }
        >
          Leave VM
        </UIButton>
        <UIButton
          onClick={toggleMute}
          disabled={isSdr ? !onPhone : !isTransferDisabled && !callId}
        >
          <FA icon={!!callId && !isMuted ? faMicrophone : faMicrophoneSlash} />
        </UIButton>

        {showForm && (
          <UIButton
            data-qa-id={"submitbtn"}
            disabled={isFormSubmitDisabled()}
            onClick={() => handleSubmit()}
          >
            Submit
          </UIButton>
        )}

        {showLeaveVmModal && onPhone && (
          <LeaveVoicemailContainer>
            <LeaveVmModal
              callId={callId}
              onClose={() => setShowLeaveVmModal((prev) => !prev)}
              voiceDropsIds={voiceDropsIds}
            />
          </LeaveVoicemailContainer>
        )}
      </Fixed>
    </>
  );
};

const LeaveVoicemailContainer = styled(Box)`
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
`;

export default FourMativ;

import routes from "../../constants/routes";
import axios from "axios";
import { CallSessionSuccessResponse } from "../../components/shared/CallSession";

type Props = {
  status: string;
  agentId: string;
};

type ResponseProps = {
  callSession?: CallSessionSuccessResponse | null;
  error?: { message: string };
};

const getByStatusAndAgentIdAsync = async ({
  status,
  agentId,
}: Props): Promise<ResponseProps> => {
  try {
    const callSession = await axios.get<CallSessionSuccessResponse>(
      routes.get.callSession.getByStatusAndAgentIdNest + `/${status}/${agentId}`
    );
    return { callSession: callSession.data[0] };
  } catch {
    return { error: { message: "Error getting call session" } };
  }
};

export default getByStatusAndAgentIdAsync;

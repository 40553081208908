import React from "react";
import { Card, Footer, CardsContainer } from "../shared";
import Notes from "../shared/Notes";
import { Contact } from "../shared/Contact";
import { Text } from "../../core/ui";
import RateCall from "../shared/RateCall";

interface Props {
  currentContact: Contact;
  setCurrentContact: (notes: Contact) => void;
}

const CallFinishedContainer = ({
  currentContact,
  setCurrentContact,
}: Props): JSX.Element => (
  <>
    <CardsContainer>
      <Card flexDirection="column" alignItems="center" justifyContent="center">
        <Text
          color="lightBlue"
          fontWeight="600"
          fontSize="36px"
          lineHeight="1.5"
        >
          Jorge Martínez
        </Text>
        <Text fontSize="26px" lineHeight="1.5">
          Lucas Films | CEO
        </Text>
        <Text fontSize="26px" lineHeight="1.5">
          55 32 501 520
        </Text>
      </Card>
      <Card padding="0px">
        <RateCall />
      </Card>
      <Card>
        <Notes
          canDelete
          currentContact={currentContact}
          setCurrentContact={setCurrentContact}
          isSDR={false}
        />
      </Card>
    </CardsContainer>
    <Footer />
  </>
);

export default CallFinishedContainer;

import React, { useState, useEffect } from "react";
import { Box, Text, Button } from "../../core/ui";
import styled from "styled-components";
import playRecordingAsync from "../../api/Recording/playRecordingAsync";
import { Recording as RecordingType } from "../../types/Recording";
import getRecordingByIdAsync from "../../api/Recording/getRecordingByIdAsync";
import { showUIToast } from "../../core/ui/UIToast";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

const Container = styled(Box)`
  width: 284px;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  border: solid 1px ${({ theme }) => theme.colors.darkGray};
  background: ${({ theme }) => theme.colors.white};
`;

const CloseButton = styled(Button)`
  width: 25px;
  height: 25px;
  position: absolute;
  right: -10px;
  top: -10px;
  padding: 0;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.lightBlue};

  display: grid;
  place-items: center;
`;

const OptionsContainer = styled(Box)`
  padding: 15px 5px 10px 5px;
  border-radius: 10px;
  border: solid 1px ${({ theme }) => theme.colors.darkGray};
`;

const Option = styled(Box)<{ selected: boolean }>`
  margin-bottom: 5px;
  padding: 4px 0 4px 25px;
  border-radius: 8px;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.lightBlue : theme.colors.white};

  input {
    margin-right: 10px;
    background: ${({ theme }) => theme.colors.darkGray};
  }

  label {
    color: ${({ theme, selected }) =>
      selected ? theme.colors.white : theme.colors.lightBlue};
    font-size: 15px;
  }
`;

const StyledButton = styled(Button)<{ disabled: boolean }>`
  margin-top: 10px;
  padding: 5px 15px;
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.lightGray : theme.colors.lightBlue};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.darkGray : theme.colors.white};
  border-radius: 5px;
`;

type Props = {
  onClose: () => void;
  voiceDropsIds: [string] | undefined;
  callId: string | undefined;
};

const LeaveVmModal = ({
  onClose,
  callId,
  voiceDropsIds,
}: Props): JSX.Element => {
  const [recordingList, setRecordingList] = useState<Array<RecordingType>>([]);
  const [optionSelected, setOptionSelected] = useState<RecordingType | null>(
    null
  );

  useEffect(() => {
    const recordings: RecordingType[] = [];
    const getRecordings = () => {
      voiceDropsIds?.map(async (recordingId) => {
        const { success, data } = await getRecordingByIdAsync(recordingId);
        if (success && !!data) {
          recordings.push(data);
          setRecordingList(recordings);
        }
      });
    };
    getRecordings();
  }, [voiceDropsIds]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { success, error } = await playRecordingAsync({
      callId,
      recordingUrl: optionSelected?.url || "default recording url here",
    });

    error && showUIToast({ type: "error", text: error.message });

    if (success) {
      onClose();
      showUIToast({ type: "info", text: "Playing recording." });
    }
  };

  return (
    <Container>
      <CloseButton onClick={onClose}>
        <FA icon={faClose} size="sm" color="white" />
      </CloseButton>
      <Text fontSize="1.5em" fontWeight="600" color="lightBlue" mb="10px">
        Select a Voicemail
      </Text>
      <form onSubmit={handleSubmit}>
        <OptionsContainer>
          {recordingList.map((r) => (
            <Option
              key={r.id}
              onClick={() => setOptionSelected(r)}
              selected={r.id === optionSelected?.id}
            >
              <input type="radio" id={r.id} name="voicemail" value={r.id} />
              <label htmlFor={r.id}>{r.title}</label>
            </Option>
          ))}
        </OptionsContainer>
        <Box textAlign="center">
          <StyledButton
            mt="10px"
            padding="5px 15px"
            bg="lightBlue"
            color="white"
            borderRadius="5px"
            disabled={!optionSelected}
          >
            Submit
          </StyledButton>
        </Box>
      </form>
    </Container>
  );
};

export default LeaveVmModal;

import { useState, useEffect, useContext } from 'react';
import { ContactsUseCase } from '../../../Domain/UseCase/contacts-nest/ContactsUseCase';
import { type Contact } from '../../../Data/DataSource/contacts-nest/contacts/entities/contact.entity';
import { LoadingState } from '../../../../src/types/LoadingStates.type';
import { UserProviderContext } from '../../../../src/components/app/UserProvider';
import { type SelectedFilters } from '@/src-v2/Domain/interfaces/Contacts/filter.interfaces';

const { IDLE, LOADING, ERROR, SUCCESS } = LoadingState;

export interface useContactsViewModelI {
  viewModelContacts: Contact[];
  sessionIds: string[] | undefined;
  setSessionIds: (sessionIds: string[]) => void;
  fetchContactsBySdrId: (callSessionId: string[], filters?: SelectedFilters) => Promise<Contact[] | undefined>;
  loadingState: useContactsViewModelLoadingState;
}

interface useContactsViewModelLoadingState {
  fetchContactssBySdrId: LoadingState;
}

const useContactsViewModel = (): useContactsViewModelI => {
  const [viewModelContacts, setContacts] = useState<Contact[]>([]);
  const [sessionIds, setSessionIds] = useState<string[] | undefined>(undefined);
  const [loadingState, setLoadingState] = useState<useContactsViewModelLoadingState>({
    fetchContactssBySdrId: IDLE,
  });
  const { currentUser } = useContext(UserProviderContext);
  const contactsUseCase = ContactsUseCase();
  const [filtersState, setFiltersState] = useState<SelectedFilters>({
    metricsFilter: {
      actions: [],
    },
    contactsFilter: {
      flagged: [],
    },
  });

  const fetchContactsBySdrId = async (
    callSessionIds: string[],
    filters: SelectedFilters | undefined = undefined,
  ): Promise<Contact[] | undefined> => {
    setLoadingState((prevState) => ({
      ...prevState,
      fetchContactssBySdrId: LOADING,
    }));

    const { data, error, success } = await contactsUseCase.getContactsByCallSessionIds(
      currentUser.userId,
      callSessionIds,
      filters !== undefined ? filters : filtersState,
    );

    if (data === undefined || error !== undefined || success === false) {
      setLoadingState({ ...loadingState, fetchContactssBySdrId: ERROR });
      return;
    }

    setContacts(data);

    setLoadingState((prevState) => ({
      ...prevState,
      fetchContactssBySdrId: SUCCESS,
    }));

    filters !== undefined && setFiltersState(filters);

    return data;
  };

  useEffect(() => {
    if (sessionIds !== undefined && sessionIds.length > 0) {
      if (loadingState.fetchContactssBySdrId !== LOADING) {
        void fetchContactsBySdrId(sessionIds).finally();
      }
    }
  }, []);

  return { viewModelContacts, sessionIds, setSessionIds, fetchContactsBySdrId, loadingState };
};

export default useContactsViewModel;

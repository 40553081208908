import routes from "../../constants/routes";
import axios from "axios";
import { BuildMetric } from "../../components/shared/Metric";
import { ApiResponse } from "../../types/ApiResponse";

const getBuildMetricsAsync = async (
  callSessionId: string
): Promise<ApiResponse<BuildMetric>> => {
  try {
    const metrics = await axios.get<BuildMetric>(
      `${routes.get.metrics.buildMetricsNest}/${callSessionId}`
    );
    return { data: metrics?.data, success: true };
  } catch (e) {
    return {
      error: {
        message: "Error getting build metrics",
      },
    };
  }
};

export default getBuildMetricsAsync;

import React, { type ReactElement } from 'react';
import ButtonOnlyIcon from '../Buttons/ButtonOnlyIcon';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import Button from '../Buttons/Button';

interface ConfirmDialogProps {
  title?: string;
  description?: string;
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
  isLoading?: boolean;
}

export default function ConfirmModal({
  title = 'Confirm making this action?',
  description = 'This action is irreversible and will make changes permanently. Please take a moment to review and consider the consequences before proceeding.',
  onConfirm,
  onCancel,
  isLoading = false,
  isOpen = false,
}: ConfirmDialogProps): ReactElement | null {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onConfirm();
  };
  return isOpen ? (
    <dialog className="grid overflow-y-auto overflow-x-hidden fixed inset-0 z-50 place-items-center w-full h-modal md:h-full bg-black bg-opacity-50">
      <div className="relative p-4 w-full max-w-xl h-full md:h-auto">
        <div className="relative p-4 bg-white rounded-lg shadow sm:p-5">
          <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
            <ButtonOnlyIcon icon={faClose} iconType="close-modal" onClick={onCancel} />
          </div>

          <form onSubmit={handleSubmit} className="flex flex-col gap-16">
            <div className="px-6 text-center">
              <FA className="w-8 h-8 mx-auto mb-4 text-gray-400" size="lg" icon={faTriangleExclamation} />

              <p className="text-justify pt-6 text-sm text-slate-400">{description}</p>
            </div>
            <div className="flex gap-4 justify-center">
              <Button type="submit" color="danger" text="Yes, I'm sure" loading={isLoading} />
              <Button type="button" color="outline" text="No, cancel" onClick={onCancel} disabled={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </dialog>
  ) : null;
}

import routes from '../../../../../src/constants/routes';
import axios, { type AxiosResponse } from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import { type SafeUserDto } from './dto/safeUser.dto';

const getUserAsync = async (userId: string): Promise<ApiResponse<SafeUserDto>> => {
  try {
    const userUrl = `${routes.get.users.userNest}/${userId}`;
    const user: AxiosResponse<SafeUserDto> = await axios.get(userUrl);
    return { data: user.data, success: true };
  } catch (e) {
    return { error: { message: 'Error getting the requested user' }, success: false };
  }
};

export default getUserAsync;

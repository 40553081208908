import {
  type DispositionRecord,
  type GetOutreachMappingsRepository,
  OutreachMappingsRepository,
} from '../../../Data/Repository/sync/outreach-mappings/outreachMappingsRepository';
import {
  type Disposition,
  DispositionAbbreviations,
  DispositionDefaultAction,
  Outcome,
  TendrilDefaultActions,
} from '../../../Presentation/Views/types/disposition-mapping.type';
import {
  type CreateCustomDispositionDto,
  type OutreachCustomDispositionDto,
} from '../../../Data/DataSource/sync/outreach-mappings/dto/create-custom-disposition.dto';
import { type ApiResponse } from '../../../../src/types/ApiResponse';
import { type PostOutreachMappingDto } from '../../../Data/DataSource/sync/outreach-mappings/dto/post-outreach-mapping.dto';
import { type OutreachCallDispositions } from '../../../Data/DataSource/sync/outreach-mappings/dto/getOutreachCustomDispositions.dto';
import {
  type UpdateCustomDispositionDto,
  type UpdateCustomDispositionResponseDto,
} from '../../../Data/DataSource/sync/outreach-mappings/dto/update-custom-disposition.dto';

interface OutreachMappingsUseCase {
  getMappings: (id: string) => Promise<ApiResponse<GetOutreachMappingsRepository>>;
  patchMappings: (id: string, data: PostOutreachMappingDto) => Promise<ApiResponse<GetOutreachMappingsRepository>>;
  getDispositions: (organizationId: string, nonce: string) => Promise<ApiResponse<OutreachCallDispositions[]>>;
  getTransformedMappings: (
    defaultMappings: DispositionRecord,
    customOutreachDispositions: OutreachCallDispositions[],
    dispositionId: string,
  ) => Disposition[];
  createDisposition: (
    organizationId: string,
    nonce: string,
    body: CreateCustomDispositionDto,
  ) => Promise<ApiResponse<OutreachCustomDispositionDto>>;
  updateCustomDisposition: (
    organizationId: string,
    nonce: string,
    body: UpdateCustomDispositionDto,
  ) => Promise<ApiResponse<UpdateCustomDispositionResponseDto>>;
  deleteCustomDisposition: (
    organizationId: string,
    nonce: string,
    callDispositionId: string,
  ) => Promise<ApiResponse<unknown>>;
  isCustomDisposition: (customName: string) => boolean;
}

export function GetOutreachMappingsUseCase(): OutreachMappingsUseCase {
  const {
    getOutreachMappings,
    postMappings,
    getCustomDispositions,
    createDisposition,
    updateCustomDisposition,
    deleteCustomDisposition,
  } = OutreachMappingsRepository();

  function isCustomDisposition(customName: string): boolean {
    return (
      customName !== TendrilDefaultActions.default_action &&
      Object.values(TendrilDefaultActions).every((tendrilDefaultName) => customName !== tendrilDefaultName)
    );
  }

  return {
    async getMappings(id: string) {
      const { data, error, success } = await getOutreachMappings(id);
      return { data, error, success };
    },
    async patchMappings(id: string, updateOutreachMappingDto) {
      const { data, error, success } = await postMappings(id, updateOutreachMappingDto);

      return { data, error, success };
    },
    async getDispositions(organizationId: string, nonce: string) {
      const { data, error, success } = await getCustomDispositions(organizationId, nonce);

      return { data, error, success };
    },
    async createDisposition(organizationId: string, nonce: string, body: CreateCustomDispositionDto) {
      const { data, error, success } = await createDisposition(organizationId, nonce, body);

      return { data, error, success };
    },
    async updateCustomDisposition(organizationId: string, nonce: string, body: UpdateCustomDispositionDto) {
      const { data, error, success } = await updateCustomDisposition(organizationId, nonce, body);

      return { data, error, success };
    },

    async deleteCustomDisposition(organizationId: string, nonce: string, callDispositionId: string) {
      const { success, error, data } = await deleteCustomDisposition(organizationId, nonce, callDispositionId);

      return { success, error, data };
    },

    isCustomDisposition,

    getTransformedMappings: (
      mappings: DispositionRecord,
      callDispositions: OutreachCallDispositions[],
      dispositionId: string,
    ) => {
      const MAX_DISPOSITIONS = 16;
      const transformedDispositions: Disposition[] = [];

      for (let index = 0; index < MAX_DISPOSITIONS; index++) {
        const isDefault = index === MAX_DISPOSITIONS - 1;
        const abbreviation = DispositionAbbreviations[index];
        const defaultAction = DispositionDefaultAction[abbreviation];
        const defaultName = TendrilDefaultActions[abbreviation];
        const mappingName = isDefault ? 'default_action' : mappings[abbreviation];

        const defaultCustomDisposition: OutreachCallDispositions = {
          id: String(index),
          attributes: {
            name: defaultName,
            outcome: Outcome.NO_ANSWER,
          },
          type: 'callDisposition',
        };

        const customDispositionMap = callDispositions.filter((map) => map.attributes.name === mappingName);

        const { id, type, attributes } =
          customDispositionMap[customDispositionMap.length - 1] ?? defaultCustomDisposition;

        const transformed: Disposition = {
          id,
          type,
          dispositionId,
          abbreviation,
          defaultAction,
          customName: attributes.name,
          defaultName,
          isCustom: isCustomDisposition(attributes.name ?? mappingName),
          outcome: attributes.outcome as Outcome,
        };

        transformedDispositions.push(transformed);
      }

      return transformedDispositions;
    },
  };
}

import axios from "axios";
import routes from "../../constants/routes";
import jwt_decode from "jwt-decode";
import refreshAync from "../../api/Auth/refreshAync";
import { Timezone } from "../../types/TimezoneTypes";
import { CurrentUser } from '../../types';
import { Roles } from '../../types/Roles';
const window = globalThis as any;

type SessionResponse = {
  authToken: string;
};

type userType = {
  type: string;
};

type Decoded = {
  user: {
    id: string;
    name: string;
    type: userType[];
    sdrId: string;
    organizationId: string;
    isSuperAdmin: boolean;
    email: string;
    timezone: Timezone;
  };
  exp: number;
};

export const refreshSessionToken = async (): Promise<boolean> => {
  const userFromSession = window.localStorage.getItem("target");

  if (!userFromSession) {
    return false;
  }

  try {
    const { authToken, error } = await refreshAync(userFromSession);

    if (error || !authToken) {
      return false;
    }

    axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;

    const user = createSessionUser(authToken);

    window.localStorage.setItem("user", JSON.stringify(user));
    window.localStorage.setItem("target", authToken);

    return true;
  } catch {
    return false;
  }
};

export const createSessionUser = (authToken: string): CurrentUser => {
  const decoded: Decoded = jwt_decode(authToken);


  return {
    userId: decoded.user.id,
    userName: decoded.user.name,
    type: decoded.user.type,
    sdrId: decoded.user.sdrId,
    organizationId: decoded.user.organizationId,
    isSuperAdmin: decoded.user.isSuperAdmin,
    email: decoded.user.email,
    timezone: decoded.user.timezone,
    isAdmin: decoded.user.type.some(item => item.type === Roles.ADMIN),
    isAgent: decoded.user.type.some(item => item.type === Roles.SDR),
    isSdr: decoded.user.type.some(item => item.type === Roles.AGENT),
  };
};

export const isTokenExpired = (authToken: string) => {
  const decoded: Decoded = jwt_decode(authToken);

  return Date.now() >= decoded.exp * 1000;
};

import routes from '../../../../../src/constants/routes';
import axios from 'axios';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type SafeUserDto } from './dto/safeUser.dto';
import { type UpdateUserDto } from './dto/updateUserDto';

const updateUserAsync = async (userData: UpdateUserDto): Promise<ApiResponse<SafeUserDto>> => {
  try {
    const user = await axios.put<SafeUserDto>(`${routes.put.users.userNest}/${userData.id}`, userData);
    return { data: user.data, success: true };
  } catch {
    return { error: { message: 'Error trying to update user' }, success: false };
  }
};

export default updateUserAsync;

import { type SaveHubspotMappingDto } from './dto/save-hubspot-mapping.dto';
import { type HubspotMappingDto } from './dto/get-hubspot-mappings.dto';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import routes from '../../../../../src/constants/routes';
import axios from 'axios';

const saveHubspotMappings = async (
  id: string,
  patchMapping: SaveHubspotMappingDto,
): Promise<ApiResponse<HubspotMappingDto>> => {
  try {
    const url = `${routes.post.hubspot.saveMappings}/${id}`;
    const mappings = await axios.post<HubspotMappingDto>(url, patchMapping);

    return {
      success: true,
      data: mappings.data,
    };
  } catch (e: unknown) {
    return {
      success: false,
      error: {
        message:
          'An error occurred while updating hubspot mappings. If the issue persists, please contact customer support.',
      },
    };
  }
};

export default saveHubspotMappings;

import React from "react";
import styled from "styled-components";
import { UIText } from "../../core/ui/UIElements";
import constants from "../../constants.json";

const WatermarkContainer = styled.div`
  position: fixed;
  bottom: 90px;
  right: 50%;
  transform: translateX(50%);
  padding: 0.75rem 1.5rem;
  backdrop-filter: brightness(0.9);
  border-radius: 5px;
`;

const CustomUIText = styled(UIText)`
  font-size: 1.5em;
  font-weight: 500;
  color: #0C548C;
  text-align: center;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
    color: #0C548C;
  }
  user-select: none;
`;

type Props = {
	lastVersion: string;
};

const VersionWatermark = ({ lastVersion }: Props) => {
	return (
		<WatermarkContainer>
			<CustomUIText>{`⚠️ Your current version of the app is ${constants.version}, there is a new version available ${lastVersion}`}</CustomUIText>
		</WatermarkContainer>
	);
};

export default VersionWatermark;

import {
  IconDefinition,
  IconName,
  IconPrefix,
} from "@fortawesome/fontawesome-svg-core";

export const customOutreachLogo: IconDefinition = {
  icon: [
    30, // SVG view-box width (in pixels)
    30, // SVG view-box height (in pixels)
    [], // Aliases                         (no needed)
    "", // Unicode as hex value (no needed)
    "M13.3,0c4.6,0,11.5,1.4,14.7,4.7c0.8,0.8,1.9,2.4,1.9,4.8c0,4.2-3.4,10.6-7.1,14.3c-1.9,1.9-5,4.4-9.1,4.4" +
      "c-4,0-7.1-2.7-8-3.6C2.4,21.2,0,16,0,11.2c0-3,0.9-5.7,3.1-7.9C5.1,1.2,8.6,0,13.3,0z M19,16.7c1.5-1.6,2-3.5,2-4.5" +
      "c0-0.8-0.2-1.9-1.2-2.8c-1.5-1.5-3.1-1.9-4.8-1.9c-2.2,0-3.7,0.7-4.7,1.7c-0.8,0.8-1.5,2-1.5,3.7c0,1.7,0.9,3.4,1.9,4.4" +
      "c0.9,0.9,2.4,1.6,3.8,1.6S17.4,18.3,19,16.7z", // SVG path data
  ],
  iconName: "outreach-logo" as IconName,
  prefix: "custom" as IconPrefix,
};

import { UsersUseCase } from '../../../../Domain/UseCase/users/UsersUseCase';
import { UserProviderContext } from '@/src/components/app/UserProvider';
import { type ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { type SafeUserDto } from '@/src-v2/Data/DataSource/edge/users/dto/safeUser.dto';
import TIME_ZONE from '@/src-v2/Domain/enums/Users/timezone';

export interface AccountSettingsViewModelProps {
  image: string;
  chooseFile: (e: ChangeEvent<HTMLInputElement>) => void;
  file: File | undefined;
}

export default function UploadProfilePictureViewmodel(): AccountSettingsViewModelProps {
  const { currentUser } = useContext(UserProviderContext);
  const { getUserAsync } = UsersUseCase();
  const [file, setFile] = useState<File>();
  const [image, setImage] = useState('');
  const user = useRef<SafeUserDto>({
    email: '',
    id: '',
    name: '',
    organizationId: '',
    profilePicture: null,
    sdrId: null,
    timezone: TIME_ZONE.UTC,
    userType: [],
  });

  const fetchUserImage = (): void => {
    void getUserAsync(currentUser.userId).then((response) => {
      if (response.data !== undefined && typeof response.data.profilePicture === 'string') {
        user.current = response.data;
        setImage(response.data.profilePicture);
        return response.data;
      }
    });
  };

  function chooseFile(e: ChangeEvent<HTMLInputElement>): void {
    if (e.target.files !== null) {
      const file = e.target.files[0];
      setFile(file);
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
    }
  }

  useEffect(() => {
    fetchUserImage();
  }, []);

  return {
    chooseFile,
    image,
    file,
  };
}

import React, { useContext, useEffect, useState } from "react";
import UICard from "../../core/ui/UICard";
import { UICol, UIRow } from "../../core/ui/UIStructures";
import Phone, { ContactPhoneStatus } from "../shared/Phone";
import ThreeDots from "../shared/ThreeDots";
import Notes from "../shared/Notes";
import { Box, Flex, Image, Text } from "../../core/ui";
import { Device } from "twilio-client";
import consumeContact from "../../api/Contacts/consumeContactAsync";
import styled from "styled-components";
import Note from "../../assets/icons/note.svg";
import LeaveVmModal from "../shared/LeaveVmModal";
import { TransparentButton } from "../shared";
import { isPhoneNumberValid } from "../../core/utils";
import Select from "../../assets/icons/select_blue.svg";
import { UIButton } from "../../core/ui/UIElements";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons/faMicrophoneSlash";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons/faMicrophone";
import FourMativ from "./4mativ";
import { featuresStore } from "../app/FeaturesProvider";
import { Contact } from "../../types/ContactTypes";
import { UserProviderContext } from "../app/UserProvider";
import { showUIToast } from "../../core/ui/UIToast";
import { Checkbox } from '../../../src-v2/Presentation/Common/Components/ui/checkbox';
import { FlaggedFilter } from '../../../src-v2/Presentation/Views/Contacts/Components/FlaggedFilter';
import { clipboardCopy } from "../../../src-v2/Domain/helpers/clipboard.helper";
import { ClipboardCopy } from 'lucide-react';
import playRecordingAsync from "../../api/Recording/playRecordingAsync";
import getRecordingByIdAsync from "../../api/Recording/getRecordingByIdAsync";
import { Recording } from "../../types/Recording";

interface Props {
  handleToggleCall: (
    number: string,
    currentContact: Contact,
    contactListId: string
  ) => void;
  transferCall: (contactId: string) => void;
  voiceDropsIds: [string] | undefined;
  twilioDevice: Device;
  callId: string;
  onPhone: boolean;
  callStatus: string | undefined;
  speech: string | undefined;
  isMuted: boolean;
  toggleMute: (state: boolean) => void;
  isSdrFree: boolean;
  currentContact: Contact;
  setCurrentContact: (notes: Contact) => void;
  callTransferred: boolean;
  callSessionId: string | undefined;
  submittedInfo: boolean;
  setSubmittedInfo: (submitted: boolean) => void;
  cleanDispositionInfo: () => void;
  submittedDisabled: boolean;
  setSubmittedDisabled: (submittedDisabled: boolean) => void;
  isTransferDisabled: boolean;
  setTransferDisabled: (transferDisabled: boolean) => void;
  setQueueElements: (queueElements: number) => void;
  isOngoing: boolean;
}

const LeaveVmModalContainer = styled(Box)`
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledFlex = styled(Flex) <{
  display?: string;
}>`
  margin-top: 15px;
  border-radius: 5px;
  padding: 5px;
  display: ${({ display }) => display};
  grid-template-columns: 140px 140px 50px;
`;

const SpeechContainer = styled.div`
  height: 130px;
  padding: 0;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    background-color: transparent;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 15px;
    background-color: #eeeeee;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 15px;
    border-right: none;
    border-left: none;
    background-color: #a6a8aa;
  }
`;
const CallContainer = ({
  twilioDevice,
  handleToggleCall,
  transferCall,
  onPhone,
  callStatus,
  callId,
  speech,
  voiceDropsIds,
  isMuted,
  toggleMute,
  isSdrFree,
  currentContact,
  setCurrentContact,
  callTransferred,
  callSessionId,
  submittedInfo,
  setSubmittedInfo,
  cleanDispositionInfo,
  submittedDisabled,
  setSubmittedDisabled,
  isTransferDisabled,
  setTransferDisabled,
  setQueueElements,
  isOngoing,
}: Props): JSX.Element => {
  const [showLeaveVmModal, setShowLeaveVmModal] = useState<boolean>(false);
  // const [submittedInfo, setSubmittedInfo] = useState<boolean>(true);
  const [contactPhoneData, setContactPhoneData] =
    useState<ContactPhoneStatus | null>(null);
  const [formattedPhone, setFormattedPhone] = useState<string>("");
  const { featuresState } = useContext(featuresStore);
  const { fourMativ } = featuresState;
  const { currentUser } = useContext(UserProviderContext);
  const [recordingVM, setRecordingVM] = useState<Recording>();

  const phoneWithCode = (phone) => {
    const firstDigit = phone[0];
    let toPrepend = "";

    if (firstDigit !== "+") {
      toPrepend += "+";
      if (firstDigit !== "1") toPrepend += "1";
    }
    return `${toPrepend}${phone}`;
  };

  const getContact = async (callSessionId) => {
    const { data, error } = await consumeContact(
      callSessionId,
      currentUser.userId
    );

    if (error) {
      console.log('éntre error')
      setCurrentContact({} as Contact);
    }

    if (data) {
      setQueueElements(data.queueLength);
      showUIToast({
        type: "warning",
        text: `Warning ${data.queueLength} contacts left in the queue.`,
      });
      setCurrentContact(data.body);
      validatePhone(data.body);
    }
  };

  const validatePhone = (contact: Contact) => {
    if (!isPhoneNumberValid(contact.phone)) {
      let phone = contact.phone.replace(/[-(). ]|[a-z]+.? ?[0-9]*/g, "");
      phone = phoneWithCode(phone);

      if (isPhoneNumberValid(phone)) {
        setFormattedPhone(phone);
      }
      setContactPhoneData({ isValid: false, value: contact.phone });
    } else {
      setContactPhoneData({ isValid: true, value: contact.phone });
    }
  };
  const handleFormattedPhone = () => {
    setContactPhoneData({ isValid: true, value: formattedPhone });
  };

  // TODO: debounce this function to avoid multiple calls
  const onTransferClick = (e) => {
    e.preventDefault();
    if (!isTransferDisabled) {
      setTransferDisabled(true);
      transferCall(currentContact?.id);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(function () {
      if (
        callStatus === "started" &&
        Object.keys(currentContact).length === 0 &&
        submittedInfo
      ) {
        getContact(callSessionId);
        !fourMativ ? setSubmittedInfo(true) : setSubmittedInfo(false);
        fourMativ && setSubmittedDisabled(true);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [currentContact, callStatus, callTransferred]);

  useEffect(() => {
    const recordingId = voiceDropsIds[0];
    const getRecordings = async () => {
        const { success, data } = await getRecordingByIdAsync(recordingId);
        if (success && !!data) {
          setRecordingVM(data);
        }
    };
    getRecordings();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { success, error } = await playRecordingAsync({
      callId,
      recordingUrl: recordingVM?.url || "default recording url here",
    });

    error && showUIToast({ type: "error", text: error.message });

    if (success) {
      showUIToast({ type: "info", text: "Playing recording." });
    }
  };

  return (
    <>
      {Object.keys(currentContact).length ? (
        <UIRow>
          <UICol sm={12} md={4} lg={3} gap={0}>
            <UICard title="Dial Pad" width="100%">
              <Phone
                handleToggleCall={handleToggleCall}
                currentContact={currentContact}
                callStatus={callStatus}
                onPhone={onPhone}
                twilioDevice={twilioDevice}
                contactPhoneData={contactPhoneData}
                setContactPhoneData={setContactPhoneData}
              />
            </UICard>
          </UICol>

          <UICol sm={12} md={8} lg={6} alignItems="center" gap={0}>
            <UICard title="Contact Information" width="100%" height="430px">
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height={!fourMativ ? "55%" : "100%"}
              >
                <Text
                  color="lightBlue"
                  fontWeight="600"
                  fontSize="clamp(30px, 3vw, 36px)"
                  lineHeight="1.5"
                >
                  {currentContact.name}
                </Text>
                <Text
                  fontSize="clamp(20px, 1.5vw, 24px)"
                  lineHeight="1.5"
                  textAlign="center"
                >
                  {currentContact.accountName} | {currentContact.jobTitle}
                </Text>

                {!fourMativ &&
                  (!!currentContact.email ? (
                    <Text
                      fontSize="clamp(20px, 1.5vw, 24px)"
                      lineHeight="1.5"
                      textAlign="center"
                    >
                      {currentContact.phone} | {currentContact.email}
                    </Text>
                  ) : (
                    <Text
                      fontSize="clamp(20px, 1.5vw, 24px)"
                      lineHeight="1.5"
                      textAlign="center"
                    >
                      {currentContact.phone}
                    </Text>
                  ))}
                <Box marginBottom="1rem" textAlign="center">
                <div className="flex justify-center items-center">
                  {currentContact.phone && !contactPhoneData?.isValid && (
                    <StyledFlex>
                      {formattedPhone ? (
                        <>
                          <Text fontSize="18px" lineHeight="1.5" id = "phone">
                            E.164 format: {formattedPhone}
                          </Text>
                          <TransparentButton onClick={handleFormattedPhone}>
                            <Image
                              src={Select}
                              alt="Select"
                              width="25px"
                              ml=".5rem"
                            />
                          </TransparentButton>
                        </>
                      ) : (

                        <Text fontSize="16px" lineHeight="1.5">
                          We are unable to get the E.164 format for this number
                          contact.
                        </Text>

                      )}
                    </StyledFlex>
                  )}
                   <ClipboardCopy onClick={(e) => {
                           void clipboardCopy(e, currentContact, formattedPhone, currentUser.sdrName)
                          }}
                          color="#0095D4"
                          strokeWidth={2.25}
                          className="cursor-pointer rounded-lg ml-5 mt-3"
                          />
                  </div>
                </Box>

                {fourMativ && transferCall && (
                  <FourMativ
                    contact={currentContact}
                    setSubmittedInfo={setSubmittedInfo}
                    clearDispositionInfo={cleanDispositionInfo}
                    onPhone={onPhone}
                    transferCall={transferCall}
                    isSdr={false}
                    toggleMute={() => toggleMute(!isMuted)}
                    isMuted={isMuted}
                    callId={callId}
                    callStatus={callStatus}
                    voiceDropsIds={voiceDropsIds}
                    showForm={!callTransferred}
                    submittedDisabled={submittedDisabled}
                    setSubmittedDisabled={setSubmittedDisabled}
                    setTransferDisabled={setTransferDisabled}
                    isTransferDisabled={isTransferDisabled}
                    isOngoing={isOngoing}
                  />
                )}
              </Flex>

              {onPhone && !fourMativ && (
                <UIRow>
                  <UICol
                    sm={1}
                    gap={0}
                    alignItems="flex-start"
                    justifyContent="flex-end"
                  >
                    <Image src={Note} alt="Note" width="15px" />
                  </UICol>
                  <UICol gap={0}>
                    <SpeechContainer>{speech}</SpeechContainer>
                  </UICol>
                </UIRow>
              )}

              {!fourMativ && (
                <UIRow alignItems="center" justifyContent="center">
                  <UICol justifyContent="center" gap={1}>
                    <UIButton
                      onClick={onTransferClick}
                      disabled={
                        !Boolean(callId)
                        || !isOngoing
                        || isTransferDisabled
                        || callStatus === "paused"
                      }
                    >
                      Transfer
                    </UIButton>

                    <UIButton
                      onClick={handleSubmit}
                      disabled={!callId || callStatus === "paused"}
                    >
                      Leave VM
                    </UIButton>

                    <UIButton
                      onClick={() => toggleMute(!isMuted)}
                      disabled={!isTransferDisabled && !callId}
                    >
                      <FA
                        icon={
                          !!callId && !isMuted
                            ? faMicrophone
                            : faMicrophoneSlash
                        }
                      />
                    </UIButton>
                  </UICol>

                </UIRow>
              )}
            </UICard>
          </UICol>
          <UICol sm={12} md={12} lg={3} gap={0}>
            <UICard title="Notes" width="100%" height="430px">
            <FlaggedFilter contact={currentContact}/>
              <Notes
                canDelete
                canEdit={!callTransferred}
                currentContact={currentContact}
                setCurrentContact={setCurrentContact}
                isSDR={false}
                showHeader={false}
              />
            </UICard>
          </UICol>
        </UIRow>
      ) : (
        <ThreeDots text={"Waiting for contact"} />
      )}
    </>
  );
};

export default CallContainer;

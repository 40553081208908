import React, { ReactElement } from 'react';
import {Roles} from "../types/Roles";
import { Navigate, Outlet } from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from '../constants/routesConnect';
import { CurrentUser } from '../types';
const window = globalThis as any;

interface Props {
    roles: Roles[];
    children?: ReactElement
}

const RoleGuard = ({roles, children}: Props) => {
    let userType = []

    const userSession: CurrentUser = JSON.parse(window.localStorage.getItem('user') || '{}');

    if (!userSession.type) {
        return <Navigate replace to={PublicRoutes.LOGIN}/>
    }

    const isAdmin = userSession.type.some(item => item.type === Roles.ADMIN);
    if (isAdmin) {
        userType.push(Roles.ADMIN)
    }
    const isSdr = userSession.type.some(item => item.type === Roles.SDR);
    if (isSdr) {
        userType.push(Roles.SDR)
    }
    const isAgent = userSession.type.some(item => item.type === Roles.AGENT);
    if (isAgent) {
        userType.push(Roles.AGENT)
    }

    const roleFound = userType.some(item => {
        return roles.some(r => r === item);
    });

    if (!roleFound) {
        return isAdmin || isSdr ? <Navigate replace to={`/${PrivateRoutes.HOME}`}/> : <Navigate replace to={`/${PrivateRoutes.DIALER}`}/>
    }

    return  roleFound && children as ReactElement

}

export default RoleGuard;

import {
  IconDefinition,
  IconName,
  IconPrefix,
} from "@fortawesome/fontawesome-svg-core";

export const customTendrilLogo: IconDefinition = {
  icon: [
    91.25, // SVG view-box width (in pixels), // SVG view-box width (in pixels)
    91.25, // SVG view-box height (in pixels)
    [], // Aliases                         (no needed)
    "", // Unicode as hex value (no needed)
    "M72.88,54.82a21,21,0,0,1-6.59,6.07,4.64,4.64,0,0,1-.42.25L56.25,54.3A11.79,11.79,0,1,0,48.09,52S55.55,57.25,63,62.5l5.26,3.71a21.53,21.53,0,0,1-2.4,1.57,1,1,0,0,1-.32.19,16.13,16.13,0,0,1-2.26,1.08,20.27,20.27,0,0,1-5.6,1.46L49,64.34l-4.23-3h0a23.46,23.46,0,0,1-7-1.07,22.52,22.52,0,0,1-6.63-3.36c-9.66-1.15-17.1-8.82-17-18.06.07-8.7,6.79-15.93,15.69-17.54a19.92,19.92,0,0,1,4-.33h0a.51.51,0,0,1,.15,1,19.85,19.85,0,0,0-6.44,3.39,17.53,17.53,0,0,0-6.79,13.55,17.24,17.24,0,0,0,5.22,12.4,19,19,0,0,1-.43-19.84A22.14,22.14,0,0,1,44.71,20.75h0a.57.57,0,0,1,.16,1.12C36.37,24.65,30.24,32.17,30.24,41A20,20,0,0,0,40.92,58.46a21.59,21.59,0,1,1,32-3.64Z", // SVG path data
  ],
  iconName: "tendril-logo" as IconName,
  prefix: "custom" as IconPrefix,
};

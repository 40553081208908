import React from "react";
import styled from "styled-components";
import { Box } from "../../core/ui";

const OPTIONS = [
  {
    display: "No Voicemail",
    value: "noVoicemail",
    type: "radio",
    name: "reason",
  },
  {
    display: "Gatekeeper Conversation",
    value: "gatekeeperConversation",
    type: "radio",
    name: "reason",
  },
  {
    display: "Left Voicemail",
    value: "leftVoicemail",
    type: "radio",
    name: "reason",
  },
  {
    display: "Phone Refusal",
    value: "phoneRefusal",
    type: "radio",
    name: "reason",
  },
  {
    display: "Bad Number/Busy Signal",
    value: "badNumberBusySignal",
    type: "radio",
    name: "reason",
  },
  {
    display: "No Longer with Company",
    value: "noLongerWithCompany",
    type: "radio",
    name: "reason",
  },
  {
    display: "Reschedule call",
    value: "rescheduleCall",
    type: "radio",
    name: "reason",
    options: [
      { display: "Monday", value: "monday", type: "checkbox" },
      { display: "Tuesday", value: "tuesday", type: "checkbox" },
      { display: "Wednesday", value: "wednesday", type: "checkbox" },
      { display: "Thursday", value: "thursday", type: "checkbox" },
      { display: "Friday", value: "friday", type: "checkbox" },
      { display: "Morning", value: "morning", type: "checkbox" },
      { display: "Afternoon", value: "afternoon", type: "checkbox" },
      { display: "Evening", value: "evening", type: "checkbox" },
    ],
  },
  {
    display: "Info update",
    value: "infoUpdate",
    type: "radio",
    name: "reason",
    options: [
      { display: "Name", value: "name", type: "input" },
      { display: "Company", value: "company", type: "input" },
      { display: "Position", value: "position", type: "input" },
      { display: "Phone number", value: "phoneNumber", type: "input" },
      { display: "E-mail", value: "email", type: "input" },
      {
        display: "*Additional comments (mandatory)",
        value: "additional",
        type: "textArea",
      },
    ],
  },
];

const TitleContainer = styled(Box)`
  padding: 20px 25px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.2rem;
  font-weight: 800;
  background: linear-gradient(90deg, #0093d2 0%, #0a5690 50%);
`;

const OptionsContainer = styled(Box)`
  margin: 30px;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px ${({ theme }) => theme.colors.darkGray};
`;
const Option = styled(Box)`
  label {
    display: block;
  }
`;

const RateCall = (): JSX.Element => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <Box width="100%">
      <TitleContainer>Please rate your call</TitleContainer>
      <OptionsContainer>
        <form onSubmit={handleSubmit}>
          {OPTIONS.map((o) => (
            <Option key={o.value}>
              <label>
                <input
                  type={o.type}
                  id={o.value}
                  name={o.name}
                  value={o.value}
                />
                {o.display}
              </label>
            </Option>
          ))}
        </form>
      </OptionsContainer>
    </Box>
  );
};

export default RateCall;

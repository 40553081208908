import React, { useState, useEffect, useContext, ReactElement } from "react";
import styled from "styled-components";
import { UserProviderContext } from "../../../components/app/UserProvider";
import Loader from "../../../core/ui/UILoader";
import { Account, AccountType } from "../Shared/Entities/Account";
import { showUIToast } from "../../../core/ui/UIToast";
import {
  BaseField,
  BaseInput,
  BaseLabel,
} from "../../../core/ui/base/FormElements";
import { UIButton, UIMiniButton } from "../../../core/ui/UIElements";
import { Flex } from "../../../core/ui";
import hubspotLogo from "../Hubspot/hubspot.svg";
import routes from "../../../constants/routes";
import axios from "axios";
import { HubspotApp } from "../../../types/HubspotAppTypes";
import saveHubspotCustomConfigAsync from "../../../api/Sync/saveHubspotCustomConfigAsync";
import getHubspotCustomConfigAsync from "../../../api/Sync/getHubspotCustomConfigAsync";
import IntegrationUser from "../Shared/Components/integrations/IntegrationUser.component";
import DeleteAccountModal from "../Shared/Components/DeleteAccountModal";
import deleteHubspotAccountAsync from "../../../api/Sync/deleteHubspotAccountAsync";

const HubspotContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-height: 395px;
`;

const IntegrationsList = styled.div<{
  scrollStartingAt: number;
}>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  ${({ scrollStartingAt }) =>
    scrollStartingAt > 0 &&
    `
    max-height: calc(82px * ${scrollStartingAt} + (.5rem * ${scrollStartingAt}));
    overflow-y: scroll;
  `}
`;

export const HubspotCustomApp = (): ReactElement => {
  const { currentUser } = useContext(UserProviderContext);
  const [hubspotApp, setHubspotApp] = useState<HubspotApp>({
    id: "",
    appId: "",
    accessKey: "",
    accessSecret: "",
    url: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    organizationId: "",
    userId: "",
    callbackUrl: "",
  });
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [state, dispatch] = useState<{
    ready: boolean;
    configuring: boolean;
    message: string;
    loading: boolean;
    loadingMessage: string;
    hasConfig: boolean;
  }>({
    ready: true,
    configuring: false,
    message: "Use button above to connect Hubspot.",
    loading: true,
    loadingMessage: "Loading linked accounts",
    hasConfig: false,
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    nonce: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const { hubspotConfig } = await getHubspotCustomConfigAsync(
        currentUser.userId,
        currentUser.organizationId
      );
      if (hubspotConfig) {
        setHubspotApp(hubspotConfig);
        dispatch((state) => ({
          ...state,
          hasConfig:
            hubspotConfig.appId !== "" &&
            hubspotConfig.accessKey !== "" &&
            hubspotConfig.accessSecret !== "",
        }));
      } else {
        showUIToast({
          type: "error",
          text: "An error occurred while getting Hubspot configuration, please try later.",
        });
        dispatch((state) => ({ ...state, hasConfig: false }));
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (accounts.length === 0) {
      changeMessage("No accounts linked.");
    } else {
      changeMessage(
        `You have ${accounts.length} ${
          accounts.length === 1 ? "account" : "accounts"
        } linked.`
      );
    }
  }, [accounts.length]);

  const handleConnect = async () => {
    try {
      const url = `${routes.post.customHubspot.oAuthLogin}/${currentUser.organizationId}/${currentUser.userId}`;
      const res = await axios.post<{
        url: string;
      }>(url);
      if (res.status === 201) {
        window.open(res.data.url, "hubspotPopup", `width=${500},height=${500}`);
        getAccountsInterval();
        showUIToast({
          type: "default",
          text: "Hubspot connected",
        });
        return dispatch((state) => ({
          ...state,
          configuring: true,
        }));
      }
    } catch (err) {
      hubspotError();
    }
  };

  const hubspotError = () => {
    showUIToast({
      type: "error",
      text: "Can't configure Hubspot right now, please try again later.",
    });
    return dispatch((state) => ({
      ...state,
      ready: false,
      message: "Can't configure Hubspot right now, please try again later.",
    }));
  };

  const changeMessage = (message: string) =>
    dispatch((state) => ({ ...state, message }));

  const handleCancel = () => {
    // TODO: stop current configuration and show the corresponding ui toast messages
    dispatch((state) => ({
      ...state,
      configuring: false,
      message: "Use button above to connect Hubspot.",
    }));
  };

  const getAccounts = async (wipeInterval?: () => void) => {
    dispatch((state) => ({
      ...state,
      loading: false,
    }));
    try {
      setAccounts([]);
      const listenUrl = `${routes.get.customHubspot.getLinkedAccounts}/${currentUser.organizationId}/${currentUser.userId}`;
      const res = await axios.get<Account[]>(listenUrl);
      if (res.status === 202) {
        showUIToast({
          type: "info",
          text: "Hubspot syncing in progress.",
        });
        return dispatch((state) => ({
          ...state,
          configuring: true,
          message: "Configuration in process.",
        }));
      }
      if (res.status === 204) {
        if (wipeInterval) wipeInterval();
        dispatch((state) => ({
          ...state,
          configuring: false,
        }));
        showUIToast({
          type: "info",
          text: "Hubspot synced.",
        });
        return changeMessage("Nothing was configured.");
      }
      if (res.status === 200) {
        if (wipeInterval) wipeInterval();
        const amount = res.data.length || 0;
        changeMessage(
          `You have ${amount} ${amount > 1 ? "account" : "accounts"} linked.`
        );
        dispatch((state) => ({
          ...state,
          loading: false,
          configuring: false,
        }));
        showUIToast({
          type: "info",
          text: `HubSpot Custom linked with ${amount} ${
            amount > 1 ? "account" : "accounts"
          }.`,
        });
        return setAccounts(res.data ?? []);
      }
    } catch (err) {
      if (wipeInterval) wipeInterval();
      dispatch((state) => ({
        ...state,
        loading: false,
      }));
      console.log(err);
      return changeMessage("No accounts linked.");
    }
  };

  const getAccountsInterval = () => {
    const interval = setInterval(
      () => getAccounts(() => clearInterval(interval)),
      100
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setHubspotApp({ ...hubspotApp, [name]: value });
  };

  const handleSave = async () => {
    const { hubspotConfig } = await saveHubspotCustomConfigAsync(
      currentUser.organizationId,
      currentUser.userId,
      hubspotApp
    );
    if (hubspotConfig) {
      setHubspotApp({ ...hubspotApp, ...hubspotConfig });
      showUIToast({
        type: "success",
        text: "Hubspot configuration saved.",
      });
      dispatch((state) => ({ ...state, hasConfig: true }));
    } else {
      showUIToast({
        type: "error",
        text: "Error saving Hubspot configuration, please try again later.",
      });
      dispatch((state) => ({
        ...state,
        hasConfig: false,
        message: "An error occurred while saving the configuration",
      }));
    }
  };

  const removeHubspotUser = async (nonce: string) => {
    const isCustomAccount = true;
    /// TODO remove user from state and everywhere else
    const { success, error } = await deleteHubspotAccountAsync(
      currentUser.organizationId,
      currentUser.userId,
      nonce,
      isCustomAccount
    );

    if (success) {
      const newUsers = accounts.filter((elem) => {
        return elem.nonce !== nonce;
      });
      setAccounts(newUsers);
      showUIToast({
        type: "info",
        text: "Hubspot account removed.",
      });
      setDeleteModal({ isOpen: false, nonce: "" });
    } else if (error) {
      showUIToast({
        type: "error",
        text: "Error removing Hubspot account.",
      });
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <HubspotContainer>
      <DeleteAccountModal
        isOpen={deleteModal.isOpen}
        onClose={() => setDeleteModal({ isOpen: false, nonce: "" })}
        handleDelete={() => removeHubspotUser(deleteModal.nonce)}
      />

      {state.loading ? (
        <Loader message={state.loadingMessage} />
      ) : (
        <>
          <BaseField>
            <BaseLabel>
              Add your developer account info to minimize service interruptions.{" "}
              <a
                target="_blank"
                href="https://developers.hubspot.com/docs/api/creating-an-app"
                rel="noreferrer"
              >
                Learn how to set up developer accounts.
              </a>
            </BaseLabel>
          </BaseField>
          <BaseField>
            <BaseLabel title="App Id">App Id</BaseLabel>
            <BaseInput
              data-qa-id={"scriptInput"}
              type="text"
              name="appId"
              value={hubspotApp.appId || ""}
              onChange={handleInputChange}
            />
          </BaseField>
          <BaseField>
            <BaseLabel title="Client Id">Client Id</BaseLabel>
            <BaseInput
              data-qa-id={"scriptInput"}
              type="text"
              name="accessKey"
              value={hubspotApp.accessKey || ""}
              onChange={handleInputChange}
            />
          </BaseField>
          <BaseField>
            <BaseLabel title="Client Secret">Client Secret</BaseLabel>
            <BaseInput
              data-qa-id={"scriptInput"}
              type="text"
              name="accessSecret"
              value={hubspotApp.accessSecret || ""}
              onChange={handleInputChange}
            />
          </BaseField>
          <BaseField>
            <BaseLabel title="Redirect URL (OAuth)">
              Redirect URL (OAuth)
            </BaseLabel>
            <BaseInput
              data-qa-id={"scriptInput"}
              type="text"
              name="callbackUrl"
              readOnly={true}
              value={hubspotApp.callbackUrl || ""}
            />
          </BaseField>
          <Flex flexDirection="row" justifyContent="flex-end" width="100%">
            <UIButton onClick={handleSave}>Save</UIButton>
          </Flex>
          {state.hasConfig && (
            <>
              <UIMiniButton
                className="hubspot"
                style={{ justifySelf: "center" }}
                disabled={state.configuring}
                onClick={handleConnect}
              >
                <img alt="Hubspot" src={hubspotLogo} width="120" />
              </UIMiniButton>
              {state.configuring && (
                <UIMiniButton onClick={handleCancel}>
                  Cancel Configuration
                </UIMiniButton>
              )}
              <IntegrationsList scrollStartingAt={accounts.length > 10 ? 5 : 0}>
                {accounts.map((user) => {
                  console.log("accounts", accounts);
                  const { id, attributes, email, nonce } = user;
                  console.log("user", user);
                  return (
                    <IntegrationUser
                      key={nonce}
                      remove={(nonce, type) => {
                        console.log("Remove hubspot custom user", nonce, type);
                        setDeleteModal({ isOpen: true, nonce });
                      }}
                      id={id}
                      type={AccountType.Hubspot}
                      email={email}
                      nonce={nonce}
                      {...attributes}
                    />
                  );
                })}
              </IntegrationsList>
              <div>{state.message}</div>
            </>
          )}
        </>
      )}
    </HubspotContainer>
  );
};

import { type CallDisposition } from '../../../DataSource/sync/hubspot-mappings/dto/call-disposition.dto';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import getHubspotCustomDispositions from '../../../DataSource/sync/hubspot-mappings/getHubspotCustomDispositions';

interface HubspotMappingsRepositoryProps {
  getHubspotCallDispositions: (organizationId: string, nonce: string) => Promise<ApiResponse<CallDisposition[]>>;
}

export function HubspotMappingsRepository(): HubspotMappingsRepositoryProps {
  return {
    async getHubspotCallDispositions(organizationId: string, nonce: string) {
      const { data, error, success } = await getHubspotCustomDispositions(organizationId, nonce);

      if (data == null || error != null) {
        return { error, success };
      }

      const customCallDisposition: CallDisposition[] = data.map((disposition) => {
        return {
          id: disposition.id,
          attributes: {
            name: disposition.label,
            outcome: disposition.label,
            // deleted: disposition.deleted,
          },
          type: 'callDisposition',
        };
      });

      return { data: customCallDisposition, success: true };
    },
  };
}

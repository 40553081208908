import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";

enum ErrorCategory {
  CLIENT_ERROR = "CLIENT_ERROR",
  SERVER_ERROR = "SERVER_ERROR",
  NETWORK_ERROR = "NETWORK_ERROR",
  OTHER_ERROR = "OTHER_ERROR",
}

const categorizeError = (status: number): ErrorCategory => {
  if (status >= 400 && status < 500) {
    return ErrorCategory.CLIENT_ERROR;
  } else if (status >= 500 && status < 600) {
    return ErrorCategory.SERVER_ERROR;
  } else if (status === 0) {
    return ErrorCategory.NETWORK_ERROR;
  } else {
    return ErrorCategory.OTHER_ERROR;
  }
};

export interface ApiErrorResponse {
  category?: string;
  message: string;
  error?: {
    message: string;
    url: string;
    method: string;
    componentStack: string;
    statusCode: number;
  };
}

interface LogAxiosError {
  payload: AxiosError;
  description: string;
}

export const logAxiosErrorWithSentry = ({
  description,
  payload,
}: LogAxiosError): void => {
  const { response } = payload;

  if (!response) {
    console.error("Request Error:", payload.message);
    Sentry.captureException(payload);
    return;
  }

  const { status } = response;
  const category = categorizeError(status);

  const apiErrorResponse: ApiErrorResponse = {
    category,
    message: description,
    error: {
      message: payload.message,
      url: payload.config.url || "",
      method: payload.config.method || "",
      componentStack: payload.stack || "",
      statusCode: status,
    },
  };

  switch (category) {
    case ErrorCategory.CLIENT_ERROR:
      console.error("Client Error:", payload.message);
      break;
    case ErrorCategory.SERVER_ERROR:
      console.error("Server Error:", payload.message);
      break;
    case ErrorCategory.NETWORK_ERROR:
      console.error("Network Error:", payload.message);
      break;
    default:
      console.error("Other Error:", payload.message);
      break;
  }

  Sentry.captureException(apiErrorResponse);
};

import { OutreachRepository } from '../../../Data/Repository/sync/outreach/outreachRepository';
import { type OutreachUseCase as OutreachUseCaseType } from './outreach.usecase';

export function OutreachUseCase(): OutreachUseCaseType {
  const { getOutreachAccounts } = OutreachRepository();

  return {
    async getOutreachAccounts(userId: string, organizationId: string) {
      const { data, error, success } = await getOutreachAccounts(userId, organizationId);
      return { data, error, success };
    },
  };
}

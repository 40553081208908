import React, { type FC } from 'react';
import { FlagIcon } from 'lucide-react';
import { Button } from '@components/ui/button';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@components/ui/sheet';
import FeatureFlagsForm from '../../../Forms/FeatureFlags.form';
import FeatureFlagViewModel from './FeatureFlags.viewmodel';

interface FeatureFlagsDialogProps {
  organization: {
    name: string;
    id: string;
  };
}

const FeatureFlagsDialog: FC<FeatureFlagsDialogProps> = ({ organization }: FeatureFlagsDialogProps) => {
  const featureFlagViewModel = FeatureFlagViewModel();
  const organizationName = `${organization.name.split(' ')[0].toUpperCase()}'s`;
  const title = `${organizationName} Feature Flags switch.`;

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => {
            void featureFlagViewModel.getFlags(organization.id);
          }}
        >
          <FlagIcon className="h-4 w-4" />
        </Button>
      </SheetTrigger>
      <SheetContent className="w-[400px] sm:w-[540px] md:w-[768px] space-y-8">
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
          <SheetDescription>Toggle feature flags for {organizationName} organization.</SheetDescription>
        </SheetHeader>

        <FeatureFlagsForm viewModel={featureFlagViewModel}>
          <SheetFooter>
            <SheetClose asChild>
              <Button type="submit">Save changes</Button>
            </SheetClose>
          </SheetFooter>
        </FeatureFlagsForm>
      </SheetContent>
    </Sheet>
  );
};

export default FeatureFlagsDialog;

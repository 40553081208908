enum TIME_ZONE {
  US_HAWAII = 'US/Hawaii',
  US_ALASKA = 'US/Alaska',
  US_PACIFIC = 'US/Pacific',
  US_MOUNTAIN = 'US/Mountain',
  US_CENTRAL = 'US/Central',
  US_EASTERN = 'US/Eastern',
  CANADA_ATLANTIC = 'Canada/Atlantic',
  MEXICO_BAJA_CALIFORNIA = 'Mexico/BajaCalifornia',
  MEXICO_CHIHUAHUA = 'Mexico/Chihuahua',
  AMERICA_CENTRAL_AMERICA = 'America/CentralAmerica',
  MEXICO_GENERAL = 'Mexico/General',
  UTC = 'UTC',
}
export default TIME_ZONE;

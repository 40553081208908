import routes from "../../constants/routes";
import axios from "axios";
import { CallSessionSuccessResponse } from "../../components/shared/CallSession";

type ResponseProps = {
  callSession?: CallSessionSuccessResponse | null;
  error?: { message: string };
};

const getByIdAsync = async (id: string): Promise<ResponseProps> => {
  try {
    const callSession = await axios.get<CallSessionSuccessResponse>(
      routes.get.callSession.getByIdNest + `/${id}`
    );
    return { callSession: callSession.data };
  } catch {
    return { error: { message: "Error getting call session" } };
  }
};

export default getByIdAsync;

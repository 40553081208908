import React from "react";
import {
  toast,
  ToastContainer,
  ToastOptions,
  TypeOptions,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons/faCircleExclamation";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons/faCircleCheck";
import styled, { useTheme } from "styled-components";
import { UIText } from "./UIElements";
import { statusColors } from "../theme"

type Props = {
  toastId?: string;
  type?: TypeOptions;
  text: string;
  close?: boolean;
};

/** List of the Types of UI Toast*/
export enum UI_TOAST_TYPES {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  DEFAULT = "default",
}

const UIToast: React.FC<Props> = ({ type = UI_TOAST_TYPES.DEFAULT, text }) => {
  const icons: Record<TypeOptions, IconDefinition> = {
    warning: faTriangleExclamation,
    error: faCircleExclamation,
    success: faCircleCheck,
    info: faInfoCircle,
    default: faBell,
  };

  return (
    <>
      <ToastBox background={statusColors.callBtnBg}>
        <FA icon={icons[type]} size="xl" color="white" />
        <ToastText>{text}</ToastText>
      </ToastBox>
    </>
  );
};

export const showUIToast = ({ type, text, toastId, close = true }: Props): void => {
  const options: ToastOptions = {
    position: "bottom-right",
    rtl: false,
    autoClose: !close ? close : 3000,
    hideProgressBar: true,
    closeOnClick: close,
    pauseOnHover: true,
    closeButton: false,
    icon: false,
    toastId: toastId || `${(type ??= UI_TOAST_TYPES.DEFAULT)}-${text}`,
    type,
  };

  toast(<UIToast type={type} text={text} />, options);
};

export const hideUIToast = (toastId: string): void => toast.dismiss(toastId);

const UIToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    width: fit-content;
    min-height: auto;
    padding: 0;
    background: transparent;
  }
  .Toastify__toast-body {
    padding: 0;
  }
`;

const ToastBox = styled.div<{ background: string }>`
  display: flex;
  gap: 1rem;
  align-items: center;
  min-width: 240px;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  background: ${({ background }) => background};
`;
const ToastText = styled(UIText)`
  font-weight: 600;
  font-size: 1.125rem;
  color: white;
`;

export default UIToastContainer;

import React, { useContext, type FC, type ReactNode } from 'react';
import { FeatureFlags, featuresStore } from '../components/app/FeaturesProvider';
import { NoFeaturesEnabled } from '../pages/Settings/Settings';

interface FeatureFlagGuardProps {
    features: FeatureFlags[];
    children: ReactNode;
}

export const FeatureFlagGuard: FC<FeatureFlagGuardProps> = ({ features, children }) => {
    const { featuresState } = useContext(featuresStore);

    const featureEnabled = features.some((feature) => featuresState[feature] === true);

    if (!featureEnabled) {
        return <NoFeaturesEnabled />;
    }

    return <>{children}</>;
};
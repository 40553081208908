import React, { FC, ReactNode } from "react";
import { SettingMenuOption } from "../types/settings-menu-option.type";
import NavItem from "./NavItem.component";
import animations from '../../../../core/ui/base/Animations';
import { motion } from 'framer-motion';

interface SettingsNavProps {
  menuOptions: SettingMenuOption[];
  component: ReactNode;
}

const SettingsNav: FC<SettingsNavProps> = ({ menuOptions, component }) => {
  return (
    <>
      <aside
          role="navigation"
        id="default-sidebar"
        className="w-72 my-5 border-solid border-r border-gray-300"
        aria-label="Sidenav"
      >
        <div className="overflow-y-auto py-5 px-3 h-full w-full bg-white">
          <ul role="list" className="space-y-2">
            {menuOptions.map(
              (option) =>
                option.isAvailable && (
                  <NavItem
                    key={option.link + option.label}
                    menuOption={option}
                  />
                )
            )}
          </ul>
        </div>
      </aside>
      <motion.section
          role="main"
        className="container px-3 py-6  mx-auto text-gray-500 w-full"
        {...animations.fadeInFromTop}
      >
        {component}
      </motion.section>
    </>
  );
};

export default SettingsNav;

import { type OutreachRepo } from './outreach.repository';
import getOutreachAccounts from '../../../DataSource/sync/outreach/getOutreachAccounts';

export function OutreachRepository(): OutreachRepo {
  return {
    async getOutreachAccounts(userId: string, organizationId: string) {
      const { data, error, success } = await getOutreachAccounts(userId, organizationId);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}

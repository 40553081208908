import routes from "../../constants/routes";
import axios from "axios";
import { ContactResponse } from "../../components/shared/Contact";
type ResponseProps = {
  data?: ContactResponse;
  error?: { message: string };
};

const consumeContactAsync = async (
  callSessionId: string,
  userId: string
): Promise<ResponseProps> => {
  try {
    const response = await axios.get<ContactResponse>(
      routes.get.rabbit.consumeNest + `/${callSessionId}/${userId}`
    );
    return { data: response.data };
  } catch (e) {
    return {
      error: {
        message: "Error getting contact",
      },
    };
  }
};

export default consumeContactAsync;

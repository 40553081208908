import React, { useContext, useEffect, useState } from "react";
import UICard from "../../../core/ui/UICard";

import { BaseField, BaseInput } from "../../../core/ui/base/FormElements";
import { Flex } from "../../../core/ui";
import { UIButton, UIIconButton } from "../../../core/ui/UIElements";
import styled from "styled-components";
import Loader from "../../../core/ui/UILoader";
import UITable, {
  GenericTableHeader,
  PaginatedProps,
  SearchProps,
} from "../../../core/ui/UITable";
import { NumberPool } from "../../../types";
import { UserProviderContext } from "../../../components/app/UserProvider";
import { showUIToast } from "../../../core/ui/UIToast";
import registreNumberCaller from "../../../api/CallerId/registreNumberCaller";
import validateCode from "../../../api/CallerId/validateCode";
import getUserNumbersAsync from "../../../api/CallerId/getUserNumbers";
import EditUserNumberModal from "./UpdateUserNumber";
import Inactive from "../../../assets/icons/summary/xmark.svg";
import Active from "../../../assets/icons/summary/active.svg";
import DeleteUserNumberModal from "./DeleteUserNumberModal";
import getCurrentNumberUser from "../../../api/CallerId/getCurrentNumberUser";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const icons = {
  ACTIVE: Active,
  INACTIVE: Inactive,
};

const SectionTitle = styled.p`
  text-align: center;
  padding: 20px;
  font-size: 20px;
  display: flex;
  justify-content: center;
`;

const CurrentNumberLabel = styled.label`
  color: limegreen;
`;

const SectionInfo = styled.p`
  padding: 20px;
  display: flex;
`;

const CodeLabel = styled.div`
  text-align: center;
  padding: 12px 40px;
  font-size: 25px;
`;

const LoadingLabel = styled.label`
  padding: 15px;
  font-size: 25px;
`;

const IconText = styled.div`
  text-align: center;
  padding: 0 10px;
`;

const CallContainer = styled.div`
  padding: 30px 10px;
`;
const CallerIdContainer = (): JSX.Element => {
  const [loadingStatus, updateLoadingStatus] = useState<{
    sequences: boolean;
    numbers: boolean;
  }>({ sequences: true, numbers: true });
  const [paginatedData, setPaginatedData] = useState<
    PaginatedProps | undefined
  >(undefined);
  const [searchData, setSearchData] = useState<SearchProps | undefined>(
    undefined
  );
  const [numberInput, setNumberInput] = useState("");
  const { currentUser } = useContext(UserProviderContext);
  const [ValidationCode, setValidationCode] = useState("XXX-XXX");
  const [isCalling, setIsCalling] = useState(false);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [userNumbers, setUserNumbers] = useState<NumberPool[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState<any>(null);
  const [currentNumber, setCurrentNumber] = useState("");

  const cleanup = () => {
    setSelectedNumber(null);
  };
  const handleOnCloseEditModal = () => {
    setShowEditModal(false);
    cleanup();
  };

  const handleEditNumber = (numberPool) => {
    setSelectedNumber(numberPool);
    setShowEditModal(true);
  };

  const handleShowDeleteNumberModal = (numberPool) => {
    setSelectedNumber(numberPool);
    setShowDeleteModal(true);
  };

  const handleOnSuccessModal = () => {
    getUserNumbers(1, search);
    getCurrentNumber();
    setShowDeleteModal(false);
    setShowEditModal(false);
    cleanup();
  };

  const handleOnCloseDeleteNumberModal = () => {
    setShowDeleteModal(false);
    cleanup();
  };

  const renderNumberStatus = (numberPool: NumberPool) => {
    let icon = icons["INACTIVE"];

    if (numberPool.active) {
      icon = icons["ACTIVE"];
    }

    return (
      <div>
        <img width={"15px"} src={icon} alt="icon" />
      </div>
    );
  };

  const renderOperations = (numberPool) => (
    <Flex gap="20px">
      <UIIconButton onClick={() => handleEditNumber(numberPool)}>
        <FA icon={faPenToSquare} size="1x" className="text-app-blue-light" />
      </UIIconButton>
      <UIIconButton onClick={() => handleShowDeleteNumberModal(numberPool)}>
        <FA icon={faTrash} size="1x" />
      </UIIconButton>
    </Flex>
  );

  const headers: GenericTableHeader[] = [
    { text: "Numbers", width: "2" },
    { text: "Location", width: "2" },
    { text: "Type", width: "2" },
    { text: "Status", width: "2" },
    { text: "", width: "0.5" },
  ];

  const tableBodies = [
    "number",
    "location",
    "type",
    { id: "active", customRender: true, renderFunc: renderNumberStatus },
    { id: "operations", customRender: true, renderFunc: renderOperations },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNumberInput(value);
  };

  const handleNumberCreate = async () => {
    const { code, error } = await registreNumberCaller(
      numberInput,
      currentUser.userId
    );

    if (!code || error) {
      showUIToast({ type: "error", text: "Failed to call number" });
      return;
    }

    setValidationCode(code);
    setIsCalling(true);

    let timesRun = 0;
    const intervalId = setInterval(async function () {
      const { validated, error } = await validateCode(
        numberInput,
        currentUser.userId
      );

      if (error) {
        showUIToast({ type: "error", text: "Failed processing number." });
      }

      timesRun += 1;
      if (validated) {
        clearInterval(intervalId);
        setIsCalling(false);
        setValidationCode("XXX-XXX");
        setNumberInput("");
        getUserNumbers(1, "");
        getCurrentNumberUser();
        showUIToast({ type: "info", text: "Number validated." });
      }

      if (timesRun > 12) {
        clearInterval(intervalId);
        setIsCalling(false);
        setValidationCode("XXX-XXX");
        setNumberInput("");
        showUIToast({
          type: "error",
          text: "Failed to complete the validation.",
        });
      }
    }, 5000);
  };

  const getUserNumbers = async (pageNumber: number, searchValue: string) => {
    const { data, totalCount, totalPages, error } = await getUserNumbersAsync(
      pageNumber,
      pageSize,
      searchValue
    );

    setPageNumber(pageNumber);
    setUserNumbers(data === undefined ? [] : data);
    setPaginatedData({
      pageNumber,
      totalCount: totalCount ? totalCount : 0,
      totalPages: totalPages ? totalPages : 1,
      pageSize,
      onPrevPage: handlePrev,
      onNextPage: handleNext,
    });
    setSearchData({
      placeHolder: "Search by number",
      onSearch: handleSearch,
    });

    updateLoadingStatus({ ...loadingStatus, numbers: false });

    error && showUIToast({ type: "error", text: error.message });
  };

  const getCurrentNumber = async () => {
    const { currentNumber, error } = await getCurrentNumberUser();

    if (error || !currentNumber) {
      showUIToast({ type: "info", text: "failed to get the current number" });
    }

    setCurrentNumber(currentNumber ? currentNumber : "");
  };

  const handleSearch = async (searchData) => {
    await setPageNumber(1);
    await setSearch(searchData);
  };

  const handlePrev = () => {
    setPageNumber((last) => last - 1);
  };

  const handleNext = () => {
    setPageNumber((last) => last + 1);
  };

  useEffect(() => {
    getUserNumbers(pageNumber, search);
  }, [pageNumber, search, isCalling]);

  useEffect(() => {
    getCurrentNumber();
  }, [pageNumber, isCalling]);

  return (
    <>
      <UICard
        title={"Contacts from selected List"}
        showTitle={false}
        width={"100%"}
      >
        <div>
          <SectionTitle>
            Current Number:{"  "}
            <CurrentNumberLabel> {currentNumber}</CurrentNumberLabel>
            <IconText>
              <img width={"15px"} src={icons["ACTIVE"]} alt="icon" />
            </IconText>
          </SectionTitle>

          <SectionInfo>
            <IconText>
              <FA
                icon={faInfo}
                size="lg"
                className="border-solid border-2 border-app-blue-light py-1 px-2.5 rounded-full text-app-blue-light"
              />
            </IconText>
            To add your personal number type in the box below, press call me and
            follow the instructions, the code you need to introduce is
            underneath
          </SectionInfo>

          <CallContainer>
            <Flex flexDirection="row" width="100%">
              <Flex flexDirection="row" justifyContent="flex-start" width="30%">
                <BaseField>
                  <BaseInput
                    data-qa-id={"scriptInput"}
                    type="text"
                    name="appId"
                    value={numberInput}
                    placeholder={"+18667404470"}
                    onChange={handleInputChange}
                  />
                </BaseField>
              </Flex>

              {isCalling ? (
                <LoadingLabel> Calling... </LoadingLabel>
              ) : (
                <UIButton
                  onClick={handleNumberCreate}
                  style={{ margin: "0 20px" }}
                >
                  Call Me
                </UIButton>
              )}

              {isCalling && (
                <CodeLabel> Validation code: {ValidationCode} </CodeLabel>
              )}
            </Flex>
          </CallContainer>
        </div>
        {userNumbers.length > 0 ? (
          loadingStatus.numbers ? (
            <Loader message={"Loading Numbers, please wait..."} />
          ) : (
            <UITable
              headers={headers}
              tableBodies={tableBodies}
              data={userNumbers}
              visible={false}
              paginatedData={paginatedData}
              searchData={searchData}
            />
          )
        ) : (
          "You don't have phone numbers yet"
        )}
      </UICard>
      <EditUserNumberModal
        isOpen={showEditModal}
        onClose={handleOnCloseEditModal}
        selectedNumber={selectedNumber}
        onSuccess={handleOnSuccessModal}
      />
      <DeleteUserNumberModal
        isOpen={showDeleteModal}
        onClose={handleOnCloseDeleteNumberModal}
        selectedNumber={selectedNumber}
        onSuccess={handleOnSuccessModal}
      />
    </>
  );
};

export default CallerIdContainer;

import React from "react";
import styled from "styled-components";
import { UIText } from "../../core/ui/UIElements";
import constants from "../../constants.json";

const WatermarkContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 50%;
  transform: translateX(50%);
  padding: 0.75rem 1.5rem;
  backdrop-filter: brightness(0.9);
  border-radius: 5px;
`;

const CustomUIText = styled(UIText)`
  font-size: 1.5em;
  font-weight: 500;
  color: #f1f1f1;
  text-align: center;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
    color: #f1f1f1;
  }
  user-select: none;
`;

enum EnvironmentName {
  Local = "Local",
  Alpha = "Alpha",
  Beta = "Beta",
}

const EnvironmentWatermark: React.FC = () => {
  const environment = constants.env;
  let friendlyEnvironmentName: EnvironmentName | "" = "";

  switch (environment) {
    case "local":
      friendlyEnvironmentName = EnvironmentName.Local;
      break;
    case "qa":
      friendlyEnvironmentName = EnvironmentName.Alpha;
      break;
    case "preprod":
      friendlyEnvironmentName = EnvironmentName.Beta;
      break;
    default:
      return null;
  }

  return (
    <WatermarkContainer>
      <CustomUIText>{`⚠️ ${friendlyEnvironmentName} environment, for testing purposes only - ${constants.version}`}</CustomUIText>
    </WatermarkContainer>
  );
};

export default EnvironmentWatermark;

import routes from "../../constants/routes";
import { ContactList } from "../../components/shared/ContactList";
import { logAxiosErrorWithSentry } from "../../core/utils/axios.utils";
import axios from "axios";

type ResponseProps = {
  contactList?: ContactList;
  error?: { message: string };
};

const getContactListAsync = async (
  contactListId: string
): Promise<ResponseProps> => {
  try {
    const contactList = await axios.get<ContactList>(
      routes.get.contacts.byContactListIdNest + "/" + contactListId
    );

    return { contactList: contactList?.data };
  } catch (e) {
    const message = "Error getting contact list";

    if (axios.isAxiosError(e)) {
      logAxiosErrorWithSentry({
        payload: e,
        description: message,
      });
    }

    return {
      error: { message },
    };
  }
};

export default getContactListAsync;

import React, { type ButtonHTMLAttributes, type ReactElement } from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { getClassNames } from './buttons.utils';

interface ButtonWithIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type: 'submit' | 'button' | 'reset';
  icon: IconDefinition;
  text: string;
  loading?: boolean;
}

export default function ButtonIconText({
  loading,
  icon,
  type,
  text,
  ...buttonProps
}: ButtonWithIconProps): ReactElement<HTMLButtonElement> {
  return (
    <button
      type={type}
      disabled={loading === true || buttonProps?.disabled}
      name={text}
      className={getClassNames(type)}
      {...buttonProps}
    >
      {loading === true ? (
        <FA icon={faSpinner} spinPulse size="lg" name="close" />
      ) : (
        <FA icon={icon} size="lg" name="add new product" />
      )}
      {text}
    </button>
  );
}

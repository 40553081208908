import React, { type FC } from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faRightLong } from '@fortawesome/free-solid-svg-icons';
import { type Disposition } from '../../../../../types/disposition-mapping.type';
import { motion } from 'framer-motion';
import animations from '../../../../../../../../src/core/ui/base/Animations';
import ReactTooltip from 'react-tooltip';
import { colors } from '../../../../../../../../src/core/theme';
import { type HubspotMappingsViewmodel } from '../HubspotMappings.viewmodel';
import MappingDropdown from './MappingDropdown.component';

const { tr: Tr } = motion;

interface MappingRowProps {
  viewModel: HubspotMappingsViewmodel;
  mapping: Disposition;
}

const MappingRow: FC<MappingRowProps> = ({ viewModel, mapping }) => {
  const { isSuperAdmin, isAdmin, isHubspotAccount } = viewModel.viewState;

  const isSelectAvailable =
    ((isHubspotAccount && isSuperAdmin) || (isHubspotAccount && isAdmin)) && mapping.abbreviation !== 'default_action';

  return (
    <Tr className="table-row" {...animations.fadeInFromTop}>
      <td
        className="table-cell py-1 px-2"
        data-tip={mapping.defaultAction}
        data-for={mapping.abbreviation + '-tooltipId'}
      >
        {mapping.defaultAction}
        <ReactTooltip
          id={mapping.abbreviation + '-tooltipId'}
          place="left"
          type="info"
          effect="solid"
          backgroundColor={colors.lightBlue}
        />
      </td>
      <td className="table-cell py-1 px-2 text-center">
        <FA icon={faRightLong} size="xl" />
      </td>
      <td className="table-cell py-1  px-2">
        <div className="flex flex-row justify-between gap-2">
          <>
            {isSelectAvailable ? (
              <MappingDropdown key={mapping.id + '-dropdown'} rowMapping={mapping} viewModel={viewModel} />
            ) : (
              <h4 key={mapping.id + '-label'} className="text-lg text-bold">
                {mapping.customName}
              </h4>
            )}
          </>
        </div>
      </td>
    </Tr>
  );
};

export default MappingRow;

export const config = {
  url: {
    edgeUrl: "https://api.tendril.dev:20020",
    edgeNestUrl: "https://api.tendril.dev:20050",
    contactsUrl: "https://api.tendril.dev:20010",
    syncUrl: "https://api.tendril.dev:20030",
    contactsNestUrl: "https://api.tendril.dev:20040",
    authUrl: "https://api.tendril.dev:20060"
  },
  sentry: {
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: "qa",
  },
  analytics: {
    trackingCode: "G-HK8MFQL8JJ",
    tagManager: "GTM-NMQKCD8",
  },
  domainProd: "",
};

import { type Contact } from '../../../Data/DataSource/contacts-nest/contacts/entities/contact.entity';
import { type ApiResponse } from '../../../../src/types/ApiResponse';
import { ContactsRepository } from '../../../Data/Repository/contacts-nest/contacts/contactsRepository';
import { type SelectedFilters } from '../../interfaces/Contacts/filter.interfaces';

interface ContactsUseCaseProps {
  getContactsByCallSessionIds: (
    userId: string,
    callSessionIds: string[],
    filters: SelectedFilters,
  ) => Promise<ApiResponse<Contact[]>>;

  updateContactAsync: (contact: Contact) => Promise<ApiResponse<Contact>>;
}

export function ContactsUseCase(): ContactsUseCaseProps {
  const { getContactsByCallSessionIds, updateContactAsync } = ContactsRepository();

  return {
    async getContactsByCallSessionIds(
      userId: string,
      callSessionIds: string[],
      filters: SelectedFilters,
    ): Promise<ApiResponse<Contact[]>> {
      const { data, error, success } = await getContactsByCallSessionIds(userId, callSessionIds, filters);
      return { data, error, success };
    },

    async updateContactAsync(contact: Contact) {
      const { data, error, success } = await updateContactAsync(contact);
      return { data, error, success };
    },
  };
}

import axios from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import routes from '../../../../../src/constants/routes';
import { type Contact } from './entities/contact.entity';

const updateContactAsync = async (contact: Contact): Promise<ApiResponse<Contact>> => {
  try {
    const response = await axios.put<Contact>(`${routes.put.contacts.update}/${contact.id}`, contact);
    return { data: response.data };
  } catch {
    return { error: { message: 'Error update contact' } };
  }
};

export default updateContactAsync;

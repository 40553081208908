import routes from "../../constants/routes";
import axios from "axios";
import { captureException } from "@sentry/react";

export type UserType = {
  type: string;
};

export type UserInfo = {
  userId: string;
  userName: string;
  type: UserType[];
  sdrId: string;
  organizationId: string;
  isSuperAdmin: boolean;
  email: string;
};

type ResponseProps = {
  user?: UserInfo;
  error?: { message: string };
};

export const getUserInfoAsync = async (id: string): Promise<ResponseProps> => {
  try {
    const userReq = await axios.get<UserInfo>(
      `${routes.get.users.getUserInfoNest}/${id}`
    );

    return { user: userReq.data };
  } catch (error) {
    captureException(error);

    return { error: { message: "Error getting user information" } };
  }
};

export default getUserInfoAsync;

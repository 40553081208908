import getOutreachMappingsByOrgId from '../../../DataSource/sync/outreach-mappings/getOutreachMappingsByOrgId';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import { type PostOutreachMappingDto } from '../../../DataSource/sync/outreach-mappings/dto/post-outreach-mapping.dto';
import postOutreachMappings from '../../../DataSource/sync/outreach-mappings/postOutreachMappings';
import { transformOutreachMappings } from './utils/data-transforms.util';
import getOutreachCustomDispositions from '../../../DataSource/sync/outreach-mappings/getCustomDispositions';
import {
  type CreateCustomDispositionDto,
  type OutreachCustomDispositionDto,
} from '../../../DataSource/sync/outreach-mappings/dto/create-custom-disposition.dto';
import createCustomDisposition from '../../../DataSource/sync/outreach-mappings/createCustomDisposition';
import { type OutreachCallDispositions } from '../../../DataSource/sync/outreach-mappings/dto/getOutreachCustomDispositions.dto';
import { type OutreachMappingDto } from '../../../DataSource/sync/outreach-mappings/dto/get-outreach-mappings.dto';
import { type DispositionAbbreviation } from '../../../../Presentation/Views/types/disposition-mapping.type';
import {
  type UpdateCustomDispositionDto,
  type UpdateCustomDispositionResponseDto,
} from '../../../DataSource/sync/outreach-mappings/dto/update-custom-disposition.dto';
import updateCustomDisposition from '../../../DataSource/sync/outreach-mappings/updateCustomDisposition';
import deleteCustomDisposition from '../../../DataSource/sync/outreach-mappings/deleteCustomDisposition';

export interface OutreachMapping {
  updateCustomDisposition: (
    organizationId: string,
    nonce: string,
    updateCustomDispositionDto: UpdateCustomDispositionDto,
  ) => Promise<ApiResponse<UpdateCustomDispositionResponseDto>>;
  createDisposition: (
    organizationId: string,
    nonce: string,
    createDispositionDto: CreateCustomDispositionDto,
  ) => Promise<ApiResponse<OutreachCustomDispositionDto>>;
  deleteCustomDisposition: (
    organizationId: string,
    nonce: string,
    callDispositionId: string,
  ) => Promise<ApiResponse<unknown>>;
  postMappings: (id: string, data: PostOutreachMappingDto) => Promise<ApiResponse<GetOutreachMappingsRepository>>;
  getOutreachMappings: (organizationId: string) => Promise<ApiResponse<GetOutreachMappingsRepository>>;
  getCustomDispositions: (organizationId: string, nonce: string) => Promise<ApiResponse<OutreachCallDispositions[]>>;
}

export interface GetOutreachMappingsRepository extends Omit<OutreachMappingDto, 'mapping'> {
  mapping: DispositionRecord;
}

export type DispositionRecord = Record<DispositionAbbreviation, string>;

export function OutreachMappingsRepository(): OutreachMapping {
  return {
    async updateCustomDisposition(
      organizationId: string,
      nonce: string,
      updateCustomDispositionDto: UpdateCustomDispositionDto,
    ) {
      const { data, error, success } = await updateCustomDisposition(organizationId, nonce, updateCustomDispositionDto);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async createDisposition(
      organizationId: string,
      nonce: string,
      createCustomDispositionDto: CreateCustomDispositionDto,
    ) {
      const { data, error, success } = await createCustomDisposition(organizationId, nonce, createCustomDispositionDto);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data: data.data, success: true };
    },

    async deleteCustomDisposition(organizationId: string, nonce: string, callDispositionId: string) {
      const { error, success } = await deleteCustomDisposition(organizationId, nonce, callDispositionId);

      if (error !== null) {
        return { error, success };
      }

      return { success: true };
    },

    async postMappings(organizationId: string, updateOutreachMappingsDto: PostOutreachMappingDto) {
      const { data, error, success } = await postOutreachMappings(organizationId, updateOutreachMappingsDto);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data: transformOutreachMappings(data), success: true };
    },

    async getOutreachMappings(id: string): Promise<ApiResponse<GetOutreachMappingsRepository>> {
      const { data, error, success } = await getOutreachMappingsByOrgId(id);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data: transformOutreachMappings(data), success: true };
    },

    async getCustomDispositions(organizationId: string, nonce: string) {
      const { data, error, success } = await getOutreachCustomDispositions(organizationId, nonce);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}

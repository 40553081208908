import routes from "../../constants/routes";
import axios from "axios";
import { ApiResponse } from "../../types/ApiResponse";
import { MessageVerificationResponse } from "../../types";

const sendVerificationCode = async (
  userId: string
): Promise<ApiResponse<MessageVerificationResponse>> => {
  try {
    const data = {
      userId,
    };

    const response = await axios.post<MessageVerificationResponse>(
      routes.post.users.password.sendVerificationCode,
      data
    );
    return {
      success: true,
      data: {
        status: response.status,
        TTL: response.data.TTL,
        IsFirstTime: response.data.IsFirstTime,
      },
    };
  } catch (e) {
    return {
      success: false,
      error: { message: "Error sending verification code, try again later." },
    };
  }
};

export default sendVerificationCode;

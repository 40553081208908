import React, { useEffect, useRef, useState } from "react";
import { BaseField, BaseInput } from "../../core/ui/base/FormElements";
import styled, { css } from "styled-components";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import getSearchByFields from "../../api/utils/getSearchByFields";
import useDebounce from "../../core/hooks/useDebounce.hook";
import { ApiResponse } from "../../types/ApiResponse";
import { motion } from "framer-motion";

const ClearInputContainer = styled.div<{ hideWhen: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-right: 1rem;
  cursor: pointer;
  color: #ccc;
  font-size: 1.2rem;
  font-weight: 600;

  ${({ hideWhen }) =>
    hideWhen &&
    css`
      display: none;
    `};
`;

const SearchInput = styled(BaseInput)`
  padding-right: 2.5rem !important;
`;

interface SearchBarProps {
  onSearchResult: (filteredOptions: ApiResponse<unknown>) => void;
  placeholder?: string;
  urlEndpoint: string;
  fields: string[];
}

function SearchBar({
  fields,
  urlEndpoint,
  onSearchResult,
  placeholder = "Search...",
}: SearchBarProps): React.ReactElement {
  const searchInput = useRef<HTMLInputElement>(null);
  const [isInputEmpty, setIsInputEmpty] = useState<boolean>(true);

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.focus(); // Ensure input is focused on mount
    }
  }, []);

  const runSearchBy = async (searchTerm: string): Promise<void> => {
    const searchResult = await getSearchByFields({
      urlEndpoint,
      fields,
      searchTerm,
    });

    onSearchResult(searchResult);
  };

  const onClearInput = (): void => {
    if (!searchInput.current) return;

    // Clear input
    searchInput.current.blur();
    searchInput.current.value = "";
    searchInput.current.focus();

    // Reset state's
    setIsInputEmpty(true);

    // Run default search
    runSearchBy("").finally();
  };

  const onChangeInput = useDebounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const { value } = e.target;
      setIsInputEmpty(value === "");

      if (value === "") {
        onClearInput();
        return;
      }

      runSearchBy(value).finally();
    },
    600
  );

  const onKeyDownInput = useDebounce(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && searchInput.current) {
        runSearchBy(searchInput.current.value).finally();
      }
    },
    200
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ y: [-20, 10, 0], opacity: 1 }}
      transition={{ ease: "easeIn", duration: 0.5 }}
    >
      <BaseField>
        <SearchInput
          ref={searchInput}
          type="text"
          placeholder={placeholder ?? "Search..."}
          name="search"
          onChange={onChangeInput}
          onKeyDown={onKeyDownInput}
        />
        <ClearInputContainer hideWhen={isInputEmpty} onClick={onClearInput}>
          <FA icon={faXmark} size="xl" />
        </ClearInputContainer>
      </BaseField>
    </motion.div>
  );
}

export default SearchBar;

import axios from 'axios';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type UpdateFeatureFlagsDto } from './dto/update-feature-flags.dto';
import { type FeatureFlag } from './entities/feature-flags.entity';
import routes from '@/src/constants/routes';

const updateFeatureFlags = async (
  organizationId: string,
  updateFeatureFlagsDto: UpdateFeatureFlagsDto,
): Promise<ApiResponse<FeatureFlag>> => {
  try {
    const url = `${routes.put.featureFlag.update}/${organizationId}`;
    const featureFlags = await axios.put<FeatureFlag>(url, updateFeatureFlagsDto);

    return {
      success: true,
      data: featureFlags.data,
    };
  } catch (e: unknown) {
    return {
      success: false,
      error: {
        message:
          'An error occurred while updating feature flags. If the issue persists, please contact customer support.',
      },
    };
  }
};

export default updateFeatureFlags;

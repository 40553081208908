import { LoadingState } from '../../../../../../../src/types/LoadingStates.type';
import {
  type Disposition,
  DispositionAbbreviation,
  DispositionDefaultAction,
  type DispositionMap,
  Outcome,
} from '../../../../types/disposition-mapping.type';
import { useContext, useEffect, useState } from 'react';
import { UserProviderContext } from '../../../../../../../src/components/app/UserProvider';
import { GetOutreachMappingsUseCase } from '../../../../../../Domain/UseCase/outreach-mappings/outreachMappingsUseCase';
import { type PostOutreachMappingDto } from '../../../../../../Data/DataSource/sync/outreach-mappings/dto/post-outreach-mapping.dto';
import { OutreachUseCase } from '../../../../../../Domain/UseCase/outreach/outreachUsecase';
import { type OutreachAccount } from '../../../../../../Data/DataSource/sync/outreach/dto/get-outreach-accounts.dto';
import { type OutreachCallDispositions } from '../../../../../../Data/DataSource/sync/outreach-mappings/dto/getOutreachCustomDispositions.dto';
import { type CreateCustomDispositionDto } from '../../../../../../Data/DataSource/sync/outreach-mappings/dto/create-custom-disposition.dto';
import { showUIToast, UI_TOAST_TYPES } from '../../../../../../../src/core/ui/UIToast';
import {
  type DispositionRecord,
  type GetOutreachMappingsRepository,
} from '../../../../../../Data/Repository/sync/outreach-mappings/outreachMappingsRepository';
import { type UpdateCustomDispositionDto } from '../../../../../../Data/DataSource/sync/outreach-mappings/dto/update-custom-disposition.dto';

export enum Modals {
  ADD_DISPOSITION = 'addDisposition',
  CONFIRM_DELETE = 'confirmDelete',
  EDIT_DISPOSITION = 'editDisposition',
}

export interface OutreachMappingsLoadingState {
  getMappings: LoadingState;
  getCustomDispositions: LoadingState;
  save: LoadingState;
  addCustomDisposition: LoadingState;
  removeCustomDisposition: LoadingState;
  getAccounts: LoadingState;
}

interface Modal {
  name: Modals;
  isOpen: boolean;
  data: Disposition | null;
}

export interface OutreachMappingsViewModel {
  fetchMappings: () => Promise<void>;
  fetchCustomDispositions: () => Promise<OutreachCallDispositions[] | undefined>;
  updateDisposition: (name: string, outcome: string) => Promise<void>;
  addCustomDisposition: (name: string, outcome: string) => Promise<void>;
  removeCustomDisposition: (dispositionToDelete: Disposition) => Promise<void>;
  saveMappings: () => Promise<void>;
  selectDisposition: (selected: Disposition, customDisposition: OutreachCallDispositions) => void;
  mappings: Disposition[];
  customDispositions: OutreachCallDispositions[];
  loadingState: OutreachMappingsLoadingState;
  viewState: ViewState;
  toggleModal: (modal: Modal) => void;
  modalState: Record<Modals, Modal>;
}

interface ViewState {
  isOutreachAccount: boolean;
  hasMappingsChanged: boolean;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  editDisposition?: Disposition;
  isTableLoading: boolean;
}

const { IDLE, LOADING, ERROR, SUCCESS } = LoadingState;

export default function useOutreachMappingsViewModel(): OutreachMappingsViewModel {
  const { getOutreachAccounts } = OutreachUseCase();

  const {
    getMappings,
    patchMappings,
    getDispositions,
    getTransformedMappings,
    createDisposition,
    updateCustomDisposition,
    deleteCustomDisposition,
    isCustomDisposition,
  } = GetOutreachMappingsUseCase();

  const {
    currentUser: { isAdmin, isSuperAdmin, ...currentUser },
  } = useContext(UserProviderContext);

  const [loadingState, setLoadingState] = useState<OutreachMappingsLoadingState>({
    getMappings: IDLE,
    getCustomDispositions: IDLE,
    save: IDLE,
    addCustomDisposition: IDLE,
    removeCustomDisposition: IDLE,
    getAccounts: IDLE,
  });

  const [modalState, setModalState] = useState<Record<Modals, Modal>>({
    [Modals.ADD_DISPOSITION]: {
      name: Modals.ADD_DISPOSITION,
      isOpen: false,
      data: null,
    },
    [Modals.CONFIRM_DELETE]: {
      name: Modals.CONFIRM_DELETE,
      isOpen: false,
      data: null,
    },
    [Modals.EDIT_DISPOSITION]: {
      name: Modals.EDIT_DISPOSITION,
      isOpen: false,
      data: null,
    },
  });

  const [viewState, setViewState] = useState<ViewState>({
    isOutreachAccount: false,
    hasMappingsChanged: false,
    isSuperAdmin,
    isAdmin,
    editDisposition: {
      id: '',
      type: '',
      dispositionId: '',
      abbreviation: DispositionAbbreviation.RTT,
      defaultAction: DispositionDefaultAction.default_action,
      defaultName: '',
      customName: '',
      isCustom: false,
      outcome: Outcome.NO_ANSWER,
    },
    isTableLoading: false,
  });

  const [mappings, setMappings] = useState<Disposition[]>([]);

  const [, setDispositionMap] = useState<DispositionRecord>();

  const [customDispositions, setCustomDispositions] = useState<OutreachCallDispositions[]>([]);

  const [outreachAccounts, setOutreachAccounts] = useState<OutreachAccount[]>([]);

  async function fetchMappings(): Promise<void> {
    setLoadingState({ ...loadingState, getMappings: LOADING });
    const organizationId = currentUser.organizationId;

    const { data, success } = await getMappings(organizationId);

    const callDispositions = customDispositions.length > 0 ? customDispositions : await fetchCustomDispositions();

    if (callDispositions == null || data == null || success === false) {
      setLoadingState({ ...loadingState, getMappings: ERROR });
      return;
    }

    const { mapping, id } = data;

    const transformedMapping: Disposition[] = getTransformedMappings(mapping, callDispositions, id);

    setDispositionMap(mapping);

    setMappings(transformedMapping);

    setLoadingState({ ...loadingState, getMappings: SUCCESS });
  }

  async function fetchAccounts(): Promise<OutreachAccount[]> {
    setLoadingState({ ...loadingState, getAccounts: LOADING });
    const organizationId = currentUser?.organizationId;
    const userId = currentUser?.userId;
    const getAccountsResponse = await getOutreachAccounts(userId, organizationId).finally(() => {
      setLoadingState({
        ...loadingState,
        getAccounts: SUCCESS,
      });
    });

    const getAccounts = getAccountsResponse.data;

    if (getAccounts === undefined) {
      setLoadingState({ ...loadingState, getAccounts: ERROR });
      showUIToast({
        type: UI_TOAST_TYPES.WARNING,
        text: 'You have to connect at least one Outreach account to use this feature.',
      });
      setOutreachAccounts([]);
      return [];
    }

    setViewState({
      ...viewState,
      isOutreachAccount: (getAccounts.length ?? -1) > 0,
    });
    setOutreachAccounts(getAccounts);
    return getAccounts;
  }

  async function fetchCustomDispositions(): Promise<OutreachCallDispositions[] | undefined> {
    setLoadingState({
      ...loadingState,
      getCustomDispositions: LOADING,
    });
    const organizationId = currentUser?.organizationId;
    const nonce = outreachAccounts[0]?.nonce ?? (await fetchAccounts().then((accounts) => accounts[0]?.nonce));

    if (nonce === undefined) return [];

    const { data: outreachCustomDisposition, success } = await getDispositions(organizationId, nonce);

    if (outreachCustomDisposition === undefined || success === false) {
      setLoadingState({
        ...loadingState,
        getCustomDispositions: ERROR,
      });

      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Error fetching custom dispositions.',
      });

      setCustomDispositions([]);

      return;
    }

    setCustomDispositions(outreachCustomDisposition);

    setLoadingState({
      ...loadingState,
      getCustomDispositions: SUCCESS,
    });

    return outreachCustomDisposition;
  }

  async function updateDisposition(name: string, outcome: string): Promise<void> {
    setLoadingState({ ...loadingState, addCustomDisposition: LOADING });

    const organizationId = currentUser.organizationId;
    const nonce = outreachAccounts[0]?.nonce ?? (await fetchAccounts().then((accounts) => accounts[0]?.nonce));
    const id = modalState[Modals.EDIT_DISPOSITION].data?.id;

    if (nonce === undefined || id === undefined || nonce.length === 0 || id.length === 0) {
      setLoadingState({ ...loadingState, addCustomDisposition: ERROR });
      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Error updating custom disposition.',
      });
      return;
    }

    const updateBody: UpdateCustomDispositionDto = {
      data: {
        id,
        type: 'callDisposition',
        attributes: {
          name,
          outcome,
        },
      },
    };

    setCustomDispositions((prev) => prev.map((d) => (d.id === id ? updateBody.data : d)));

    const { success, error, data: updated } = await updateCustomDisposition(organizationId, nonce, updateBody);

    if (success === false || error !== undefined || updated === undefined) {
      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Error updating custom disposition.',
      });
      setLoadingState({ ...loadingState, addCustomDisposition: ERROR });
      return;
    }

    const updateMap = await updateMappings();

    if (updateMap === undefined) {
      setViewState({
        ...viewState,
        hasMappingsChanged: true,
      });
      return;
    }

    showUIToast({
      type: UI_TOAST_TYPES.SUCCESS,
      text: 'Custom disposition updated.',
    });

    setLoadingState({ ...loadingState, addCustomDisposition: SUCCESS });

    toggleModal({
      name: Modals.EDIT_DISPOSITION,
      data: null,
      isOpen: false,
    });
  }

  async function addCustomDisposition(name: string, outcome: string): Promise<Awaited<void>> {
    setLoadingState({ ...loadingState, addCustomDisposition: LOADING });

    const repeatedCustomDisposition = customDispositions.filter((item) => item.attributes.name === name);

    if (repeatedCustomDisposition.length > 0) {
      setLoadingState({ ...loadingState, addCustomDisposition: ERROR });
      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'There is another custom disposition with the same name. Please use another.',
      });
      return;
    }

    const organizationId = currentUser.organizationId;
    const nonce = outreachAccounts[0].nonce ?? (await fetchAccounts().then((accounts) => accounts[0].nonce));

    if (nonce === '') {
      setLoadingState({ ...loadingState, addCustomDisposition: ERROR });
      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Error adding custom disposition: No nonce found.',
      });
      return;
    }

    const createBody: CreateCustomDispositionDto = {
      type: 'callDisposition',
      attributes: {
        name,
        outcome,
      },
    };

    const {
      success,
      error,
      data: newDisposition,
    } = await createDisposition(organizationId, nonce, createBody).finally(() => {
      toggleModal({ name: Modals.ADD_DISPOSITION, data: null, isOpen: false });
    });

    if (success === false || error != null || newDisposition == null) {
      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Error adding custom disposition.',
      });
      setLoadingState({ ...loadingState, addCustomDisposition: ERROR });
      return;
    }

    const newCustom: OutreachCallDispositions = {
      id: newDisposition.id,
      type: 'callDisposition',
      attributes: {
        name,
        outcome,
      },
    };

    setLoadingState({ ...loadingState, addCustomDisposition: SUCCESS });
    setCustomDispositions((prev) => [...prev.filter((d) => newCustom.id !== d.id), { ...newCustom }]);
    showUIToast({
      type: UI_TOAST_TYPES.SUCCESS,
      text: 'Custom disposition added.',
    });
  }

  async function removeCustomDisposition(dispositionToDelete: Disposition): Promise<Awaited<void>> {
    if (loadingState.removeCustomDisposition === LOADING) return;

    setLoadingState({
      ...loadingState,
      removeCustomDisposition: LOADING,
    });

    const organizationId = currentUser.organizationId;
    const { nonce } = outreachAccounts[0];

    const dispositionId = dispositionToDelete.id;

    const { success } = await deleteCustomDisposition(organizationId, nonce, dispositionId).finally(() => {
      toggleModal({
        name: Modals.CONFIRM_DELETE,
        data: null,
        isOpen: false,
      });
    });

    if (success === false) {
      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Error removing custom disposition.',
      });
      setLoadingState({
        ...loadingState,
        removeCustomDisposition: ERROR,
      });
      return;
    }

    showUIToast({
      type: UI_TOAST_TYPES.SUCCESS,
      text: 'Custom disposition removed.',
    });

    setLoadingState({
      ...loadingState,
      removeCustomDisposition: SUCCESS,
    });
  }

  async function updateMappings(): Promise<GetOutreachMappingsRepository | undefined> {
    const cleanMappings: DispositionMap = new Map();
    const dispositionId = mappings[0].dispositionId;

    for (const disposition of mappings) {
      cleanMappings.set(DispositionAbbreviation[disposition.abbreviation], disposition.customName);
    }

    const patchMappingsDto: PostOutreachMappingDto = {
      mapping: JSON.stringify(Object.fromEntries(cleanMappings)),
    };

    const { success, error, data } = await patchMappings(dispositionId, patchMappingsDto);

    if (success === false || error !== undefined || data?.mapping === undefined) {
      setLoadingState({ ...loadingState, save: ERROR });

      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Error saving Outreach Mappings.',
      });
      return;
    }

    setDispositionMap(data.mapping);
    setViewState({
      ...viewState,
      hasMappingsChanged: false,
    });

    return data;
  }

  async function saveMappings(): Promise<Awaited<void>> {
    setLoadingState({ ...loadingState, save: LOADING });

    const updateMap = await updateMappings();

    if (updateMap === undefined) {
      setViewState({
        ...viewState,
        hasMappingsChanged: true,
      });
      return;
    }

    showUIToast({
      type: UI_TOAST_TYPES.SUCCESS,
      text: 'Outreach Mappings saved.',
    });

    setLoadingState({ ...loadingState, save: SUCCESS });
  }

  function selectDisposition(selected: Disposition, customDisposition: OutreachCallDispositions): void {
    const {
      id,
      attributes: { name, outcome },
    } = customDisposition;
    const customSelected = customDispositions.find((d) => d.id === id);

    if (customSelected === undefined) {
      console.log('No custom disposition found');
      return;
    }

    setMappings((prev) => [
      ...prev.map((d) => {
        if (selected.abbreviation !== d.abbreviation) return d;
        setViewState({
          ...viewState,
          hasMappingsChanged: true,
        });
        return {
          ...d,
          id,
          customName: name,
          isCustom: isCustomDisposition(name),
          outcome: outcome as Outcome,
        };
      }),
    ]);
  }

  function toggleModal(modal: Modal): void {
    const { name, data, isOpen } = modal;

    setModalState((prev) => {
      return {
        ...prev,
        [name]: {
          name,
          data,
          isOpen,
        },
      };
    });
  }

  useEffect(() => {
    if (loadingState.getAccounts !== LOADING) {
      void fetchAccounts().finally();
    }
    if (loadingState.getCustomDispositions !== LOADING) {
      void fetchCustomDispositions().finally();
    }
    if (loadingState.getMappings !== LOADING) {
      void fetchMappings().finally();
    }
  }, []);

  useEffect(() => {
    const isTableLoading =
      loadingState.getAccounts === LOADING ||
      loadingState.getCustomDispositions === LOADING ||
      loadingState.getMappings === LOADING;

    setViewState((prev) => ({
      ...prev,
      isTableLoading,
    }));
  }, [loadingState]);

  return {
    fetchMappings,
    fetchCustomDispositions,
    updateDisposition,
    addCustomDisposition,
    removeCustomDisposition,
    saveMappings,
    selectDisposition,
    toggleModal,
    mappings,
    loadingState,
    customDispositions,
    viewState,
    modalState,
  };
}

import React, { useEffect, useState } from "react";
import GenericModal from "../../shared/GenericModal";
import { Box, Flex, Text } from "../../../core/ui";
import { NumberPool } from "../../../types";
import { showUIToast } from "../../../core/ui/UIToast";
import { Field, Form } from "react-final-form";
import {
  CheckboxField,
  StyledButton,
  StyledForm,
} from "../../shared/FormAndFields";
import { NumberType } from "../../shared/enums/numbersTypes";
import enableNumberPool from "../../../api/Numbers/enableNumberPool";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedNumber: NumberPool;
  onSuccess: () => void;
  numberPoolId: string;
};

const EnableNumberOrgModal = ({
  isOpen,
  onClose,
  selectedNumber,
  onSuccess,
  numberPoolId,
}: Props) => {
  const handleDeleteNumber = async (values) => {
    const { number, error } = await enableNumberPool(
      selectedNumber.id,
      values.active
    );
    if (number) {
      showUIToast({ type: "info", text: "Number updated." });
      onSuccess();
      return;
    }
    error && showUIToast({ type: "error", text: error.message });
  };

  const [active, setActive] = useState({
    active: selectedNumber ? selectedNumber.active : false,
  });

  useEffect(() => {
    if (
      selectedNumber &&
      ((selectedNumber.active &&
        selectedNumber.type === NumberType.ORGANIZATION) ||
        (numberPoolId && selectedNumber.id === numberPoolId))
    ) {
      setActive({ active: true });
    }
  }, [isOpen, selectedNumber]);

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} padding="50px 20px 20px">
      <Box>
        <Text
          fontSize={22}
          fontWeight="600"
          textAlign="center"
          color="black"
          lineHeight="28px"
          mb={32}
        >
          Are you sure you want to enable/disable the
          <br />
          {`${selectedNumber?.number.substring(0, 4)} ${
            selectedNumber?.location
          } number?`}
          <br />
        </Text>
        <Flex gap="16px" justifyContent="center" mb={24}>
          <Form
            onSubmit={handleDeleteNumber}
            initialValues={active}
            render={({ handleSubmit, form, submitting, pristine, valid }) => (
              <StyledForm onSubmit={handleSubmit}>
                <Flex mb="20px" gap="24px">
                  <Flex
                    alignItems="center"
                    justifyContent="space-around"
                    height="36px"
                    mt="8px"
                  >
                    <Field
                      label={"Active"}
                      name="active"
                      component={CheckboxField}
                      type="checkbox"
                    />
                  </Flex>
                </Flex>
                <StyledButton
                  bg="lightBlue"
                  px={25}
                  py={12}
                  type="submit"
                  mt={12}
                  disabled={submitting || pristine || !valid}
                >
                  <Text fontSize={16} fontWeight="600" color="white">
                    Submit
                  </Text>
                </StyledButton>
              </StyledForm>
            )}
          ></Form>
        </Flex>
      </Box>
    </GenericModal>
  );
};

export default EnableNumberOrgModal;

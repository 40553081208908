import styled, { css } from "styled-components";

type UIRowProps = {
  alignItems?: "stretch" | "center" | "flex-start" | "flex-end";
  justifyContent?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  gap?: number;
  minHeight?: string;
  maxWidth?: string;
};

export const UIRow = styled.div<UIRowProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
  ${({ gap }) => gap && `gap: ${gap}rem;`}
    ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
    ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  width: 100%;
`;

interface UIColProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  alignItems?: "stretch" | "center" | "flex-start" | "flex-end";
  justifyContent?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  gap?: number;
  height?: string;
  minWidth?: string;
  maxWidth?: string;
}

export const UICol = styled.div<UIColProps>`
  display: flex;
  flex-wrap: wrap;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ gap }) => (gap ? `gap: ${gap}rem;` : `padding: 0.5rem;`)}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}

  ${({ xs }) =>
    xs
      ? css`
          flex: 0 0 ${(xs / 12) * 100}%;
          max-width: ${(xs / 12) * 100}%;
        `
      : css`
          flex: 1;
        `}
  ${({ sm }) =>
    sm &&
    css`
      @media screen and (min-width: 576px) {
        flex: 0 0 ${(sm / 12) * 100}%;
        max-width: ${(sm / 12) * 100}%;
      }
    `}
  ${({ md }) =>
    md &&
    css`
      @media screen and (min-width: 768px) {
        flex: 0 0 ${(md / 12) * 100}%;
        max-width: ${(md / 12) * 100}%;
      }
    `}
  ${({ lg }) =>
    lg &&
    css`
      @media screen and (min-width: 992px) {
        flex: 0 0 ${(lg / 12) * 100}%;
        max-width: ${(lg / 12) * 100}%;
      }
    `}
  ${({ xl }) =>
    xl &&
    css`
      @media screen and (min-width: 1200px) {
        flex: 0 0 ${(xl / 12) * 100}%;
        max-width: ${(xl / 12) * 100}%;
      }
    `}
`;

export const getClassNames = (className?: string): string => {
  switch (className) {
    case 'danger':
      return 'text-white inline-flex gap-2 items-center bg-red-600 hover:bg-red-800 focus:ring-2 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center';
    case 'danger-outline':
      return 'text-red-500 inline-flex gap-2 items-center hover:text-white hover:bg-red-500 ring-1 ring-red-500 focus:outline-none focus:ring-red-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center';
    case 'close-modal':
      return 'text-gray-400 items-center hover:text-gray-500 hover:bg-gray-100 hover:ring-1 ring-gray-400 focus:outline-none font-medium rounded-lg text-sm px-2 py-1 text-center';
    case 'only-icon':
      return 'text-white inline-flex gap-2 items-center bg-app-blue-light hover:bg-opacity-90 focus:ring-2 focus:outline-none focus:ring-app-blue-lighter font-medium rounded-lg text-sm px-5 py-2.5 text-center';
    case 'reset':
      return 'text-gray-400 inline-flex gap-2 items-center hover:text-white hover:bg-gray-400 ring-1 ring-gray-400 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center';
    case 'cancel':
    case 'outline':
      return 'text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10';
    case 'submit':
    case 'button':
    default:
      return 'text-white inline-flex gap-2 items-center bg-app-blue-light hover:bg-opacity-90 focus:ring-2 focus:outline-none focus:ring-app-blue-lighter font-medium rounded-lg text-sm px-5 py-2.5 text-center';
  }
};

import { type ActiveCallSession } from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import { type ApiResponse } from '@/src/types/ApiResponse';
import axios from 'axios';
import routes from '@/src/constants/routes';

const getActiveCallSessions = async (): Promise<ApiResponse<ActiveCallSession[]>> => {
  try {
    // eslint-disable-next-line
    const callSession = await axios.get<ActiveCallSession[]>(routes.get.callSession.getActiveCallSessions);
    return { data: callSession.data };
  } catch {
    return { error: { message: 'Error getting call sessions' } };
  }
};

export default getActiveCallSessions;

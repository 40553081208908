import React, { type BaseSyntheticEvent, type FC, useState } from 'react';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@components/ui/table';
import SettingsTitleDivider from '@views/Settings/Components/SettingsTitleDivider.component';
import { type ActiveCallSession } from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import ActiveSessionsViewmodel from '@views/Settings/ActiveSessions/ActiveSessions.viewmodel';
import { SdrStatus, SessionStatus } from '@domain/enums/CallSessions/callSessionsStatus';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@components/ui/select';
import { Button } from '@components/ui/button';
import { Form, FormField, FormItem, FormMessage } from '@components/ui/form';
import { useForm } from 'react-hook-form';
import { type z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { MinusIcon, PlusIcon } from '@radix-ui/react-icons';
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@components/ui/collapsible';

const SessionsView: FC = () => {
  const { sessionsData, FormSchema, toggleResumePauseSession } = ActiveSessionsViewmodel();
  const [updatedRowIndex, setUpdatedRowIndex] = useState('');
  const [isOpened, openAgentsList] = useState(false);

  function detectUpdatedRowByIndex(e: BaseSyntheticEvent): void {
    // eslint-disable-next-line
    const index: string = e.target.id;
    if (index !== null) {
      setUpdatedRowIndex(index);
    }
  }

  function toggleOpenAgentList(): void {
    if (!isOpened) {
      openAgentsList(true);
    }
    if (isOpened) {
      openAgentsList(false);
    }
  }

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  return (
    <>
      <SettingsTitleDivider title="Active Call Sessions" description="Watch and manage the active call sessions" />
      <Form {...form}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            void toggleResumePauseSession(form.getValues(), updatedRowIndex).then(() => {
              setUpdatedRowIndex('');
            });
          }}
        >
          <Table>
            <TableHeader>
              <TableCell>Session</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>SDR</TableCell>
              <TableCell>Agents</TableCell>
              <TableCell>Session Status</TableCell>
              <TableCell>SDR Status</TableCell>
            </TableHeader>
            <TableBody>
              {sessionsData?.map((callSession: ActiveCallSession, index) => {
                const { callSessionId, organization, startAt, sdrName, sdrId, agents, status, sdrStatus } = callSession;
                return (
                  <TableRow key={callSessionId}>
                    <TableCell id={`callSessionId-${index}`}>{callSessionId}</TableCell>
                    <TableCell>{organization}</TableCell>
                    <TableCell>{startAt}</TableCell>
                    <TableCell>
                      {sdrName}
                      <br />
                      <h3 className="text-app-gray-300">id: {sdrId}</h3>
                    </TableCell>
                    {agents !== null && agents.length <= 4 ? (
                      <TableCell>
                        {agents.map((el, index) => {
                          return (
                            <div key={index}>
                              <h3>{el.name}</h3>
                            </div>
                          );
                        })}
                      </TableCell>
                    ) : agents !== null && agents.length > 4 ? (
                      <TableCell>
                        {agents.slice(0, 4).map((el, idx) => {
                          return (
                            <div key={idx}>
                              <h3>{el.name}</h3>
                            </div>
                          );
                        })}

                        <Collapsible>
                          <div className="flex items-center justify-between">
                            <div>
                              <h3>{agents[4].name}</h3>
                            </div>
                            <CollapsibleTrigger>
                              <Button className="h-5 w-6 p-0" type="button" onClick={toggleOpenAgentList}>
                                {isOpened ? <MinusIcon /> : <PlusIcon />}
                              </Button>
                            </CollapsibleTrigger>
                          </div>
                          <CollapsibleContent>
                            {agents.slice(5).map((el, idx) => {
                              return (
                                <div key={idx}>
                                  <h3>{el.name}</h3>
                                </div>
                              );
                            })}
                          </CollapsibleContent>
                        </Collapsible>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <h3>
                          <b>
                            No agents assigned <br />
                            for this Session
                          </b>
                        </h3>
                      </TableCell>
                    )}
                    <TableCell id={`sessionStatusSwitch-${index}`}>
                      <FormField
                        control={form.control}
                        name="sessionStatus"
                        render={({ field }) => (
                          <FormItem>
                            <Select onValueChange={field.onChange} defaultValue={status}>
                              <SelectTrigger>
                                <SelectValue placeholder={status} />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectLabel>Session Status</SelectLabel>
                                  <SelectItem value={SessionStatus.STARTED}>{SessionStatus.STARTED}</SelectItem>
                                  <SelectItem value={SessionStatus.PAUSED}>{SessionStatus.PAUSED}</SelectItem>
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>

                    <TableCell>
                      <FormField
                        control={form.control}
                        name="sdrStatus"
                        render={({ field }) => (
                          <FormItem>
                            <Select onValueChange={field.onChange} defaultValue={sdrStatus}>
                              <SelectTrigger>
                                <SelectValue placeholder={sdrStatus} />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectItem value={SdrStatus.FREE}>{SdrStatus.FREE}</SelectItem>
                                  <SelectItem value={SdrStatus.BUSY}>{SdrStatus.BUSY}</SelectItem>
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        id={`${index}`}
                        type="submit"
                        onClick={(e) => {
                          detectUpdatedRowByIndex(e);
                        }}
                      >
                        Update
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </form>
      </Form>
    </>
  );
};

export default SessionsView;

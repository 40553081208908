import getHubspotAccounts from '../../../DataSource/sync/hubspot/getHubspotAccounts';
import { type Account } from '../../../DataSource/sync/hubspot/entities/account.entity';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';

interface HubspotRepo {
  getHubspotAccounts: (userId: string, organizationId: string, isCustom?: boolean) => Promise<ApiResponse<Account[]>>;
}

export function HubspotRepository(): HubspotRepo {
  return {
    async getHubspotAccounts(userId: string, organizationId: string, isCustom?: boolean) {
      const { data, error, success } = await getHubspotAccounts(userId, organizationId, isCustom);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}

import React, { type FC } from 'react';
import UploadProfilePictureViewmodel from '../UploadProfilePicture.viewmodel';
import { Avatar, AvatarFallback, AvatarImage } from '@components/ui/avatar';

interface AvatarProps {
  twClass: string;
  src: string;
}

const UserAvatarImage: FC<AvatarProps> = ({ twClass, src }) => {
  const { image } = UploadProfilePictureViewmodel();
  return (
    <Avatar className={twClass}>
      <AvatarImage src={src !== '' ? src : image} id="userAvatarImage" alt="Avatar" />
      <AvatarFallback></AvatarFallback>
    </Avatar>
  );
};
export default UserAvatarImage;

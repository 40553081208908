import styled from "styled-components";

export const Navbar = styled.nav`
  background: #fafafc;
  color: #0095d4;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 5rem;
  font-size: 1.2rem;
  z-index: 10;
`;

export const Text = styled.p`
  text-align: center;
  padding: 0.5rem 0;
  margin: auto;
`;
export const NavLeft = styled.nav`
  background: #0095d4;
  color: #fff;
  width: 20%;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WelcomeSpan = styled.span`
  font-weight: 600;
`;

export const ImgAvatar = styled.img`
  height: 70%;
  margin: auto 1rem;
`;

export const ImgLogout = styled.img`
  height: 40%;
  margin: auto 1rem auto 0;
`;

export const LogoutBtn = styled.button`
  background: #0098d8;
  color: #fff;
  border: 0;
  outline: none;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  width: 12%;
  height: 3rem;
  padding: 0 1rem;
  margin: auto 0;
`;

import React, { type FC, type ReactNode } from 'react';

export interface SettingsSectionOptionProps {
  subtitle: string;
  details: string;
  actions?: ReactNode;
}

const SettingsSectionOption: FC<SettingsSectionOptionProps> = ({ subtitle, details, actions }) => {
  const hidden = actions === undefined ? 'hidden' : '';

  return (
    <div className="pt-6 inline-flex items-center w-full">
      <div className="w-full">
        <h4 className="text-sm font-medium">{subtitle}</h4>
        <p className="text-sm text-muted-foreground text-truncate ">{details}</p>
      </div>
      <div className={`w-fit grid content-center place-content-end ${hidden}`}>
        <div className="flex w-full flex-col lg:flex-row justify-end gap-2">{actions}</div>
      </div>
    </div>
  );
};

export default SettingsSectionOption;

import React, { type FC, useEffect } from 'react';
import { type HubspotMappingsViewmodel } from '../HubspotMappings.viewmodel';
import ButtonLink from '../../../../../../Common/Buttons/ButtonLink';

const MappingActions: FC<{ viewModel: HubspotMappingsViewmodel }> = ({ viewModel }) => {
  const saveButtonRef = React.useRef<HTMLButtonElement>(null);

  const { isHubspotAccount, isSuperAdmin, isAdmin, hasMappingsChanged } = viewModel.viewState;

  useEffect(() => {
    if (saveButtonRef.current != null) {
      saveButtonRef.current.classList.toggle('disabled', !hasMappingsChanged);
    }
  }, [hasMappingsChanged]);

  const isActionsAvailable = isHubspotAccount && (isSuperAdmin || isAdmin);

  return isActionsAvailable ? (
    <div className="flex w-full justify-end gap-8">
      <ButtonLink
        onClick={() => {
          viewModel.addCallDisposition();
        }}
        disabled={!isActionsAvailable}
        text="Adding dispositions get Hubspot's help"
        isRedirection={true}
      />

      <button
        ref={saveButtonRef}
        type="button"
        onClick={() => {
          void viewModel.saveMappings();
        }}
        disabled={!hasMappingsChanged || !isActionsAvailable}
        className="disabled disabled:bg-opacity-50 disabled:cursor-not-allowed text-white inline-flex gap-2 items-center bg-app-blue-light hover:bg-opacity-90 focus:ring-2 focus:outline-none focus:ring-app-blue-lighter font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
        Save
      </button>
    </div>
  ) : (
    <></>
  );
};

export default MappingActions;

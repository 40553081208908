import moment from "moment-timezone";
import { UserProviderContext } from "../../components/app/UserProvider";
import { useContext, useEffect } from "react";
import { Timezone } from "../../types/TimezoneTypes";

const TimeZoneProvider = () => {
  const { currentUser } = useContext(UserProviderContext);

  useEffect(() => {
    if (currentUser && currentUser.timezone) {
      moment.tz.setDefault(currentUser.timezone);
    } else {
      moment.tz.setDefault(Timezone.UTC);
    }
  }, [currentUser]);

  return null;
};

export default TimeZoneProvider;

import React from "react";
import GenericModal from "../../../components/shared/GenericModal";
import { Box, Flex, Text, Button } from "../../../core/ui";
import { NumberPool } from "../../../types";
import { showUIToast } from "../../../core/ui/UIToast";
import deleteUserNumberAsync from "../../../api/CallerId/deleteUserNumberAsync";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedNumber: NumberPool;
  onSuccess: () => void;
};

const DeleteUserNumberModal = ({
  isOpen,
  onClose,
  selectedNumber,
  onSuccess,
}: Props) => {
  const handleDeleteNumber = async () => {
    const { number, error } = await deleteUserNumberAsync(selectedNumber.id);
    if (number) {
      showUIToast({ type: "info", text: "Number deleted." });
      onSuccess();
      return;
    }
    error && showUIToast({ type: "error", text: error.message });
  };
  return (
    <GenericModal isOpen={isOpen} onClose={onClose} padding="50px 20px 20px">
      <Box>
        <Text
          fontSize={22}
          fontWeight="600"
          textAlign="center"
          color="black"
          lineHeight="28px"
          mb={32}
        >
          Are you sure you want to delete
          <br />
          {`${selectedNumber?.number} number`}
        </Text>
        <Flex gap="16px" justifyContent="center" mb={24}>
          <Button bg="lightBlue" p="12px 40px" onClick={onClose}>
            <Text color="white" fontWeight="600" fontSize={15}>
              No
            </Text>
          </Button>
          <Button bg="darkGray" p="12px 40px" onClick={handleDeleteNumber}>
            <Text color="white" fontWeight="600" fontSize={15}>
              Yes
            </Text>
          </Button>
        </Flex>
      </Box>
    </GenericModal>
  );
};

export default DeleteUserNumberModal;

import type React from 'react';
import { showUIToast, UI_TOAST_TYPES } from '@/src/core/ui/UIToast';
import { type Contact } from '../../../src/types/ContactTypes';

export const clipboardCopy = (
  e: React.MouseEvent<SVGSVGElement>,
  contact: Contact,
  formattedPhone: string,
  sdrName: string | undefined,
): void => {
  e.preventDefault();

  const textToCopy =
    `${contact.accountName}\n` +
    `${contact.name} | ${contact.jobTitle}\n` +
    `[${contact.phone}](tel: ${formattedPhone}) | [${contact.email}](mailTo: ${contact.email}) Transfer to: ${
      sdrName ?? ''
    }\n`;

  const textArea = document.createElement('textarea');

  textArea.value = textToCopy;

  textArea.style.position = 'absolute';

  textArea.style.left = '-9999px';

  document.body.appendChild(textArea);

  textArea.select();

  navigator.clipboard
    .writeText(textArea.value)
    .then(() => {
      showUIToast({
        type: UI_TOAST_TYPES.SUCCESS,
        text: 'Copied to clipboard successfully',
      });
    })
    .catch((e) => {
      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Error copying to clipboard',
      });
      console.error('Could not copy text: ', e);
    });
  document.body.removeChild(textArea);
};

import routes from "../../constants/routes";
import axios from "axios";
import { CallLog, UpdateCallLogDto } from "../../types/CallLogTypes";
import { ApiResponse } from "../../types/ApiResponse";
import { logAxiosErrorWithSentry } from "../../core/utils/axios.utils";

const updateCallLog = async (
  callLogDto: UpdateCallLogDto
): Promise<ApiResponse<CallLog>> => {
  try {
    const callLog = await axios.put<CallLog>(
      routes.put.callLog.updateCallLog + `/${callLogDto.callId}`,
      callLogDto
    );
    return { success: true, data: callLog.data };
  } catch (e) {
    const message = " Something happened updating the call log";

    if (axios.isAxiosError(e)) {
      logAxiosErrorWithSentry({
        payload: e,
        description: message,
      });
    }
    return { success: false, error: { message } };
  }
};

export default updateCallLog;

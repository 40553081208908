import React, { useEffect, useState } from "react";
import styled from "styled-components";
import updateContactNotesAsync from "../../api/Contacts/updateContactNotesAsync";
import { Box, Flex, Image, Text } from "../../core/ui";
import { TransparentButton } from ".";
import { Contact } from "./Contact";
import GarbageIcon from "../../assets/icons/garbage_gray.svg";
import NoteSavedIcon from "../../assets/icons/note_saved.svg";
import { UICol, UIRow } from "../../core/ui/UIStructures";
import { UIText, UITextArea } from "../../core/ui/UIElements";
import { showUIToast } from "../../core/ui/UIToast";
import useDebounce from "../../core/hooks/useDebounce.hook";

const HeaderContainer = styled(Flex)`
  border-bottom: solid 1px ${({ theme }) => theme.colors.lighterBlue};
  width: 100%;
`;

const UITextAreaCustom = styled(UITextArea)`
  height: calc(100% - 30px);
  resize: none;
  outline: none;
  border: none;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.lightGray};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.lighterBlue};
    border-radius: 10px;
  }
`;

const NotesContainer = styled(Box)`
  width: 100%;
  font-size: 14px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};
  border: solid 1px ${({ theme }) => theme.colors.lightGray};
  padding: 10px 15px;
  border-radius: 15px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.lightGray};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.lighterBlue};
    border-radius: 10px;
  }
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.secondDarkGray};
  font-weight: 600;
`;

type NoteProps = {
  note: string;
  onSave: (newNote: string) => void;
  onDelete?: () => void;
  canEdit: boolean;
};

const Note = ({ note, onDelete, onSave, canEdit }: NoteProps): JSX.Element => {
  const [newNote, setNewNote] = useState<string>("");
  const [lastUpdateSaved, setLastUpdateSaved] = useState<boolean>(false);
  useEffect(() => setNewNote(note), [note]);

  const handleUpdate = (val) => {
    onSave(val);
    setLastUpdateSaved(true);

    setTimeout(async () => {
      setLastUpdateSaved(false);
    }, 500);
  };

  const handleChange = useDebounce((e) => {
    const val = e.target.value;
    setNewNote(val);
    handleUpdate(val);
  }, 2000);

  return (
    <>
      <UITextAreaCustom
        defaultValue={newNote}
        onChange={handleChange}
        disabled={!canEdit}
      />
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        {onDelete && canEdit && (
          <TransparentButton onClick={onDelete}>
            <Image src={GarbageIcon} width="13px" alt="Delete icon" />
          </TransparentButton>
        )}
        {lastUpdateSaved && (
          <Image src={NoteSavedIcon} width="20px" alt="Saved note icon" />
        )}
      </Flex>
    </>
  );
};

type NotesProps = {
  currentContact: Contact;
  setCurrentContact: any;
  canDelete?: boolean;
  canEdit?: boolean;
  isSDR: boolean;
  showHeader?: boolean;
};

const Notes = ({
  currentContact,
  setCurrentContact,
  canDelete = false,
  canEdit = true,
  isSDR,
  showHeader = true,
}: NotesProps): JSX.Element => {
  const handleDeleteNote = async (isSdr) => {
    const values = updateContactPayload(isSdr);

    if (values?.contactId) {
      const { success, error } = await updateContactNotesAsync(values);

      if (success) {
        showUIToast({ type: "info", text: "Note deleted." });
        setCurrentContact((current) => {
          return getUpdatedCurrentInfo(isSdr, current);
        });
        return;
      }

      error && showUIToast({ type: "error", text: error.message });
    }
  };

  const handleSaveNote = async (notes, isSdr) => {
    const values = updateContactPayload(isSdr, notes);

    if (values?.contactId) {
      const { success, error } = await updateContactNotesAsync(values);

      if (success) {
        showUIToast({ type: "info", text: "Note updated." });
        setCurrentContact((current) => {
          return getUpdatedCurrentInfo(isSdr, current, notes);
        });
        return;
      }

      error && showUIToast({ type: "error", text: error.message });
    }
  };

  const updateContactPayload = (isSdr, notes = null) => {
    let notesToUpdate;

    if (isSdr) {
      notesToUpdate = {
        contactId: currentContact._id ? currentContact._id : currentContact.id,
        notesSDR: notes,
      };
    } else {
      notesToUpdate = {
        contactId: currentContact._id ? currentContact._id : currentContact.id,
        notesAgent: notes,
      };
    }
    return notesToUpdate;
  };

  const getUpdatedCurrentInfo = (isSdr, current, notes = null) => {
    let values;
    if (isSdr) {
      values = { ...current, notesSDR: notes };
    } else {
      values = { ...current, notesAgent: notes };
    }
    return values;
  };

  return (
    <>
      <UIRow minHeight="100%" alignItems="center" gap={0}>
        {showHeader && (
          <UICol height="10%" xs={12} gap={0}>
            <HeaderContainer>
              <Text color="lightBlue" fontWeight="600" mr="25px">
                Notes
              </Text>
            </HeaderContainer>
          </UICol>
        )}
        <UICol height="40%" xs={12} gap={0}>
          <NotesContainer>
            <Note
              key={(currentContact._id ??= currentContact.id)}
              note={isSDR ? currentContact.notesSDR : currentContact.notesAgent}
              onDelete={canDelete ? () => handleDeleteNote(isSDR) : undefined}
              onSave={(e) => handleSaveNote(e, isSDR)}
              canEdit={canEdit}
            />
          </NotesContainer>
        </UICol>
        <UICol height={showHeader ? "50%" : "60%"} xs={12} gap={0}>
          {currentContact?.notes ||
          currentContact?.notesAgent ||
          currentContact?.notesSDR ? (
            <NotesContainer>
              {currentContact?.notes && (
                <UIText>
                  <Title>Notes: </Title>
                  {currentContact.notes}
                </UIText>
              )}
              {currentContact?.notesAgent && (
                <UIText>
                  <Title>Notes from Agent: </Title>
                  {currentContact.notesAgent}
                </UIText>
              )}
              {currentContact?.notesSDR && (
                <UIText>
                  <Title>Notes from SDR: </Title>
                  {currentContact.notesSDR}
                </UIText>
              )}
            </NotesContainer>
          ) : (
            <NotesContainer>
              <UIText>
                <Title>There are no notes </Title>
              </UIText>
            </NotesContainer>
          )}
        </UICol>
      </UIRow>
    </>
  );
};

export default Notes;

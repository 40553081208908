import axios from 'axios';
import { type FeatureFlag } from './entities/feature-flags.entity';
import routes from '@/src/constants/routes';
import { type ApiResponse } from '@/src/types/ApiResponse';

const getFeatureFlagsAsync = async (organizationId: string): Promise<ApiResponse<FeatureFlag>> => {
  try {
    const featureFlags = await axios.get<FeatureFlag>(
      `${routes.get.featureToggle.getFeaturesFlagsForOrg}/${organizationId}`,
    );

    return {
      data: featureFlags.data,
      success: true,
    };
  } catch (error: unknown) {
    return {
      error: { message: 'Error while getting organization feature flags' },
      success: false,
    };
  }
};

export default getFeatureFlagsAsync;

import axios from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import routes from '../../../../../src/constants/routes';
import {
  type CreateCustomDispositionDto,
  type CreateOutreachCallDispositionResponse,
} from './dto/create-custom-disposition.dto';

const createCustomDisposition = async (
  organizationId: string,
  nonce: string,
  createCustomDispositionDto: CreateCustomDispositionDto,
): Promise<ApiResponse<CreateOutreachCallDispositionResponse>> => {
  try {
    const url = `${routes.post.outreach.createCustomDisposition}/dispositions/${organizationId}/${nonce}`;
    const createResponse = await axios.post<CreateOutreachCallDispositionResponse>(url, {
      data: createCustomDispositionDto,
    });
    return {
      success: true,
      data: createResponse.data,
    };
  } catch (e: unknown) {
    return {
      success: false,
      error: {
        message:
          'An error occurred while updating outreach dispositions If the issue persists, please contact customer support.',
      },
    };
  }
};

export default createCustomDisposition;

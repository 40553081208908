import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import styled from "styled-components";
import { theme } from "../../../../../core/theme";

const { colors } = theme;

const UserWrapper = styled.div<{
  type: string;
}>`
  display: grid;
  grid-template-columns: 64px auto auto 1fr;
  grid-template-areas: "img name name delete" "img l c delete";
  grid-gap: 8px;
  align-items: center;

  font-weight: 600;
  padding: 0.5em 1em;
  color: ${colors.darkGray};
  border: 1px solid;
  border-radius: 0.5rem;

  .image {
    grid-area: img;
    flex: none;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2em;
      background-color: ${colors.lightGray};
    }
  }

  .name {
    grid-area: name;
    align-self: flex-end;
    font-weight: 700;
    padding-bottom: 4px;
    border-bottom: 1px solid;
    color: ${({ type }) => userTypeColor[type]};
  }

  .lists {
    grid-area: l;
    align-self: flex-start;
  }

  .contacts {
    grid-area: c;
    align-self: flex-start;
  }

  .delete {
    grid-area: delete;
    justify-self: flex-end;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: inherit;
    &:hover,
    &:focus {
      color: ${colors.lightBlue};
    }
  }
`;

export const OUTREACH_COLOR = "#5952ff";
export const HUBSPOT_COLOR = "#2e475d";

const userTypeColor = {
  outreach: OUTREACH_COLOR,
  hubspot: HUBSPOT_COLOR,
};

interface IntegrationUserProps {
  remove: (nonce: string, type: string) => void;
  id: string;
  name: string;
  activeProspectsCount: number;
  email: string;
  nonce: string;
  type: string;
}
const IntegrationUser: FC<IntegrationUserProps> = ({
  remove,
  id,
  name,
  email,
  nonce,
  activeProspectsCount,
  type = "OUTREACH",
}): JSX.Element => {
  const initials = name ? name.substring(0, 1) : email.substring(0, 1);
  return (
    <UserWrapper type={type} key={id}>
      <div className="image empty">{initials}</div>
      <div className="name">{name || email}</div>
      {activeProspectsCount && (
        <div className="lists">{`${activeProspectsCount} contacts`}</div>
      )}
      <button
        title="Delete user"
        className="delete"
        onClick={() => remove(nonce, type)}
      >
        <FontAwesomeIcon icon={["fas", "trash"]} />
      </button>
    </UserWrapper>
  );
};

export default IntegrationUser;

export enum DispositionAbbreviation {
  RTT = 'RTT',
  OO = 'OO',
  PR = 'PR',
  LVM = 'LVM',
  GC = 'GC',
  RAC = 'RAC',
  NLC = 'NLC',
  SA = 'SA',
  WN = 'WN',
  PTNC = 'PTNC',
  NA = 'NA',
  DMC = 'DMC',
  OTR = 'OTR',
  'BN/BS' = 'BN/BS',
  SDNPU = 'SDNPU',
  default_action = 'default_action',
}

export enum Outcome {
  COMPLETED = 'completed',
  NO_ANSWER = 'no_answer',
}

export interface Disposition {
  id: string;
  type: string;
  dispositionId: string;
  abbreviation: DispositionAbbreviation;
  defaultAction: DispositionDefaultAction;
  defaultName: string;
  customName: string;
  isCustom: boolean;
  outcome: Outcome;
}

export enum DispositionDefaultAction {
  RTT = 'Refuse to transfer',
  OO = 'Opt Out',
  LVM = 'Left Voicemail',
  PR = 'Phone Refusal (hangup)',
  GC = 'Gatekeeper Conversation',
  RAC = 'Referred to another contact',
  NLC = 'No Longer With Company',
  SA = 'Set Appointment',
  WN = 'Wrong Number',
  PTNC = 'Phone Tree No Connection',
  NA = 'No Answer',
  DMC = 'Decision Maker Conversation',
  OTR = 'Other',
  'BN/BS' = 'Bad Number/Busy Signal',
  SDNPU = 'User did not pick up',
  default_action = 'default_action',
}

export const DispositionAbbreviations: DispositionAbbreviation[] = [
  DispositionAbbreviation.RTT,
  DispositionAbbreviation.OO,
  DispositionAbbreviation.PR,
  DispositionAbbreviation.LVM,
  DispositionAbbreviation.GC,
  DispositionAbbreviation.RAC,
  DispositionAbbreviation.NLC,
  DispositionAbbreviation.SA,
  DispositionAbbreviation.WN,
  DispositionAbbreviation.PTNC,
  DispositionAbbreviation.NA,
  DispositionAbbreviation.DMC,
  DispositionAbbreviation.OTR,
  DispositionAbbreviation['BN/BS'],
  DispositionAbbreviation.SDNPU,
  DispositionAbbreviation.default_action,
];

export enum TendrilDefaultActions {
  'RTT' = 'Tendril - Gatekeeper Conversation',
  'OO' = 'Tendril - Answered - Opt Out',
  'PR' = 'Tendril - Call - Phone Refusal',
  'LVM' = 'Tendril - Left Voicemail',
  'GC' = 'Tendril - Gatekeeper Conversation',
  'RAC' = 'Tendril - Answered - Referral',
  'NLC' = 'Tendril - Answered - Wrong Contact',
  'SA' = 'Tendril - Answered - Meeting Set',
  'WN' = 'Tendril - Bad Number',
  'PTNC' = 'Tendril - Phone tree no connection',
  'NA' = 'Tendril - No Answer',
  'DMC' = 'Tendril - Answered - Decision Maker Conversation',
  'OTR' = 'Tendril - Call - Other',
  'BN/BS' = 'Tendril - Bad Number',
  'SDNPU' = 'Tendril - No Answer',
  'default_action' = 'default_action',
}

export type DispositionMap = Map<DispositionAbbreviation, string>;
export type DispositionRecord = Record<DispositionAbbreviation, string>;

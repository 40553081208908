import { type SaveHubspotMappingDto } from '../../../Data/DataSource/sync/hubspot-mappings/dto/save-hubspot-mapping.dto';
import { type ApiResponse } from '../../../../src/types/ApiResponse';
import { type HubspotMappingDto } from '../../../Data/DataSource/sync/hubspot-mappings/dto/get-hubspot-mappings.dto';
import { type CallDisposition } from '../../../Data/DataSource/sync/hubspot-mappings/dto/call-disposition.dto';
import {
  type Disposition,
  DispositionAbbreviations,
  DispositionDefaultAction,
  type DispositionRecord,
  Outcome,
  TendrilDefaultActions,
} from '../../../Presentation/Views/types/disposition-mapping.type';
import { type OutreachCallDispositions } from '../../../Data/DataSource/sync/outreach-mappings/dto/getOutreachCustomDispositions.dto';
import saveHubspotMappings from '../../../Data/DataSource/sync/hubspot-mappings/saveHubspotMappings';
import getHubspotMappingsByOrgId from '../../../Data/DataSource/sync/hubspot-mappings/getHubspotMappingsByOrgId';
import { HubspotMappingsRepository } from '../../../Data/Repository/sync/hubspot-mappings/hubspotMappingsRepository';

interface HubspotMappingsUseCaseProps {
  saveHubspotMapping: (
    mappingId: string,
    saveHubspotMappingDto: SaveHubspotMappingDto,
  ) => Promise<ApiResponse<HubspotMappingDto>>;

  getHubspotMappings: (organizationId: string) => Promise<ApiResponse<HubspotMappingDto>>;

  getHubspotCallDispositions: (organizationId: string, nonce: string) => Promise<ApiResponse<CallDisposition[]>>;

  isCustomDisposition: (customName: string) => boolean;

  getTransformedMappings: (
    mappings: DispositionRecord,
    customOutreachDispositions: CallDisposition[],
    dispositionId: string,
  ) => Disposition[];
}

function HubspotMappingsUseCase(): HubspotMappingsUseCaseProps {
  const { getHubspotCallDispositions } = HubspotMappingsRepository();
  function isCustomDisposition(customName: string): boolean {
    return (
      customName !== TendrilDefaultActions.default_action &&
      Object.values(TendrilDefaultActions).every((tendrilDefaultName) => customName !== tendrilDefaultName)
    );
  }

  return {
    async saveHubspotMapping(mappingId: string, saveHubspotMappingDto: SaveHubspotMappingDto) {
      const { data, error, success } = await saveHubspotMappings(mappingId, saveHubspotMappingDto);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getHubspotMappings(organizationId: string) {
      const { data, error, success } = await getHubspotMappingsByOrgId(organizationId);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getHubspotCallDispositions(organizationId: string, nonce: string) {
      const { data, error, success } = await getHubspotCallDispositions(organizationId, nonce);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    isCustomDisposition,

    getTransformedMappings(mappings: DispositionRecord, callDispositions: CallDisposition[], dispositionId: string) {
      const MAX_DISPOSITIONS = 16;

      const transformedMapping: Disposition[] = [];

      for (let index = 0; index < MAX_DISPOSITIONS; index++) {
        const isDefault = index === MAX_DISPOSITIONS - 1;
        const abbreviation = DispositionAbbreviations[index];
        const defaultAction = DispositionDefaultAction[abbreviation];
        const defaultName = TendrilDefaultActions[abbreviation];
        const mappingName = isDefault ? 'default_action' : mappings[abbreviation];

        const defaultCustomDisposition: OutreachCallDispositions = {
          id: String(index),
          attributes: {
            name: defaultName,
            outcome: Outcome.NO_ANSWER,
          },
          type: 'callDisposition',
        };

        const customDispositionMap = callDispositions.filter((map) => map.attributes.name === mappingName);

        const { id, type, attributes } =
          customDispositionMap[customDispositionMap.length - 1] ?? defaultCustomDisposition;

        const transformed: Disposition = {
          id,
          type,
          dispositionId,
          abbreviation,
          defaultAction,
          customName: attributes.name,
          defaultName,
          isCustom: isCustomDisposition(attributes.name ?? mappingName),
          outcome: attributes.outcome as Outcome,
        };

        transformedMapping.push(transformed);
      }

      return transformedMapping;
    },
  };
}

export default HubspotMappingsUseCase;

import axios from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import { type SafeUserDto } from './dto/safeUser.dto';
import { UploadImageDto } from './dto/uploadImage.dto';
import routes from '../../../../../src/constants/routes';

const uploadProfilePictureAsync = async (
  id: string,
  userImage = new UploadImageDto(),
): Promise<ApiResponse<SafeUserDto>> => {
  try {
    const user = await axios.post<SafeUserDto>(`${routes.post.users.uploadPicture}/${id}`, userImage, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return { data: user.data, success: true };
  } catch (error) {
    return { error: { message: 'Error trying to update user' }, success: false };
  }
};

export default uploadProfilePictureAsync;

import { type CallSession } from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { CallSessionRepository } from '@repository/contacts-nest/callSession/callSessionRepository';

interface CallSessionUseCaseProps {
  getCallSessionsBySdrId: (userId: string, organizationId: string) => Promise<ApiResponse<CallSession[]>>;
}

export function CallSessionUseCase(): CallSessionUseCaseProps {
  const { getCallSessionsBySdrId } = CallSessionRepository();

  return {
    async getCallSessionsBySdrId(sdrId: string, orgId: string) {
      const { data, error, success } = await getCallSessionsBySdrId(sdrId, orgId);
      return { data, error, success };
    },
  };
}

import { useState, useEffect, useContext } from 'react';
import { CallSessionUseCase } from '../../../Domain/UseCase/contacts-nest/CallSessionUseCase';
import { type CallSession } from '../../../Data/DataSource/contacts-nest/callSession/entities/callSession.entity';
import { LoadingState } from '../../../../src/types/LoadingStates.type';
import { UserProviderContext } from '../../../../src/components/app/UserProvider';
import moment from 'moment-timezone';
import { featuresStore } from '@/src/components/app/FeaturesProvider';

const { IDLE, LOADING, ERROR, SUCCESS } = LoadingState;

export interface useCallSessionViewModelI {
  callSessions: CallSession[];
  titlesSession: any[];
  loadingState: useCallSessionViewModelLoadingState;
  formatAndSetData: (callSessions: CallSession[]) => void;
}

interface useCallSessionViewModelLoadingState {
  fetchCallSessionsBySdrId: LoadingState;
}

const useCallSessionViewModel = (): useCallSessionViewModelI => {
  const [callSessions, setCallSessions] = useState([] as CallSession[]);
  const [titlesSession, setTitles] = useState([] as any[]);
  const { currentUser } = useContext(UserProviderContext);
  const [loadingState, setLoadingState] = useState<useCallSessionViewModelLoadingState>({
    fetchCallSessionsBySdrId: IDLE,
  });
  const { contactsFilter } = useContext(featuresStore).featuresState;
  const callSessionUseCase = CallSessionUseCase();

  const formatAndSetData = (callSessions: CallSession[]): void => {
    const formatedData = callSessions.map((callSession) => {
      const parsedDate = moment(callSession.startAt).format('YYYY-MM-DD');
      const title = `${callSession.contactList[0].title}-${parsedDate}-${callSession.id.substring(0, 6)}+${
        callSession.contactList[0].organization
      }`;
      return {
        id: callSession.id,
        title,
        type: 'filteredContacts',
      };
    });

    setTitles(formatedData);
  };

  const fetchCallSessionsBySdrId = async (sdrId: string, orgId: string): Promise<void> => {
    setLoadingState((prevState) => ({
      ...prevState,
      fetchCallSessionsBySdrId: LOADING,
    }));

    const { data, error, success } = await callSessionUseCase.getCallSessionsBySdrId(sdrId, orgId);

    if (data === undefined || error !== undefined || success === false) {
      setLoadingState({ ...loadingState, fetchCallSessionsBySdrId: ERROR });
      return;
    }

    formatAndSetData(data);

    setCallSessions(data);

    setLoadingState((prevState) => ({
      ...prevState,
      fetchCallSessionsBySdrId: SUCCESS,
    }));
  };

  useEffect(() => {
    if (loadingState.fetchCallSessionsBySdrId !== LOADING && contactsFilter) {
      void fetchCallSessionsBySdrId(currentUser.userId, currentUser.organizationId).finally();
    }
  }, []);

  return { callSessions, titlesSession, loadingState, formatAndSetData };
};

export default useCallSessionViewModel;

import React, { useState, useRef, useEffect } from "react";
import { Box, Flex, Button, Text, Image } from "../../core/ui";
import styled from "styled-components";
import { useTheme } from "styled-components";
import DeleteIcon from "../../assets/icons/delete_blue.svg";
import { TransparentButton } from ".";
import { Device } from "twilio-client";
import { isPhoneNumberValid } from "../../core/utils";
import ReactTooltip from 'react-tooltip';
import { Contact } from "../../types/ContactTypes";
import ChronometerComponent from "./Chronometer.component";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

const keypad = [
  [
    { number: "1", letter: "" },
    { number: "2", letter: "ABC" },
    { number: "3", letter: "DEF" },
  ],
  [
    { number: "4", letter: "GHI" },
    { number: "5", letter: "JKL" },
    { number: "6", letter: "MNO" },
  ],
  [
    { number: "7", letter: "PQRS" },
    { number: "8", letter: "TUV" },
    { number: "9", letter: "WXYZ" },
  ],
  [
    { number: "*", letter: "" },
    { number: "0", letter: "+" },
    { number: "#", letter: "" },
  ],
];

const RoundedButton = styled(Button)<{ disabled?: boolean }>`
  width: 50px;
  height: 50px;
  margin: 5px;
  padding: 0;
  border-radius: 50%;
  ${({ disabled, theme }) =>
    disabled && `background: ${theme.colors.lightGray};`}
`;

const SymbolButton = styled(RoundedButton)`
  background: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.lighterBlue};
  color: ${({ theme }) => theme.colors.darkGray};
`;

const InputContainer = styled(Flex)`
  width: 100%;
  padding: 6px 12px;
  border-radius: 12px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.lightGray};
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  font-weight: 500;
  font-size: 26px;
  color: ${({ theme }) => theme.colors.darkGray};
  outline: none;
`;

export type ContactPhoneStatus = {
  isValid: boolean;
  value: string;
};
interface Props {
  handleToggleCall: (
    number: string,
    currentContact: Contact,
    contactListId: string
  ) => void;
  currentContact: Contact;
  twilioDevice: Device;
  onPhone: boolean;
  callStatus: string | undefined;
  contactPhoneData: ContactPhoneStatus | null;
  setContactPhoneData: ({ isValid, value }: ContactPhoneStatus) => void;
}

const Phone = ({
  twilioDevice,
  handleToggleCall,
  currentContact,
  onPhone,
  callStatus,
  contactPhoneData,
  setContactPhoneData,
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>("");
  const [callButtonDisabled, setCallButtonDisabled] = useState<boolean>(true);
  const inputRef = useRef<any>(null);

  const handleStartCall = () => {
    handleToggleCall(inputValue, currentContact, currentContact.contactListId);
    setInputValue("");
  };

  const handleDRMFTClick = (digit: string) => {
    if (onPhone) {
      twilioDevice.activeConnection()?.sendDigits(digit);
    }
    setInputValue(inputValue + digit);
  };

  const toggleTooltip = (isValid: boolean) => {
    if (isValid) {
      ReactTooltip.hide(inputRef.current);
    } else {
      ReactTooltip.show(inputRef.current);
    }
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    const isPhoneNumber = isPhoneNumberValid(value);
    toggleTooltip(isPhoneNumber);
    if (!onPhone) {
      setContactPhoneData({ isValid: isPhoneNumber, value });
    }
  };

  const handleCallButtonDisabled = () => {
    const initialDisabled = !onPhone && callStatus === "paused";
    if (contactPhoneData) {
      return initialDisabled || !isPhoneNumberValid(inputValue);
    }
    return initialDisabled;
  };

  useEffect(() => {
    if (Object.keys(currentContact).length && !onPhone) {
      const isPhoneNumber = isPhoneNumberValid(
        contactPhoneData?.value ?? currentContact.phone
      );
      toggleTooltip(isPhoneNumber);
      setInputValue(contactPhoneData?.value ?? currentContact.phone);
    }
  }, [currentContact.phone, contactPhoneData]);

  useEffect(() => {
    if (Object.keys(currentContact).length && !onPhone) {
      setCallButtonDisabled(handleCallButtonDisabled());
    }
  }, [onPhone, callStatus, inputValue, contactPhoneData]);

  return (
    <Box mx="3px">
      <InputContainer>
        <StyledInput
          data-qa-id={"phoneNumberInput"}
          value={inputValue}
          onChange={(e) => handleInputChange(e.target.value)}
          data-tip="The given number is incorrect,<br />please, check it."
          data-for="phone-error"
          ref={inputRef}
        />
        <TransparentButton
          data-qa-id={"delPhoneBtn"}
          onClick={() => handleInputChange(inputValue.slice(0, -1))}
        >
          <Image src={DeleteIcon} alt="Delete icon" width="20px" ml="10px" />
        </TransparentButton>
        <ReactTooltip
          id="phone-error"
          place="top"
          type="error"
          effect="solid"
          event="custom"
          scrollHide={false}
          resizeHide={false}
          multiline={true}
          delayShow={500}
          delayHide={500}
        />
      </InputContainer>
      <Flex flexDirection="column" alignItems="center" mt="20px">
        {keypad.map((column, index) => (
          <Flex key={index}>
            {column.map(({ number, letter }) => (
              <SymbolButton
                data-qa-id={"numBtn_" + number}
                key={number}
                onClick={() => handleDRMFTClick(number)}
              >
                <Text fontSize="22px" lineHeight="1">
                  {number}
                </Text>
                <Text fontSize="8px">{letter}</Text>
              </SymbolButton>
            ))}
          </Flex>
        ))}
      </Flex>

      <Flex justifyContent="center">
        <RoundedButton
          data-qa-id={"callbtn"}
          bg={useTheme().statusColors.callBtnBg}
          onClick={handleStartCall}
          disabled={callButtonDisabled}
        >
          <FA icon={["fas", "phone"]} size="lg" color="white" />
        </RoundedButton>
      </Flex>

      <ChronometerComponent isTimerOn={onPhone} />
    </Box>
  );
};

export default Phone;

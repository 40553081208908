import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BaseInput, BaseLabel } from "../../../core/ui/base/FormElements";
import { UIButton } from "../../../core/ui/UIElements";
import Counter from "../../../components/shared/Counter";
import { Row } from "../../Login/LoginElements";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { UI_TOAST_TYPES } from "../../../core/ui/UIToast";
import login from "../../../api/Auth/login";
import updatePassword from "../../../api/Auth/updatePassword";
import validateVerificationCode from "../../../api/Auth/validateVerificationCode";
import sendVerificationCode from "../../../api/Auth/sendVerificationCode";

const Container = styled.div`
  margin-top: 1rem;
`;
const FormContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 60%;
`;

const CenteredFormContainer = styled(FormContainer)`
  align-items: flex-end;
  width: 50%;
  margin: auto;
  margin-top: 10px;
`;

const Form = styled.form<{
  visible: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const VerificationForm = styled(Form)`
  display: ${(props) => (props.visible === "true" ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 12px;
  width: 100%;
`;

const VerificationLabelContainer = styled(LabelContainer)`
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  background-color: transparent;
`;

const VerificationCodeInput = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const VerificationCodeDigitInput = styled(BaseInput)`
  width: 40px;
  padding: 0.5rem 0;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0.25rem;
`;

const Label = styled(BaseLabel)`
  margin-right: 10px;
  margin-bottom: 4px;
`;

const CenteredButton = styled(UIButton)`
  align-self: center;
  margin-top: 5px;
`;

const Icon = styled.i`
  width: 32px;
  height: 30px;
  justify-self: end;
  position: absolute;
  margin-right: 20px;
`;

interface Props {
  userData: any;
  setUserData: (userData: any) => void;
  setShowPasswordButton: (showPasswordButton: boolean) => void;
  showMessage: (type: UI_TOAST_TYPES, message: string) => void;
  handleUserDataChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CenteredForm = ({
  userData,
  setUserData,
  setShowPasswordButton,
  showMessage,
  handleUserDataChange,
}: Props): JSX.Element => {
  const [showPasswordForm, setShowPasswordForm] = useState(false);

  const [showValidationCodeForm, setShowValidationCodeForm] = useState(true);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [remainingTTL, setRemainingTTL] = useState(-1);

  const codeInputs = useRef<HTMLInputElement[]>([]);

  const clearVerificationCode = () => {
    codeInputs.current.forEach((input) => {
      if (input) {
        input.value = "";
      }
    });
  };

  const togglePassword = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    const iconName = event.currentTarget.getAttribute("data-name");

    iconName &&
      setShowPassword({ ...showPassword, [iconName]: !showPassword[iconName] });
  };

  const refreshToken = async (email: string, password: string) => {
    const { authToken, error } = await login(email, password);

    if (!authToken) {
      showMessage(
        UI_TOAST_TYPES.ERROR,
        "Session expired. Please log in again to continue."
      );

      throw new Error(error?.message);
    }

    localStorage.setItem("target", authToken);
  };

  const handleSubmitNewPassword = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    let message: string;

    if (
      userData.currentPassword === "" ||
      userData.newPassword === "" ||
      userData.confirmPassword === ""
    ) {
      message = "Password cannot be empty.";

      showMessage(UI_TOAST_TYPES.ERROR, message);

      throw new Error(message);
    }

    if (userData.newPassword !== userData.confirmPassword) {
      message = "Passwords are not identical. Please review your confirmation.";

      showMessage(UI_TOAST_TYPES.ERROR, message);

      throw new Error(message);
    }

    const token = window.localStorage.getItem("target") || "";

    const { error, success } = await updatePassword(
      token,
      userData.newPassword,
      userData.currentPassword
    );

    if (error && error.message && !success) {
      showMessage(UI_TOAST_TYPES.ERROR, error.message);
      throw new Error(error.message);
    }

    showMessage(
      UI_TOAST_TYPES.SUCCESS,
      "Password has been changed successfully."
    );

    setShowPasswordButton(true);

    setShowPasswordForm(false);

    await refreshToken(userData.email, userData.newPassword);
    clearVerificationCode();
    clearPasswordForm();
  };
  const sendEmailVerificationCode = async () => {
    const { data, error } = await sendVerificationCode(userData.id);

    if (error && error.message && data && data.status !== 201 && data.TTL) {
      showMessage(UI_TOAST_TYPES.ERROR, error.message);

      throw new Error(error.message);
    }

    data && setRemainingTTL(data.TTL);

    if (!data?.IsFirstTime) {
      showMessage(
        UI_TOAST_TYPES.INFO,
        "The verification code will expire in " + data?.TTL + " seconds."
      );
      setIsFirstTime(!isFirstTime);
    } else {
      showMessage(
        UI_TOAST_TYPES.INFO,
        "You will recive a verification code email, expired in 2 minutes."
      );

      setIsFirstTime(!isFirstTime);
    }
  };
  const handleVerifyButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    const buttonText = (event.target as HTMLButtonElement).textContent;

    if (buttonText === "Verify Code") {
      const verificationCode = codeInputs.current
        .map((input) => input.value)
        .join("");

      if (verificationCode.length < 6) {
        const message = "Verification code is invalid.";

        showMessage(UI_TOAST_TYPES.ERROR, message);

        throw new Error(message);
      }

      const { data, error } = await validateVerificationCode(
        userData.id,
        verificationCode
      );

      if (error && error.message && data?.status !== 201) {
        showMessage(UI_TOAST_TYPES.ERROR, error.message);

        clearVerificationCode();

        setRemainingTTL(-1);

        throw new Error(error.message);
      }

      setShowPasswordForm(true);

      setShowValidationCodeForm(false);

      clearVerificationCode();

      setRemainingTTL(-1);
    } else {
      clearVerificationCode();

      sendEmailVerificationCode();
    }
  };

  const handleDigitChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target;

    const value = input.value;

    if (value.length === 1 && index < codeInputs.current.length - 1) {
      codeInputs.current[index + 1].focus();
    }
  };

  const handlePaste = (
    index: number,
    event: React.ClipboardEvent<HTMLInputElement>
  ) => {
    event.preventDefault();

    const pasteData = event.clipboardData.getData("text");

    for (let i = 0; i < Math.min(pasteData.length, 6 - index); i++) {
      codeInputs.current[index + i].value = pasteData[i];

      if (index + i < codeInputs.current.length - 1) {
        codeInputs.current[index + i + 1].focus();
      }
    }
  };

  const clearPasswordForm = () => {
    setUserData({
      ...userData,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });

    setShowPassword({
      ...showPassword,
      currentPassword: false,
      newPassword: false,
      confirmPassword: false,
    });
  };

  useEffect(() => {
    if (remainingTTL == 0) {
      showMessage(UI_TOAST_TYPES.ERROR, "Verification code has expired.");
      clearVerificationCode();
    }
    if (isFirstTime) {
      sendEmailVerificationCode();
    }
  }, [remainingTTL, isFirstTime]);

  return (
    <Container>
      <CenteredFormContainer>
        <VerificationForm visible={showValidationCodeForm.toString()}>
          <VerificationLabelContainer>
            <Label>Introduce your verification code:</Label>
          </VerificationLabelContainer>
          <VerificationCodeInput>
            {Array.from({ length: 6 }, (_, index) => (
              <VerificationCodeDigitInput
                key={index}
                type="text"
                maxLength={1}
                ref={(element) =>
                  (codeInputs.current[index] = element as HTMLInputElement)
                }
                onChange={(e) => handleDigitChange(index, e)}
                onPaste={(e) => handlePaste(index, e)}
                disabled={remainingTTL > 0 ? false : true}
              />
            ))}
          </VerificationCodeInput>
          <UIButton onClick={(e) => handleVerifyButtonClick(e)}>
            {remainingTTL > 0 ? "Verify Code" : "Send verification code"}
          </UIButton>
          {remainingTTL > 0 && (
            <Counter
              remainingTTL={remainingTTL}
              setRemainingTTL={setRemainingTTL}
            />
          )}
        </VerificationForm>

        <VerificationForm visible={showPasswordForm.toString()}>
          <VerificationLabelContainer>
            <Label>Introduce your current password:</Label>
            <Row>
              <Icon
                data-name="currentPassword"
                onClick={(e) => togglePassword(e)}
              >
                {!showPassword.currentPassword ? (
                  <FA icon="eye" color="#95979A" size="xl" />
                ) : (
                  <FA icon="eye-slash" color="#95979A" size="xl" />
                )}
              </Icon>
              <BaseInput
                type={showPassword.currentPassword ? "text" : "password"}
                name="currentPassword"
                value={userData.currentPassword}
                onChange={(e) => handleUserDataChange(e)}
                required
              />
            </Row>
          </VerificationLabelContainer>
          <VerificationLabelContainer>
            <Label>Introduce your new password:</Label>
            <Row>
              <Icon data-name="newPassword" onClick={(e) => togglePassword(e)}>
                {!showPassword.newPassword ? (
                  <FA icon="eye" color="#95979A" size="xl" />
                ) : (
                  <FA icon="eye-slash" color="#95979A" size="xl" />
                )}
              </Icon>
              <BaseInput
                type={showPassword.newPassword ? "text" : "password"}
                name="newPassword"
                value={userData.newPassword}
                onChange={(e) => handleUserDataChange(e)}
                required
              />
            </Row>
          </VerificationLabelContainer>
          <VerificationLabelContainer>
            <Label>Confirm your new password:</Label>
            <Row>
              <Icon
                data-name="confirmPassword"
                onClick={(e) => togglePassword(e)}
              >
                {!showPassword.confirmPassword ? (
                  <FA icon="eye" color="#95979A" size="xl" />
                ) : (
                  <FA icon="eye-slash" color="#95979A" size="xl" />
                )}
              </Icon>
              <BaseInput
                type={showPassword.confirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={userData.confirmPassword}
                onChange={(e) => handleUserDataChange(e)}
                required
              />
            </Row>
          </VerificationLabelContainer>
          <CenteredButton onClick={(e) => handleSubmitNewPassword(e)}>
            Change password
          </CenteredButton>
        </VerificationForm>
      </CenteredFormContainer>
    </Container>
  );
};
export default CenteredForm;

import { type ApiResponse } from '@/src/types/ApiResponse';
import { type CallSession } from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import routes from '@/src/constants/routes';
import axios from 'axios';

async function getCallSessionById(callSessionId: string): Promise<ApiResponse<CallSession>> {
  try {
    const callSession = await axios.get<CallSession>(`${routes.get.callSession.getByIdNest}/${callSessionId}`);
    return { data: callSession.data };
  } catch {
    return { error: { message: 'The requested call session could not been updated' }, success: false };
  }
}

export default getCallSessionById;

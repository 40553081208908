import React, { type ButtonHTMLAttributes, type ReactElement } from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface ButtonIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconDefinition;
  loading?: boolean;
}

export default function ButtonIcon({
  loading,
  icon,
  ...buttonProps
}: ButtonIconProps): ReactElement<HTMLButtonElement> {
  return (
    <button
      disabled={loading === true || buttonProps?.disabled}
      name="Icon Button"
      className="text-app-blue-light inline-flex gap-2 items-center bg-transparent focus:ring-1 focus:outline-none focus:ring-app-blue-lighter font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      {...buttonProps}
    >
      {loading === true ? (
        <FA icon={faSpinner} spinPulse size="xl" name="Loading Icon" />
      ) : (
        <FA icon={icon} size="xl" name="Icon Button" />
      )}
    </button>
  );
}

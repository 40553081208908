import axios from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import routes from '../../../../../src/constants/routes';
import { type OutreachMappingDto } from './dto/get-outreach-mappings.dto';

const getOutreachMappingsByOrgId = async (organizationId: string): Promise<ApiResponse<OutreachMappingDto>> => {
  try {
    const url = `${routes.get.outreach.getMappingsByOrgId}/${organizationId}`;
    const mappings = await axios.get<OutreachMappingDto>(url);

    return {
      success: true,
      data: mappings.data,
    };
  } catch (e: unknown) {
    return {
      success: false,
      error: {
        message:
          'An error occurred while retrieving outreach mappings. If the issue persists, please contact customer support.',
      },
    };
  }
};

export default getOutreachMappingsByOrgId;

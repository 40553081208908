import React from "react";
import { UIButton, UIText } from "../../../core/ui/UIElements";
import styled from "styled-components";
import { Box } from "../../../core/ui";
import useMicrophone from "../../../core/hooks/useMicrophone";

const MicrophoneSetupModal = (): JSX.Element => {
  const { setupMicrophonePermissions, isMicrophoneAllowed } = useMicrophone();

  return (
    <>
      {!isMicrophoneAllowed && (
        <Container>
          <ModalWrapper>
            <ModalTitle>Microphone Setup</ModalTitle>
            <ModalContent>
              <ModalUIText>
                To start making or receiving calls, please ensure to allow the
                browser access to your microphone.
              </ModalUIText>
            </ModalContent>
            <ModalButtons>
              <UIButton
                onClick={() => setupMicrophonePermissions({ showToast: true })}
              >
                Check Microphone Access
              </UIButton>
            </ModalButtons>
          </ModalWrapper>
        </Container>
      )}
    </>
  );
};

const Container = styled(Box)`
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3.75px);
  background: rgba(255, 255, 255, 0.8);
`;

const ModalUIText = styled(UIText)`
  font-size: 1.6rem;
  font-weight: 400;
  text-align: justify;
  color: black;
  line-height: 1.25;
`;

const ModalWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 0.5rem;
  color: black;
`;

const ModalTitle = styled(UIText)`
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  color: black;
  line-height: 1.25;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  gap: 1rem;
  padding: 1rem;
`;

const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export default MicrophoneSetupModal;

import React from "react";
import GenericModal from "../../../components/shared/GenericModal";
import { Box, Flex, Text } from "../../../core/ui";
import { NumberPool } from "../../../types";
import { showUIToast } from "../../../core/ui/UIToast";
import {
  CheckboxField,
  InputField,
  StyledButton,
  StyledForm,
} from "../../../components/shared/FormAndFields";
import { Field, Form } from "react-final-form";
import updateNumberAsync from "../../../api/Numbers/updateNumberAsync";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedNumber: NumberPool;
  onSuccess: () => void;
};

const EditUserNumberModal = ({
  isOpen,
  onClose,
  selectedNumber,
  onSuccess,
}: Props) => {
  const handleUpdateNumber = async (data) => {
    const { numberPool, error } = await updateNumberAsync(data);
    if (numberPool) {
      showUIToast({ type: "info", text: "Number updated." });
      onSuccess();
      return;
    }

    error && showUIToast({ type: "error", text: error.message });
  };
  const handleEditNumber = async (values) => {
    const data = {
      location: values.location,
      ownerId: selectedNumber.ownerId,
      number: selectedNumber.number,
      type: selectedNumber.type,
      active: values.active,
      id: selectedNumber.id,
    };

    await handleUpdateNumber(data);
  };
  return (
    <GenericModal isOpen={isOpen} onClose={onClose} padding="50px 20px 20px">
      <Box>
        <Text color="black" mb={15} textAlign="center">
          {selectedNumber
            ? `Please edit ${selectedNumber.number} data`
            : "Please fill all the fields"}
        </Text>
        <Form
          onSubmit={handleEditNumber}
          initialValues={selectedNumber}
          render={({ handleSubmit, form, submitting, pristine, valid }) => (
            <StyledForm onSubmit={handleSubmit}>
              <Flex alignItems="center" flexDirection={"column"}>
                <Field
                  label="Location"
                  name="location"
                  component={InputField}
                  type="text"
                />
                <Field
                  label={"Active"}
                  name="active"
                  component={CheckboxField}
                  type="checkbox"
                />
              </Flex>

              <StyledButton
                bg="lightBlue"
                px={25}
                py={12}
                type="submit"
                mt={12}
                disabled={submitting || pristine || !valid}
              >
                <Text fontSize={16} fontWeight="600" color="white">
                  Submit
                </Text>
              </StyledButton>
            </StyledForm>
          )}
        ></Form>
      </Box>
    </GenericModal>
  );
};

export default EditUserNumberModal;

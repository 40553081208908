import axios from 'axios';
import routes from '../../../../../src/constants/routes';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';

interface HubspotCustomDispositionDataSource {
  id: string;
  label: string;
  deleted: boolean;
}

const getHubspotCustomDispositions = async (
  organizationId: string,
  nonce: string,
): Promise<ApiResponse<HubspotCustomDispositionDataSource[]>> => {
  try {
    const url = `${routes.get.hubspot.getCallDispositions}/${organizationId}/${nonce}`;
    const dispositions = await axios.get<HubspotCustomDispositionDataSource[]>(url);

    return {
      success: true,
      data: dispositions.data,
    };
  } catch (e: unknown) {
    return {
      success: false,
      error: {
        message:
          'An error occurred while retrieving hubspot call dispositions. If the issue persists, please contact customer support.',
      },
    };
  }
};

export default getHubspotCustomDispositions;

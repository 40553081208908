import routes from '../../../../../src/constants/routes';
import { type HubspotMappingDto } from './dto/get-hubspot-mappings.dto';
import axios from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';

const getHubspotMappingsByOrgId = async (organizationId: string): Promise<ApiResponse<HubspotMappingDto>> => {
  try {
    const url = `${routes.get.hubspot.getMappingsByOrgId}/${organizationId}`;
    const mappings = await axios.get<HubspotMappingDto>(url);

    return {
      success: true,
      data: mappings.data,
    };
  } catch (e: unknown) {
    return {
      success: false,
      error: {
        message:
          'An error occurred while retrieving hubspot mappings. If the issue persists, please contact customer support.',
      },
    };
  }
};

export default getHubspotMappingsByOrgId;

import React, { useState } from "react";
import { Box, Flex, Button, Text, Image } from "../../core/ui";
import styled from "styled-components";
import { TransparentButton } from ".";
import { Contact } from "./Contact";
import { Device } from "twilio-client";
import DeleteIcon from "../../assets/icons/delete_blue.svg";
import ChronometerComponent from "./Chronometer.component";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

const keypad = [
  [
    { number: "1", letter: "" },
    { number: "2", letter: "ABC" },
    { number: "3", letter: "DEF" },
  ],
  [
    { number: "4", letter: "GHI" },
    { number: "5", letter: "JKL" },
    { number: "6", letter: "MNO" },
  ],
  [
    { number: "7", letter: "PQRS" },
    { number: "8", letter: "TUV" },
    { number: "9", letter: "WXYZ" },
  ],
  [
    { number: "*", letter: "" },
    { number: "0", letter: "+" },
    { number: "#", letter: "" },
  ],
];
const RoundedButton = styled(Button)`
  width: 50px;
  height: 50px;
  margin: 5px;
  padding: 0;
  border-radius: 50%;
`;

const SymbolButton = styled(RoundedButton)`
  background: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.lighterBlue};
  color: ${({ theme }) => theme.colors.darkGray};
`;

const InputContainer = styled(Flex)`
  width: 100%;
  padding: 6px 12px;
  border-radius: 12px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.lightGray};
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  font-weight: 500;
  font-size: 26px;
  color: ${({ theme }) => theme.colors.darkGray};
  outline: none;
`;

interface Props {
  hangup: () => void;
  currentContact: Contact;
  callSessionId: string | undefined;
  onPhone: boolean;
  twilioDevice: Device;
}

const SDRPhone = ({
  hangup,
  callSessionId,
  onPhone,
  twilioDevice,
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleDRMFTClick = (digit: string) => {
    if (onPhone) {
      twilioDevice.activeConnection()?.sendDigits(digit);
    }
    setInputValue(inputValue + digit);
  };

  return (
    <Box mx="3px">
      <InputContainer>
        <StyledInput
          data-qa-id={"phoneNumberInput"}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <TransparentButton
          data-qa-id={"delPhoneBtn"}
          onClick={() => setInputValue(inputValue.slice(0, -1))}
        >
          <Image src={DeleteIcon} alt="Delete icon" width="20px" ml="10px" />
        </TransparentButton>
      </InputContainer>
      <Flex flexDirection="column" alignItems="center" mt="20px">
        {keypad.map((column, index) => (
          <Flex key={index}>
            {column.map(({ number, letter }) => (
              <SymbolButton
                data-qa-id={"numBtn_" + number}
                key={number}
                onClick={() => handleDRMFTClick(number)}
              >
                <Text fontSize="22px" lineHeight="1">
                  {number}
                </Text>
                <Text fontSize="8px">{letter}</Text>
              </SymbolButton>
            ))}
          </Flex>
        ))}
      </Flex>
      <Flex justifyContent="center">
        {onPhone && (
          <RoundedButton
            data-qa-id={"callBtn"}
            bg={`linear-gradient(90deg, #DBA754, #CD6D47)`}
            onClick={() => hangup()}
          >
            <FA icon={["fas", "phone"]} size="lg" color="white" />
          </RoundedButton>
        )}
      </Flex>
      <ChronometerComponent isTimerOn={onPhone} />
    </Box>
  );
};

export default SDRPhone;

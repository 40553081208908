import { type UpdateFeatureFlagsDto } from '@/src-v2/Data/DataSource/sync/feature-flags/dto/update-feature-flags.dto';
import { type FeatureFlag } from '@/src-v2/Data/DataSource/sync/feature-flags/entities/feature-flags.entity';
import updateFeatureFlags from '@/src-v2/Data/DataSource/sync/feature-flags/updateFeatureFlags';
import getFeatureFlagsAsync from '@datasource/sync/feature-flags/getFeatureFlags';

interface FeatureFlagRepositoryProps {
  getFeatureFlags: (organizationId: string) => Promise<FeatureFlag | undefined>;
  updateFeatureFlags: (
    organizationId: string,
    updateFeatureFlagsDto: UpdateFeatureFlagsDto,
  ) => Promise<FeatureFlag | undefined>;
}

export default function FeatureFlagRepository(): FeatureFlagRepositoryProps {
  return {
    async getFeatureFlags(organizationId: string) {
      const { data, error } = await getFeatureFlagsAsync(organizationId);

      if (data === undefined || error !== undefined) {
        return undefined;
      }

      return data;
    },
    async updateFeatureFlags(organizationId: string, updateFeatureFlagsDto: UpdateFeatureFlagsDto) {
      const { data, error } = await updateFeatureFlags(organizationId, updateFeatureFlagsDto);

      if (data === undefined || error !== undefined) {
        return undefined;
      }

      return data;
    },
  };
}

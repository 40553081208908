import getContactsByCallSessionIds from '../../../DataSource/contacts-nest/contacts/getContactsByCallSessionId';
import { type Contact } from '../../../DataSource/contacts-nest/contacts/entities/contact.entity';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import { type SelectedFilters } from '@/src-v2/Domain/interfaces/Contacts/filter.interfaces';
import updateContactAsync from '@/src-v2/Data/DataSource/contacts-nest/contacts/updateContactAsync';

interface CallSessionRepo {
  getContactsByCallSessionIds: (
    userId: string,
    callSessionIds: string[],
    filters: SelectedFilters,
  ) => Promise<ApiResponse<Contact[]>>;

  updateContactAsync: (contact: Contact) => Promise<ApiResponse<Contact>>;
}

export function ContactsRepository(): CallSessionRepo {
  return {
    async getContactsByCallSessionIds(userId: string, callSessionIds: string[], filters: SelectedFilters) {
      const { data, error, success } = await getContactsByCallSessionIds(userId, callSessionIds, filters);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async updateContactAsync(contact: Contact) {
      const { data, error, success } = await updateContactAsync(contact);
      return { data, error, success };
    },
  };
}

import moment from 'moment-timezone';
export interface CallSession {
  id: string;
  sdrId: string;
  status: string;
  sdrStatus: string;
  startAt: number;
  endsAt: number;
  contactList: [ContactList];
  voiceDropsIds: [string];
  speech: string;
  agents: string[];
  scriptId: string;
  scriptTitle: string;
  startedAt: Date;
}

interface ContactList {
  contactListId: string;
  completed: boolean;
  title: string;
  organization: string;
}

export class AgentInfo {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class ActiveCallSession {
  callSessionId!: string;
  sdrId!: string;
  status!: string;
  sdrStatus!: string;
  startAt!: string;
  agents: AgentInfo[] | null;
  sdrName!: string;
  organization!: string;
  organizationId!: string;

  constructor(
    callSessionId: string,
    sdrId: string,
    status: string,
    sdrStatus: string,
    startAt: string,
    sdrName: string,
    organization: string,
    organizationId: string,
    agents: AgentInfo[] | null,
  ) {
    this.callSessionId = callSessionId;
    this.sdrId = sdrId;
    this.status = status;
    this.sdrStatus = sdrStatus;
    this.startAt = moment(startAt).format('YYYY-MM-DD HH:mm:ss');
    this.agents =
      agents != null
        ? agents.map((agent) => {
            return new AgentInfo(agent.id, agent.name);
          })
        : null;
    this.sdrName = sdrName != null ? sdrName : 'Not available';
    this.organization = organization != null ? organization : 'Not available';
    this.organizationId = organizationId != null ? organizationId : 'Not available';
  }
}

import React, { type FC, useEffect } from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Modals, type OutreachMappingsViewModel } from '../OutreachMappings.viewmodel';

const MappingActions: FC<{ viewModel: OutreachMappingsViewModel }> = ({ viewModel }) => {
  const saveButtonRef = React.useRef<HTMLButtonElement>(null);

  const { isOutreachAccount, isSuperAdmin, isAdmin, hasMappingsChanged } = viewModel.viewState;

  const { toggleModal, saveMappings } = viewModel;

  useEffect(() => {
    if (saveButtonRef.current !== null) {
      saveButtonRef.current.classList.toggle('disabled', !hasMappingsChanged);
    }
  }, [hasMappingsChanged]);

  const isActionsAvailable = (isOutreachAccount && isAdmin) || (isOutreachAccount && isSuperAdmin);

  return isActionsAvailable ? (
    <div className="flex w-full justify-end gap-8">
      <button
        type="button"
        onClick={() => {
          toggleModal({
            name: Modals.ADD_DISPOSITION,
            data: null,
            isOpen: true,
          });
        }}
        className="text-white inline-flex gap-2 items-center bg-app-blue-light hover:bg-opacity-90 focus:ring-2 focus:outline-none focus:ring-app-blue-lighter font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        disabled={!isActionsAvailable}
      >
        <FA icon={faPlus} size="lg" />
        Add disposition
      </button>

      <button
        ref={saveButtonRef}
        type="button"
        onClick={() => {
          void saveMappings();
        }}
        disabled={!hasMappingsChanged}
        className="disabled disabled:bg-opacity-50 disabled:cursor-not-allowed text-white inline-flex gap-2 items-center bg-app-blue-light hover:bg-opacity-90 focus:ring-2 focus:outline-none focus:ring-app-blue-lighter font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
        Save
      </button>
    </div>
  ) : (
    <></>
  );
};

export default MappingActions;

import routes from "../../constants/routes";
import axios from "axios";
import { Contact } from "../../components/shared/Contact";

type Props = {
  contactId: string;
  notes?: string;
  notesAgent?: string;
  notesSDR?: string;
};

type ResponseProps = {
  success?: boolean;
  error?: { message: string };
};

const updateContactNotesAsync = async ({
  contactId,
  notes,
  notesAgent,
  notesSDR,
}: Props): Promise<ResponseProps> => {
  try {
    await axios.put<Contact>(
      routes.put.contacts.updateNotes + `/${contactId}`,
      { notes, notesAgent, notesSDR }
    );
    return { success: true };
  } catch (e) {
    return { error: { message: "Error updating note, try again later" } };
  }
};

export default updateContactNotesAsync;

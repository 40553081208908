import axios from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import routes from '../../../../../src/constants/routes';
import { type Contact } from './entities/contact.entity';
import { type SelectedFilters } from '@/src-v2/Domain/interfaces/Contacts/filter.interfaces';

const getContactsByCallSessionIds = async (
  userId: string,
  callSessionIds: string[],
  filters: SelectedFilters,
): Promise<ApiResponse<Contact[]>> => {
  try {
    const parsedFilters = JSON.stringify(filters);

    const callSession = await axios.get<Contact[]>(`${routes.get.contacts.byCallSessionIds}/${userId}`, {
      params: { filters: parsedFilters, callSessionIds },
    });

    return { data: callSession.data };
  } catch {
    return { error: { message: 'Error getting contacts by call session id' } };
  }
};

export default getContactsByCallSessionIds;

import styled, { css, useTheme } from "styled-components";
import { Link } from "react-router-dom";
import { outreachColor, hubspotButtonColor, colors, statusColors } from "../theme";

import {
  desktopWidth,
  sidebarWidth,
  headerHeight,
  defaultShadow,
} from "../../constants/settings";
import { backgroundGradient, minMediaWidth } from "../../constants/mixins";
import { ColorProp, SizeProp } from "./types";


const {
  lighterBlue,
  lightBlue,
  midBlue,
  darkBlue,
  lightGray,
  danger,
  dangerDark,
} = colors;

const UIMain = styled.div`
  min-height: 100vh;
  display: grid;
  transition: 0.5s;
  grid-template-rows: ${headerHeight}px 1fr;
  &.sidebar {
    grid-template-areas: "header header" "sidenav content";
    &.open {
      grid-template-columns: ${sidebarWidth}px 1fr;
    }
    &.closed {
      grid-template-columns: 58px 1fr;
    }
  }
  &:not(.sidebar) {
    grid-template-columns: 100%;
    grid-template-areas: "header" "content";
  }
`;

const UIHeader = styled.div`
  z-index: 1;
  grid-area: header;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-left: 2em;
  padding-right: 2em;
  font-size: 1.2rem;
  background: white;
  color: #0098d8;
  box-shadow: ${defaultShadow};
  & span {
    font-weight: 800;
  }
`;

const UISidebar = styled.div`
  overflow: hidden;
  grid-area: sidenav;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  gap: 1rem;
  background-color: ${lightBlue};
  color: white;
`;

const UISidebarNav = styled.nav`
  width: 100%;
  & ul {
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    font-weight: 600;
  }
`;

const UISidebarBottom = styled.div`
  width: 100%;
  align-self: stretch;
  display: grid;
  grid-template-columns: 100%;
  justify-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
  margin-top: auto;
`;

const UILink = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75em;
  box-sizing: border-box;
  gap: 0.75em;
  text-decoration: none;
  color: inherit;
  transition: 0.25s;
  &.selected {
    color: ${lightBlue};
    background-color: white;
  }
  &:hover,
  &:focus {
    background-color: white;
    color: ${midBlue};
    .open & {
      padding-left: 1.25em;
    }
  }
  & svg {
    width: 1.5em;
    height: 1.5em;
  }
  &.hidden {
    display: none;
  }
`;

const UIContainer = styled.div<{
  isSettings: boolean | undefined;
  tabsIsEmpty: boolean | undefined;
}>`
  position: relative;
  grid-area: content;
  display: flex;
  flex-direction: row;
  align-items: ${(props) =>
    props.isSettings && !props.tabsIsEmpty ? "top" : "center"};
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  ${() => {
    return `
      color: ${statusColors.colorText};
      background-color: ${statusColors.bg};
      ${backgroundGradient(
        [statusColors.primary, statusColors.secondary],
        "to right"
      )}
    `;
  }}
  ${() => minMediaWidth(desktopWidth, "gap: 2rem; padding: 2rem;")}
`;

const UIColumn = styled.div<{
  maxWidth?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  gap: 1rem;
  padding: 1rem 0;
`;

const UIBurgerButton = styled.button`
  border: 1px solid ${lightBlue};
  height: 48px;
  width: 48px;
  border-radius: 0.5em;
  font-size: 1.25rem;
  padding: 0;
  background: none;
  color: ${lightBlue};
`;

const ButtonBase = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border: none;
  border-radius: 0.5em;
  color: white;
  background-color: ${lighterBlue};
  text-align: center;
  padding: 0.5em 0.75em;
  cursor: pointer;
  &:disabled {
    background-color: ${colors.darkGray};
    cursor: initial;
  }
  &.outreach:not(:disabled) {
    background-color: ${outreachColor};
    color: white;
  }
  &.hubspot:not(:disabled) {
    background: ${hubspotButtonColor};
  }
`;

const UIButton = styled(ButtonBase)<{
  color?: ColorProp;
  size?: SizeProp;
  visible?: string;
  disabledBtn?: boolean;
  showWhen?: boolean;
  width?: string;
}>`
  width: ${({ width }) => (width ? width : "auto")};
  display: ${({ showWhen }) =>
    showWhen !== undefined && (showWhen ? "auto" : "none")};
  font-size: 1.25rem;
  transition: 0.25s;
  background-color: ${lightBlue};
  &:not(:disabled) {
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: ${darkBlue};
    }
  }
  & > img {
    display: block;
    width: 1.2em;
    height: 1.2em;
  }

  ${({ color }) => {
    switch (color) {
      case "secondary":
        return css`
          background-color: darkgray;
          color: white;
          &:not(:disabled) {
            cursor: pointer;
            &:hover,
            &:focus {
              background-color: gray;
            }
          }
        `;
      case "danger":
        return css`
          background-color: ${danger};
          &:not(:disabled) {
            cursor: pointer;
            &:hover,
            &:focus {
              background-color: ${dangerDark};
            }
          }
        `;
    }
  }}

  ${({ size }) => {
    switch (size) {
      case "sm":
        return css`
          font-size: 1rem;
          padding: 0.4em 0.6em;
        `;
      case "md":
        return css`
          font-size: 1.45rem;
          padding: 0.6em 0.9em;
        `;
      case "lg":
        return css`
          font-size: 1.65rem;
          padding: 0.7em 1em;
        `;
      case "xl":
        return css`
          font-size: 1.75rem;
          padding: 0.8em 1.1em;
        `;
    }
  }}
`;

const UIIconButton = styled.button`
  border: none;
  font-size: 1em;
  padding: 0;
  color: inherit;
  background-color: transparent;
  margin-left: auto;
  cursor: pointer;
`;

const UIMiniButton = styled(ButtonBase)`
  font-size: 1rem;
`;

const UITitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 400;
  color: ${lightBlue};
  border-bottom: 2px solid;
  margin: 0 0 0.25em;
  padding-bottom: 0.25em;
`;
const UISection = styled.section``;

const UIText = styled.div`
  font-size: 1rem;
  line-height: 1.325;
  & + & {
    margin-top: 0.5em;
  }
`;

const UIInputText = styled.input`
  width: 100%;
  color: inherit;
  border: 1px solid ${lightGray};
  border-radius: 0.25rem;
  padding: 0.5em;
  font-size: 1.125rem;
`;

const UITextArea = styled.textarea`
  width: 100%;
  max-width: 100%;
  color: inherit;
  border: 1px solid ${lightGray};
  border-radius: 0.25rem;
  padding: 0.5em;
  font-size: 1.125rem;
  min-height: 5em;
  resize: none;
`;

const UIHorizontalDivider = styled.div`
  margin: 1rem 0;
  width: 100%;
  border-bottom: 1px solid #ccc;
`;

export {
  UIMain,
  UIHeader,
  UIBurgerButton,
  UISidebar,
  UISidebarNav,
  UISidebarBottom,
  UILink,
  UIContainer,
  UIColumn,
  UIButton,
  UIIconButton,
  UIMiniButton,
  UIInputText,
  UITextArea,
  UITitle,
  UISection,
  UIText,
  UIHorizontalDivider,
};

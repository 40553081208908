import styled from "styled-components";
import React, { ReactElement } from "react";
import { SummaryData } from "./SummaryContainer";

interface SummaryItemProps {
  dataItem: SummaryData;
}
const SummaryItem = ({ dataItem }: SummaryItemProps): ReactElement => {
  if (!dataItem) return <></>;
  return (
    <Item>
      <img
        width={"30px"}
        src={dataItem.icon}
        alt="icon"
        style={{
          margin: "5px",
          fill: "#A6A8AA",
          gridColumn: 1,
          alignSelf: "center",
        }}
      />
      <div>
        <ItemText>{dataItem.text}</ItemText>
        <ItemValue>{dataItem.value}</ItemValue>
      </div>
    </Item>
  );
};

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  padding: 0.75rem 1rem;
  align-content: center;
  border-radius: 0.25rem;
  min-width: 200px;
  max-width: 200px;
  box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff;
`;

const ItemText = styled.p`
  max-width: 100px;
  font-size: 0.7rem;
`;

const ItemValue = styled.p`
  font-weight: 500;
  font-size: 1.25rem;
  padding: 0.5rem 0 0 0;
  font-variant-numeric: tabular-nums;

  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
`;

export default SummaryItem;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../../core/theme";

const { colors } = theme;

const daysOfWeek: DayOfWeek[] = [
  { value: "Sunday", label: "Su" },
  { value: "Monday", label: "Mo" },
  { value: "Tuesday", label: "Tu" },
  { value: "Wednesday", label: "We" },
  { value: "Thursday", label: "Th" },
  { value: "Friday", label: "Fr" },
  { value: "Saturday", label: "Sa" },
];

type DayOfWeek = {
  value: string;
  label: string;
};

type WeekdaySelectorProps = {
  selectedDays: string[];
  onDaySelected: (days: string[]) => void;
};

const WeekdaysSelector = (props: WeekdaySelectorProps): JSX.Element => {
  const { selectedDays, onDaySelected } = props;
  const [selected, setSelected] = useState(selectedDays);

  useEffect(() => {
    setSelected(selectedDays);
  }, [selectedDays]);

  const toggleSelectedDay = (day: string) => {
    const index = selected.indexOf(day);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(day);
    }
    setSelected([...selected]);
    onDaySelected(selected);
  };

  return (
    <>
      <WeekdaySelectorContainer id={"daysOfWeek"}>
        {daysOfWeek.map((day) => (
          <DayOfWeek
            data-qa-id={"btn_" + day.value}
            key={day.value}
            role="checkbox"
            aria-checked={selected.indexOf(day.value) > -1}
            tabIndex={0}
            onKeyPress={(e) => {
              e.preventDefault();
              if (e.key === "Enter" || e.key === " ") {
                toggleSelectedDay(day.value);
              }
            }}
            onClick={() => toggleSelectedDay(day.value)}
            isSelected={selected.indexOf(day.value) > -1}
          >
            {day.label}
          </DayOfWeek>
        ))}
      </WeekdaySelectorContainer>
    </>
  );
};

const WeekdaySelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const DayOfWeek = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1px solid ${colors.lightGray};
  cursor: pointer;
  margin: 0 0.25rem;
  font-size: 1.125rem;
  font-weight: ${({ isSelected }) => (isSelected ? "bold" : "normal")};
  color: ${({ isSelected }) => (isSelected ? colors.white : "inherit")};
  background-color: ${({ isSelected }) =>
    isSelected ? colors.lightBlue : "inherit"};

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? colors.lightBlue : "#e9ecef"};
  }
  &:focus {
    box-shadow: 0 0 0 2px ${colors.lightBlue};
  }
`;

export default WeekdaysSelector;

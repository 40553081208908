import axios from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import routes from '../../../../../src/constants/routes';
import {
  type OutreachCallDispositions,
  type OutreachCallDispositionsResponseDto,
} from './dto/getOutreachCustomDispositions.dto';

const getOutreachCustomDispositions = async (
  organizationId: string,
  nonce: string,
): Promise<ApiResponse<OutreachCallDispositions[]>> => {
  try {
    const url = `${routes.get.outreach.getCustomDispositions}/${organizationId}/${nonce}`;
    const mappings = await axios.get<OutreachCallDispositionsResponseDto>(url);

    return {
      success: true,
      data: mappings.data.data,
    };
  } catch (e: unknown) {
    return {
      success: false,
      error: {
        message:
          'An error occurred while retrieving outreach mappings. If the issue persists, please contact customer support.',
      },
    };
  }
};

export default getOutreachCustomDispositions;

import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";

type TimeAgoProps = {
  date?: Date | string;
  timezone?: string;
};

type TimeAgoReturn = {
  timeAgo: string;
  updateTimeAgoDate: (date: Date) => void;
};

function useTimeAgo(timeAgoProps?: TimeAgoProps): TimeAgoReturn {
  const { date: initialDate, timezone = "UTC" } = timeAgoProps || {};

  const [timeAgo, setTimeAgo] = useState("");
  const [date, setDate] = useState(initialDate && new Date(initialDate));
  const [showTimeAgo, setShowTimeAgo] = useState(true);

  const getFormattedDate = (duration: moment.Duration): string => {
    if (duration.asMinutes() < 1) {
      return `${duration.seconds()} sec. ago`;
    } else if (duration.asHours() < 1) {
      return `${duration.minutes()} min. ago`;
    } else if (duration.asDays() < 1) {
      return `${duration.hours()} hrs. ago`;
    } else {
      return `${duration.days()} days ago`;
    }
  };

  const updateTimeAgoDate = useCallback((date: Date) => {
    setDate(date);
    setShowTimeAgo(false); // hide timeAgo when date is updated
  }, []);

  useEffect(() => {
    if (!date) return;

    const interval = setInterval(() => {
      const now = moment.tz(timezone);
      const delta = now.diff(moment.tz(date, timezone));
      const duration = moment.duration(delta);
      const formattedDate = getFormattedDate(duration);

      setTimeAgo(formattedDate);
      setShowTimeAgo(true);
    }, 1000);

    return () => clearInterval(interval);
  }, [date, timezone]);

  return {
    timeAgo: showTimeAgo ? timeAgo : "",
    updateTimeAgoDate,
  };
}

export default useTimeAgo;

import { type ApiResponse } from '@/src/types/ApiResponse';
import { type SafeUserDto } from '../../../DataSource/edge/users/dto/safeUser.dto';

import uploadImageAsync from '../../../DataSource/edge/users/updateUserAccount';
import { type UploadImageDto } from '../../../DataSource/edge/users/dto/uploadImage.dto';
import getUserAsync from '../../../DataSource/edge/users/getUserAsync';
import { type UpdateUserDto } from '@/src-v2/Data/DataSource/edge/users/dto/updateUserDto';
import updateUserAsync from '@/src-v2/Data/DataSource/edge/users/updateUserAsync';

interface UsersRepo {
  uploadProfilePicture: (userId: string, profilePicture: UploadImageDto) => Promise<ApiResponse<SafeUserDto>>;
  getUserAsync: (userId: string) => Promise<ApiResponse<SafeUserDto>>;
  updateUserAsync: (userData: UpdateUserDto) => Promise<ApiResponse<SafeUserDto>>;
}

export function UsersRepository(): UsersRepo {
  return {
    async uploadProfilePicture(userId: string, profilePicture: UploadImageDto) {
      const { data, success, error } = await uploadImageAsync(userId, profilePicture);
      if (data === null || error !== null) {
        return { error, success };
      }
      return { data, success: true };
    },
    async getUserAsync(userId: string) {
      const { data, success, error } = await getUserAsync(userId);
      if (data === null || data === undefined) {
        return { error, success };
      }
      return { data, success: true };
    },
    async updateUserAsync(userData: UpdateUserDto) {
      const { data, success, error } = await updateUserAsync(userData);
      if (data === null || data === undefined) {
        return { error, success };
      }
      return { data, success: true };
    },
  };
}

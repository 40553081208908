import { LoadingState } from '../../../../../../../src/types/LoadingStates.type';

import {
  type Disposition,
  DispositionAbbreviation,
  type DispositionMap,
  type DispositionRecord,
  type Outcome,
} from '../../../../types/disposition-mapping.type';
import { type CallDisposition } from '../../../../../../Data/DataSource/sync/hubspot-mappings/dto/call-disposition.dto';
import { useContext, useEffect, useState } from 'react';
import { UserProviderContext } from '../../../../../../../src/components/app/UserProvider';
import { type Account } from '../../../../../../Data/DataSource/sync/hubspot/entities/account.entity';
import { type OutreachAccount } from '../../../../../../Data/DataSource/sync/outreach/dto/get-outreach-accounts.dto';
import { showUIToast, UI_TOAST_TYPES } from '../../../../../../../src/core/ui/UIToast';
import { HubspotUseCase } from '../../../../../../Domain/UseCase/hubspot/hubspotUseCase';
import HubspotMappingsUseCase from '../../../../../../Domain/UseCase/hubspot-mappings/hubspotMappingsUseCase';
import { type SaveHubspotMappingDto } from '../../../../../../Data/DataSource/sync/hubspot-mappings/dto/save-hubspot-mapping.dto';
import { featuresStore } from '../../../../../../../src/components/app/FeaturesProvider';

const { IDLE, LOADING, ERROR, SUCCESS } = LoadingState;

export interface HubspotMappingsViewmodel {
  fetchMappings: () => Promise<void>;
  fetchCallDispositions: () => Promise<CallDisposition[]>;
  saveMappings: () => Promise<void>;
  addCallDisposition: () => void;
  selectDisposition: (selected: Disposition, customDisposition: CallDisposition) => void;
  mappings: Disposition[];
  customDispositions: CallDisposition[];
  loadingState: HubspotMappingsLoadingState;
  viewState: ViewState;
}

interface HubspotMappingsLoadingState {
  getMappings: LoadingState;
  getCallDispositions: LoadingState;
  saveMappings: LoadingState;
  getAccounts: LoadingState;
  addCallDisposition: LoadingState;
}

interface ViewState {
  isHubspotAccount: boolean;
  isHubspotCustomFeature: boolean;
  isHubspotFeature: boolean;
  hasMappingsChanged: boolean;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isTableLoading: boolean;
}
export default function HubspotMappingsViewModel(): HubspotMappingsViewmodel {
  const { getHubspotAccounts } = HubspotUseCase();

  const {
    getHubspotMappings,
    getHubspotCallDispositions,
    saveHubspotMapping,
    getTransformedMappings,
    isCustomDisposition,
  } = HubspotMappingsUseCase();

  const {
    currentUser: { isAdmin, isSuperAdmin, ...currentUser },
  } = useContext(UserProviderContext);

  const { featuresState } = useContext(featuresStore);

  const [loadingState, setLoadingState] = useState<HubspotMappingsLoadingState>({
    getMappings: IDLE,
    getCallDispositions: IDLE,
    saveMappings: IDLE,
    getAccounts: IDLE,
    addCallDisposition: IDLE,
  });

  const [viewState, setViewState] = useState<ViewState>({
    isHubspotAccount: false,
    isHubspotCustomFeature: featuresState.hubspotCustom,
    isHubspotFeature: featuresState.hubspot,
    hasMappingsChanged: false,
    isSuperAdmin,
    isAdmin,
    isTableLoading: false,
  });

  const [mappings, setMappings] = useState<Disposition[]>([]);

  const [customDispositions, setCustomDispositions] = useState<CallDisposition[]>([]);

  const [hubspotAccounts, setHubspotAccounts] = useState<Account[]>([]);

  const fetchMappings = async (): Promise<void> => {
    setLoadingState((prevState) => ({
      ...prevState,
      getMappings: LOADING,
    }));

    const organizationId = currentUser?.organizationId;

    const { data, error, success } = await getHubspotMappings(organizationId);

    const callDispositions = customDispositions.length > 0 ? customDispositions : await fetchCallDispositions();

    if (data === undefined || error !== undefined || success === false) {
      setLoadingState({ ...loadingState, getMappings: ERROR });
      return;
    }

    const { mapping, id } = data;

    const transformedMapping: Disposition[] = getTransformedMappings(
      JSON.parse(mapping) as DispositionRecord,
      callDispositions,
      id,
    );

    setMappings(transformedMapping);

    setLoadingState((prevState) => ({
      ...prevState,
      getMappings: SUCCESS,
    }));
  };

  const fetchCallDispositions = async (): Promise<CallDisposition[]> => {
    setLoadingState((prevState) => ({
      ...prevState,
      getCallDispositions: LOADING,
    }));
    const organizationId = currentUser?.organizationId;
    const nonce = hubspotAccounts[0]?.nonce ?? (await fetchAccounts().then((accounts) => accounts[0]?.nonce));

    if (nonce === undefined) return [];

    const { data: callDispositions, error } = await getHubspotCallDispositions(organizationId, nonce);

    if (callDispositions === undefined || error !== undefined) {
      setLoadingState((prevState) => ({
        ...prevState,
        getCallDispositions: ERROR,
      }));

      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Error while retrieving Hubspot call dispositions.',
      });

      setCustomDispositions([]);

      return [];
    }

    setCustomDispositions(callDispositions);

    setLoadingState((prevState) => ({
      ...prevState,
      getCallDispositions: SUCCESS,
    }));

    return callDispositions;
  };

  async function fetchAccounts(): Promise<OutreachAccount[]> {
    setLoadingState({ ...loadingState, getAccounts: LOADING });
    const organizationId = currentUser?.organizationId;
    const userId = currentUser?.userId;
    const { data, success, error } = await getHubspotAccounts(userId, organizationId);

    if (data === undefined || success === false || error !== undefined) {
      setLoadingState({ ...loadingState, getAccounts: ERROR });
      setViewState({
        ...viewState,
        isHubspotAccount: false,
      });
      showUIToast({
        type: UI_TOAST_TYPES.WARNING,
        text: 'You have to connect at least one Hubspot account to use this feature.',
      });
      setHubspotAccounts([]);
      return [];
    }

    setViewState({
      ...viewState,
      isHubspotAccount: Boolean(data.length),
    });

    setHubspotAccounts(data);

    setLoadingState({
      ...loadingState,
      getAccounts: SUCCESS,
    });

    return data;
  }

  function selectDisposition(selected: Disposition, customDisposition: CallDisposition): void {
    const {
      id,
      attributes: { name, outcome },
    } = customDisposition;
    const customSelected = customDispositions.find((d) => d.id === id);

    if (customSelected == null) {
      console.log('No custom disposition found');
      return;
    }

    setMappings((prev) => [
      ...prev.map((d) => {
        if (selected.abbreviation !== d.abbreviation) return d;
        setViewState({
          ...viewState,
          hasMappingsChanged: true,
        });
        return {
          ...d,
          id,
          customName: name,
          isCustom: isCustomDisposition(name),
          outcome: outcome as Outcome,
        };
      }),
    ]);
  }

  async function saveMappings(): Promise<void> {
    setLoadingState({
      ...loadingState,
      saveMappings: LOADING,
    });

    const dispositionId = mappings[0]?.dispositionId;
    const cleanMappings: DispositionMap = new Map();
    const patchMappings: SaveHubspotMappingDto = { mapping: '' };

    for (const disposition of mappings) {
      cleanMappings.set(DispositionAbbreviation[disposition.abbreviation], disposition.customName);
    }

    patchMappings.mapping = JSON.stringify(Object.fromEntries(cleanMappings));

    const { error, success } = await saveHubspotMapping(dispositionId, patchMappings);

    if (error !== undefined || success === false) {
      setLoadingState((prev) => ({
        ...prev,
        saveMappings: ERROR,
      }));

      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Error while saving Hubspot mappings.',
      });

      return;
    }

    showUIToast({
      type: UI_TOAST_TYPES.SUCCESS,
      text: 'Hubspot mappings saved.',
    });

    setViewState((prev) => ({
      ...prev,
      hasMappingsChanged: false,
    }));

    setLoadingState({
      ...loadingState,
      saveMappings: SUCCESS,
    });
  }

  function addCallDisposition(): void {
    const url = 'https://knowledge.hubspot.com/calling/create-custom-call-and-meeting-outcomes';
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  useEffect(() => {
    if (loadingState.getAccounts !== LOADING) {
      void fetchAccounts().finally();
    }
    if (loadingState.getCallDispositions !== LOADING) {
      void fetchCallDispositions().finally();
    }
    if (loadingState.getMappings !== LOADING) {
      void fetchMappings().finally();
    }
  }, []);

  useEffect(() => {
    const isTableLoading =
      loadingState.getAccounts === LOADING ||
      loadingState.getCallDispositions === LOADING ||
      loadingState.getMappings === LOADING;

    setViewState((prev) => ({
      ...prev,
      isTableLoading,
    }));
  }, [loadingState]);

  return {
    fetchMappings,
    fetchCallDispositions,
    mappings,
    customDispositions,
    loadingState,
    viewState,
    selectDisposition,
    saveMappings,
    addCallDisposition,
  };
}

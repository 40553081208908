import { type OutreachMappingDto } from '../../../../DataSource/sync/outreach-mappings/dto/get-outreach-mappings.dto';
import { type GetOutreachMappingsRepository } from '../outreachMappingsRepository';
import { type DispositionRecord } from '../../../../../Presentation/Views/types/disposition-mapping.type';

export function transformOutreachMappings(dto: OutreachMappingDto): GetOutreachMappingsRepository {
  return {
    ...dto,
    mapping: JSON.parse(dto.mapping) as DispositionRecord,
  };
}

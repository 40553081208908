import React, { useState, useContext, useRef, ReactElement } from "react";
import styled from "styled-components";
import { BaseInput, BaseLabel } from "../../../core/ui/base/FormElements";
import { UI_TOAST_TYPES, showUIToast } from "../../../core/ui/UIToast";
import updateUserAsync from "../../../api/Users/updateUserAsync";
import { User } from "../../../types";
import { UserProviderContext } from "../../../components/app/UserProvider";
import {
  UIButton,
  UIHorizontalDivider,
  UIIconButton,
} from "../../../core/ui/UIElements";
import { ImgAvatar } from "../../../components/NavBar/NavBarElements";
import Avatar from "../../../../public/assets/icons/avatar.svg";
import CenteredForm from "./CenteredForm";
import TimezoneDropdown from "./components/TimezoneDropdown.component";
import { Timezone } from "../../../types/TimezoneTypes";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../../../core/theme";
import { motion } from 'framer-motion';
import animations from '../../../core/ui/base/Animations';




const LIGHT_BLUE_APP_COLOR = theme.colors.lightBlue;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 60%;
  margin-top: 2rem;
`;

const Form = styled.form<{
  visible: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

const EditContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 12px;
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: flex-end;
  margin-left: 2rem;
  align-items: flex-end;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem;
  width: 100%;
`;

const Label = styled(BaseLabel)`
  margin: 0 0.5rem 0 0;
  font-size: 1.25rem;
  line-height: 1.5rem;

  span {
    vertical-align: sub;
  }
`;

const ActionButton = styled(UIButton)`
  align-self: flex-end;
  margin-left: 1rem;
  margin-bottom: 2px;
`;

const ProfileImage = styled.div`
  width: 150px;
  height: 200px;
  border-radius: 50%;
  margin-right: 10px;
`;

const AccountSetting = (): ReactElement => {
  const { currentUser, setCurrentUser } = useContext(UserProviderContext);

  const [showPasswordButton, setShowPasswordButton] = useState(true);

  const [isEditable, setIsEditable] = useState(false);

  const timezoneSelectRef = useRef<HTMLSelectElement>(null);

  const [isTimezoneEditable, setIsTimezoneEditable] = useState(false);

  const [enableActionButton, setEnableActionButton] = useState(false);

  const [userData, setUserData] = useState({
    id: currentUser.userId,
    email: currentUser.email,
    name: currentUser.userName,
    originalName: currentUser.userName,
    originalTimezone: currentUser.timezone,
    timezone: currentUser.timezone,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const showMessage = (type: UI_TOAST_TYPES, message: string) => {
    showUIToast({
      type: type,
      text: message,
    });
  };

  const handlePasswordButtonClick = async (e) => {
    e.preventDefault();

    setShowPasswordButton(!showPasswordButton);

    setUserData({ ...userData, name: userData.originalName });
  };

  const handleUserDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { name, value } = event.target;

    if (name === "name") {
      if (userData.originalName !== value) {
        setEnableActionButton(true);
      } else {
        setEnableActionButton(false);
      }
    } else if (name === "timezone") {
      if (userData.originalTimezone !== value) {
        setIsTimezoneEditable(true);

        setIsEditable(false);

        setEnableActionButton(true);
      } else {
        setEnableActionButton(false);
      }
    }
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmitSaveBtn = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    const button = event.target as HTMLButtonElement;

    const textButton = button.textContent;

    const { userId, email, organizationId, type, sdrId } = currentUser;

    const updateUser: User = {
      id: userId,
      _id: userId,
      email: email,
      name: userData.name,
      organizationId: organizationId,
      userType: type,
      sdrId: sdrId,
      timezone: userData.timezone as Timezone,
    };

    const { error } = await updateUserAsync(updateUser);

    if (error) {
      showMessage(UI_TOAST_TYPES.ERROR, error.message);

      throw new Error(error.message);
    }

    showMessage(
      UI_TOAST_TYPES.SUCCESS,
      `${
        textButton === "Save" ? "Username" : "Timezone"
      } has been changed successfully.`
    );

    const updatedUser = {
      ...currentUser,
      userName: userData.name,
      timezone: userData.timezone,
    };

    localStorage.setItem("user", JSON.stringify(updatedUser));

    setCurrentUser(updatedUser);

    setShowPasswordButton(true);

    setUserData({
      ...userData,
      name: updateUser.name,
      originalName: updateUser.name,
      timezone: updateUser.timezone,
      originalTimezone: updateUser.timezone,
    });

    setEnableActionButton(!enableActionButton);

    setIsEditable(false);

    setIsTimezoneEditable(false);
  };

  const handleEdit = (e) => {
    e.preventDefault();

    setIsEditable(!isEditable);

    setIsTimezoneEditable(false);

    resetForm();

    !showPasswordButton && setShowPasswordButton(!showPasswordButton);
  };

  const handleCancel = (e) => {
    e.preventDefault();

    resetForm();

    setIsEditable(false);

    setIsTimezoneEditable(false);
  };

  const resetForm = () => {
    if (timezoneSelectRef.current) {
      timezoneSelectRef.current?.blur();

      timezoneSelectRef.current.value = currentUser.timezone;

      timezoneSelectRef.current?.focus();
    }

    setUserData({ ...userData, name: userData.originalName });

    setIsTimezoneEditable(false);
  };

  return (
    <Container {...animations.fadeInFromTop}>
      <FormContainer>
        <ProfileImage>
          <ImgAvatar src={Avatar} alt="Avatar" />
        </ProfileImage>
        <Form visible="true">
          <LabelContainer>
            <Label>Email:</Label>
            <Label>{userData.email}</Label>
          </LabelContainer>
          <EditContainer>
            <LabelContainer>
              <Label>Name:</Label>
              {!isEditable ? (
                <Label>{userData.name}</Label>
              ) : (
                <BaseInput
                  type="text"
                  name="name"
                  value={userData.name}
                  data-qa-id={"inputUserName"}
                  onChange={(e) => handleUserDataChange(e)}
                />
              )}
              {!isEditable && (
                <UIIconButton
                  data-qa-id={"editUserName"}
                  onClick={(e) => handleEdit(e)}
                >
                  <FA
                    icon={faPenToSquare}
                    size="xl"
                    color={LIGHT_BLUE_APP_COLOR}
                  />
                </UIIconButton>
              )}
            </LabelContainer>
          </EditContainer>
        </Form>
      </FormContainer>
      <FormContainer>
        <Form visible="true">
          {!isEditable && (
            <EditContainer>
              <LabelContainer>
                <Label>
                  Password: <span>***********</span>
                </Label>
                <UIIconButton
                  data-qa-id={"editUserPassword"}
                  onClick={(e) => handlePasswordButtonClick(e)}
                >
                  <FA
                    icon={faPenToSquare}
                    size="xl"
                    color={LIGHT_BLUE_APP_COLOR}
                  />
                </UIIconButton>
              </LabelContainer>
            </EditContainer>
          )}
        </Form>
      </FormContainer>
      {!showPasswordButton && (
        <CenteredForm
          userData={userData}
          setUserData={setUserData}
          setShowPasswordButton={setShowPasswordButton}
          showMessage={showMessage}
          handleUserDataChange={handleUserDataChange}
        />
      )}

      {(isEditable || isTimezoneEditable) && (
        <FlexContainer>
          <ActionButton
            data-qa-id={`${
              isTimezoneEditable ? "cancelEditTimezone" : "cancelEditUser"
            }`}
            onClick={(e) => handleCancel(e)}
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={(e) => handleSubmitSaveBtn(e)}
            disabled={!enableActionButton}
            disabledBtn={!enableActionButton}
            data-qa-id={`${
              isTimezoneEditable ? "saveEditTimezone" : "saveEditUser"
            }`}
          >
            {isTimezoneEditable ? "Update Timezone" : "Save"}
          </ActionButton>
        </FlexContainer>
      )}

      <UIHorizontalDivider />

      <TimezoneDropdown
        timezoneSelectRef={timezoneSelectRef}
        handleUserDataChange={handleUserDataChange}
        currentUser={currentUser}
      />
    </Container>
  );
};

export default AccountSetting;

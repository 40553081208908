import React, { type ButtonHTMLAttributes, type ReactElement } from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

interface ButtonWithIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  isRedirection?: boolean;
  children?: ReactElement;
}

export default function ButtonLink({
  text = 'Go to settings',
  children,
  isRedirection,
  ...buttonProps
}: ButtonWithIconProps): ReactElement<HTMLButtonElement> {
  return (
    <button
      type="button"
      name={text}
      className="text-app-blue-light inline-flex gap-2 items-center underline underline-offset-4 hover:no-underline hover:ring-1 ring-app-blue-light focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      {...buttonProps}
    >
      {isRedirection === true ? (
        <>
          {text}
          <FA icon={faArrowUpRightFromSquare} size="lg" />
        </>
      ) : (
        <>
          {text}
          {children}
        </>
      )}
    </button>
  );
}

import React, {useContext, useEffect, useState} from 'react';
import { Outlet, useNavigate } from 'react-router-dom'
import EnvironmentWatermark from "./app/EnvironmentWatermark";
import { showUIToast } from "../core/ui/UIToast";
import { featuresStore } from "./app/FeaturesProvider";
import ThemeProviderWrapper from "./app/ThemeProviderWrapper";
import { setUser, setContext, setTags } from '@sentry/react'
import constants from "../constants.json";
import { Device } from "twilio-client";
import {PublicRoutes} from "../constants/routesConnect";
import logout from "../api/Auth/logout";
import axios from "axios";
import {UserProviderContext} from "./app/UserProvider";
import {CallSessionSuccessResponse} from "./shared/CallSession";
import { AppContext } from '../libs/contextLib';
import { isTokenExpired } from "../core/utils/sessionRefresher";
import * as Sentry from "@sentry/react";
import { CurrentUser } from '../types';
import TimeZoneProvider from '../core/utils/timezone';
import VersionWatermark from "./app/VersionWatermark";
const window = globalThis as any;

const RoutesLayout = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { clearCurrentUser, setCurrentUser } = useContext(UserProviderContext);
    const [twilioDevice, setTwilioDevice] = useState<Device>(new Device());
    const { fetchFeatures } = useContext(featuresStore);
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const [currentCallSession, setCurrentCallSession] = useState<
        CallSessionSuccessResponse | null | undefined
    >(null);
    const [intervals, setIntervals] = useState<any>({});
    const [userName, setUserName] = useState("");
    const [callSessionStatus, setCallSessionStatus] = useState<string>("");
    const navigate = useNavigate();
    const userSession: CurrentUser = JSON.parse(window.localStorage.getItem('user') || '{}');

    const userTypeAdmin = userSession?.type?.some(
        (item) => item.type === 'admin'
    );
    const userTypeSdr = userSession?.type?.some(
        (item) => item.type === 'sdr'
    );
    const userTypeAgent = userSession?.type?.some(
        (item) => item.type === 'agent'
    );
    const [versionValidation, setVersionValidation] = useState<boolean>(false);
    const [lastVersion, setLastVersion] = useState<string>("");

    useEffect( () => {
        const versionValidationFun = async () => {
            try {
                delete axios.defaults.headers.common["Authorization"];
                const getLastVersion = await axios.get('https://raw.githubusercontent.com/tendril-llc/.github/main/versions.json');
                let lastVersion = JSON.stringify(getLastVersion.data).substring(19, 24);
                setLastVersion(lastVersion);

                console.log(`latest version: ${lastVersion}`);

                let current = constants.version;
                let validation = isNewerVersion(current, lastVersion as string);
                setVersionValidation(validation);
                console.log(versionValidation)
            } catch (e) {
                console.log(e)
            }
        }
        versionValidationFun().then();
    }, []);

    const handleRoutesLogout = () => {
        // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
        Sentry.configureScope((scope) => scope.setUser(null));
        setCallSessionStatus("");
        setTwilioDevice({} as Device);
        handleLogout().then();
        setIsFirstRender(true);
    };
    const handleLogout = async () => {
        const token = window.localStorage.getItem("target");
        navigate(`/${PublicRoutes.LOGIN}`, { replace: true });
        const { error } = await logout(token as string);

        if (error) {
            showUIToast({
                type: "error",
                text: error.message,
            });
        }

        await invalidateSession();
    };

    const invalidateSession = async () => {
        window.localStorage.removeItem("target");
        window.localStorage.removeItem("user");
        clearCurrentUser();
        delete axios.defaults.headers.common["Authorization"];
    };
    useEffect(() => {
        if (userSession.type) {
            const { userId, userName, type, sdrId, email } = userSession;
            setUserName(userName);
            // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
            setUser({ email })
            // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/context/
            setContext('customContext', {
                userId,
                userName,
                type,
                sdrId,
                email
            })
            const { version, branch } = constants
            // https://docs.sentry.io/platforms/javascript/enriching-events/tags/
            // prettier-ignore
            setTags({
                userId,
                sdrId,
                version,
                branch
            })
        }

        const userTarget = window.localStorage.getItem("target") || "";
        if (userTarget !== "") {
            //validate target and prepare user session
                if (!isTokenExpired(userTarget)) {
                axios.defaults.headers.common["Authorization"] = `Bearer ${userTarget}`;
                setIsAuthenticated(true);
                setCurrentUser(userSession);
                fetchFeatures(userSession.organizationId);
            } else {
                invalidateSession().then(() =>
                    showUIToast({
                        type: "warning",
                        text: "Your session has expired, please login again.",
                    })
                );
            }
        }

    }, [userName, isAuthenticated]);

    const isNewerVersion = (oldVer: string, newVer: string) => {
        const oldParts = oldVer.split('.');
        const newParts = newVer.split('.');
        for (let i = 0; i < newParts.length; i++) {
            const a = ~~newParts[i] // parse int
            const b = ~~oldParts[i] // parse int
            if (a > b) return true
            if (a < b) return false
        }
        return false
    }

    const outletContext: OutletContextProps = {
        userSession,
        userTypeAdmin,
        userTypeSdr,
        userTypeAgent,
        twilioDevice,
        setTwilioDevice,
        handleRoutesLogout,
        isFirstRender,
        setIsFirstRender,
        currentCallSession,
        setCurrentCallSession,
        intervals,
        setIntervals,
        callSessionStatus,
        setCallSessionStatus
    };

    return (
        <>
            <TimeZoneProvider/>
            <AppContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
                <ThemeProviderWrapper>
                  <Outlet context={outletContext}/>
                </ThemeProviderWrapper>
                <EnvironmentWatermark />
                {versionValidation && <VersionWatermark lastVersion={lastVersion}/>}
            </AppContext.Provider>
        </>
    );
};

export type OutletContextProps = {
    userSession: CurrentUser;
    userTypeAdmin: boolean;
    userTypeSdr: boolean;
    userTypeAgent: boolean;
    twilioDevice: Device;
    setTwilioDevice: (device: Device) => void;
    handleRoutesLogout: () => void;
    isFirstRender: boolean;
    setIsFirstRender: (isFirstRender: boolean) => void;
    currentCallSession: CallSessionSuccessResponse | null | undefined;
    setCurrentCallSession: (session: any) => void;
    intervals: any; // TODO: replace 'any' with the actual type
    setIntervals: (intervals: any) => void;
    callSessionStatus: string; // TODO: replace 'string' with the actual type
    setCallSessionStatus: (status: string) => void;
}

export default RoutesLayout;

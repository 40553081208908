export enum SdrStatus {
  FREE = 'free',
  BUSY = 'busy',
}

export enum SessionStatus {
  STARTED = 'started',
  PAUSED = 'paused',
  FINISHED = 'finished',
}

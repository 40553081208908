import routes from "../../constants/routes";
import axios from "axios";
import { CurrentCallSessionMetrics } from "../../components/shared/CurrentCallSessionMetrics";
import getBuildMetricsAsync from "./getBuildMetricsAsync";
import getByMultipleStatusAndSdrIdAsync from "../../api/CallSession/getByMultipleStatusAndSdrIdAsync";

type CurrentSessionResponseProps = {
  currentSessionStats?: CurrentCallSessionMetrics | null;
  error?: { message: string };
};

const getCurrentSessionStats =
  async (): Promise<CurrentSessionResponseProps> => {
    try {
      const userSession = JSON.parse(
        window.localStorage.getItem("user") || "{}"
      );

      const { response, error } = await getByMultipleStatusAndSdrIdAsync({
        status: ["started", "paused"],
        sdrId: userSession.userId,
      });

      if (!!error && !response) {
        throw new Error(error.message);
      }
      let currentSessionMetrics;

      if (response?.callSession.id) {
        const { data, error } = await getBuildMetricsAsync(
          response?.callSession.id
        );
        if (error || !data) {
          return { error: error };
        }
        currentSessionMetrics = data;

        const totalContactsUrl = `${routes.get.contacts.contactsNest}/${response?.callSession.contactList[0].contactListId}`;
        const totalContactsRes = await axios.get(totalContactsUrl);

        if (totalContactsRes.data.length) {
          currentSessionMetrics.totalContacts = totalContactsRes.data.length;
        } else {
          currentSessionMetrics.totalContacts = 0;
        }
      }

      return { currentSessionStats: currentSessionMetrics };
    } catch (e) {
      return { error: { message: "Error getting current session stats" } };
    }
  };

export default getCurrentSessionStats;

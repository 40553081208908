import axios from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import routes from '../../../../../src/constants/routes';

import {
  type UpdateCustomDispositionDto,
  type UpdateCustomDispositionResponseDto,
} from './dto/update-custom-disposition.dto';

const updateCustomDisposition = async (
  organizationId: string,
  nonce: string,
  updateCustomDispositionDto: UpdateCustomDispositionDto,
): Promise<ApiResponse<UpdateCustomDispositionResponseDto>> => {
  try {
    const url = `${routes.post.outreach.updateCustomDisposition}/${organizationId}/${nonce}`;
    const mappings = await axios.post<UpdateCustomDispositionDto>(url, updateCustomDispositionDto);
    return {
      success: true,
      data: mappings.data.data,
    };
  } catch (e: unknown) {
    return {
      success: false,
      error: {
        message:
          'An error occurred while updating outreach dispositions If the issue persists, please contact customer support.',
      },
    };
  }
};

export default updateCustomDisposition;

/**
 * @description Fade in from top animation (this is the default animation for the app)
 */
export const fadeInFromTop = {
  initial: { opacity: 0 },
  animate: { y: [-20, 10, 0], opacity: 1 },
  transition: { ease: "easeIn", duration: 0.5 },
};

const animations = {
  fadeInFromTop,
};

export default animations;

import getCallSessionsBySdrId from '@datasource/contacts-nest/callSession/getCallSessionsBySdrId';
import {
  type ActiveCallSession,
  type CallSession,
} from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import { type ApiResponse } from '@/src/types/ApiResponse';
import getActiveCallSessions from '@datasource/contacts-nest/callSession/getActiveCallSessions';
import updateCallSession from '@datasource/contacts-nest/callSession/updateCallSession';
import { type UpdateCallSessionDto } from '@datasource/contacts-nest/callSession/entities/updateCallSessionDto';
import getCallSessionById from '@datasource/contacts-nest/callSession/getCallSessionById';

interface CallSessionRepo {
  getCallSessionsBySdrId: (sdrId: string, orgId: string) => Promise<ApiResponse<CallSession[]>>;
  getCallSessionById: (callSessionId: string) => Promise<ApiResponse<CallSession>>;
  getActiveCallSessions: () => Promise<ApiResponse<ActiveCallSession[]>>;
  updateCallSession: (callSessionId: string, payload: UpdateCallSessionDto) => Promise<ApiResponse<CallSession>>;
}

export function CallSessionRepository(): CallSessionRepo {
  return {
    async getCallSessionsBySdrId(sdrId: string, orgId: string) {
      const { data, error, success } = await getCallSessionsBySdrId(sdrId, orgId);

      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getCallSessionById(callSessionId: string): Promise<ApiResponse<CallSession>> {
      const { data, success, error } = await getCallSessionById(callSessionId);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getActiveCallSessions() {
      const { data, success, error } = await getActiveCallSessions();
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async updateCallSession(callSessionId: string, payload: UpdateCallSessionDto) {
      const { data, success, error } = await updateCallSession(callSessionId, payload);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}

import { type Account } from '../../../Data/DataSource/sync/hubspot/entities/account.entity';
import { type ApiResponse } from '../../../../src/types/ApiResponse';
import { HubspotRepository } from '../../../Data/Repository/sync/hubspot/hubspotRepository';

interface HubspotUseCaseProps {
  getHubspotAccounts: (userId: string, organizationId: string) => Promise<ApiResponse<Account[]>>;
}

export function HubspotUseCase(): HubspotUseCaseProps {
  const { getHubspotAccounts } = HubspotRepository();

  return {
    async getHubspotAccounts(userId: string, organizationId: string) {
      const { data, error, success } = await getHubspotAccounts(userId, organizationId);
      return { data, error, success };
    },
  };
}

import { UsersRepository } from '../../../Data/Repository/edge/users/usersRepository';
import { type UploadImageDto } from '../../../Data/DataSource/edge/users/dto/uploadImage.dto';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type SafeUserDto } from '../../../Data/DataSource/edge/users/dto/safeUser.dto';
import { type UpdateUserDto } from '@/src-v2/Data/DataSource/edge/users/dto/updateUserDto';

interface UsersUseCaseProps {
  uploadProfilePicture: (userId: string, profilePicture: UploadImageDto) => Promise<ApiResponse<SafeUserDto>>;
  getUserPictureUrl: (userId: string) => Promise<string | null | undefined>;
  getUserAsync: (userId: string) => Promise<ApiResponse<SafeUserDto>>;
  updateUserData: (userData: UpdateUserDto) => Promise<ApiResponse<SafeUserDto>>;
}

export function UsersUseCase(): UsersUseCaseProps {
  const { getUserAsync, uploadProfilePicture, updateUserAsync } = UsersRepository();
  return {
    async uploadProfilePicture(userId: string, profilePicture: UploadImageDto) {
      const { success, data, error } = await uploadProfilePicture(userId, profilePicture);
      return { success, data, error };
    },
    async getUserPictureUrl(userId: string) {
      const { data } = await getUserAsync(userId);
      return data?.profilePicture;
    },
    async getUserAsync(userId: string) {
      const { data, error } = await getUserAsync(userId);
      if (data === null) {
        return { error };
      }
      return { data };
    },
    async updateUserData(userData: UpdateUserDto) {
      const { data, success, error } = await updateUserAsync({ ...userData });
      return { data, success, error };
    },
  };
}

import React from "react";
import styled from "styled-components";
import { mask } from "../../constants/mixins";
import { theme } from "../../core/theme";

const { colors } = theme;

const HomepageColumn = styled.div<{
  gap?: string;
  width?: string;
}>`
  display: grid;
  grid-template-column: 100%;
  ${({ gap }) => gap && `gap: ${gap};`}
  ${({ width }) => width && `width: ${width};`}
`;

const StartMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Message = styled.div`
  color: inherit;
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 600;
  width: 10em;
  text-align: center;
`;

const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: none;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: none;
  background-color: white;
  color: #a6a8aa;
  box-sizing: border-box;
  padding: 0.5em;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 2em;
  cursor: pointer;
  transition: 0.25s;
  &:hover,
  &:focus {
    background-color: ${colors.lightBlue};
    color: white;
  }
  span {
    position: relative;
    z-index: 1;
  }
  ${() => {
    const rules = `
      z-index: 0;
      top: -1em;
      left: -1em;
      right: -1em;
      bottom: -1em;
      background-color: inherit;
      border-radius: inherit;
      opacity: .25;
      transition: inherit;
    `;
    return mask("before", rules);
  }}
`;

const HompageBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .right {
    text-align: right;
  }
  .list {
    list-style: disc;
    padding-left: 1em;
  }
`;

const IconTitle = styled.div`
  grid-column: span 2;
  display: inline-flex;
  gap: 0.25em;
  align-items: center;
  padding-bottom: 0.5em;
  border-bottom: 1px solid;
  margin-bottom: 0.5em;
  font-size: 1.25rem;
  font-weight: 500;
  svg {
    width: 1em;
    height: 1em;
  }
`;

const Table = styled.table<{
  maxRows?: number;
  cols: number;
}>`
  background-color: white;
  border-radius: 0.25em;
  overflow: hidden;
  padding: 0.5em;
  tr {
    display: grid;
    grid-template-columns: repeat(${({ cols }) => cols}, 1fr);
  }
  thead {
    display: block;
    background-color: ${colors.lightBlue};
    color: white;
    font-weight: 600;
    tr {
      align-items: center;
    }
  }
  tbody {
    display: block;
    color: ${colors.darkGray};
    ${({ maxRows }) =>
      maxRows &&
      `
      max-height: calc(1.8em * ${maxRows});
      overflow-y: scroll;
    `}
    tr {
      align-items: stretch;
      &:not(:last-child) {
        border-top: 1px solid ${colors.lightGray};
      }
    }
    td:not(:first-child) {
      border-left: 1px solid ${colors.lightGray};
    }
  }
  td {
    padding: 0.75em 1em;
  }
`;

type Props = {
  message?: string;
  handleClick: any;
  buttonMessage: string;
};

type TableProps = {
  tableData: {
    tableHeaders: string[];
    data: any;
  };
  maxRows?: number;
};

const HomepageIconTitle = ({ children }): JSX.Element => (
  <IconTitle>{children}</IconTitle>
);

const HomepageTable = (props: TableProps): JSX.Element => {
  const { maxRows } = props;
  const { tableHeaders, data } = props.tableData;
  return (
    <Table maxRows={maxRows} cols={tableHeaders.length}>
      <thead>
        <tr>
          {tableHeaders.map((h, i) => (
            <td key={i}>{h}</td>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((o, i) => {
          return (
            <tr key={i}>
              {o.map((r, i) => (
                <td key={i}>{r}</td>
              ))}
            </tr>
          );
        })}
        <tr></tr>
      </tbody>
    </Table>
  );
};

const HomepageMessageCircle = ({
  handleClick,
  message,
  buttonMessage,
}: Props): JSX.Element => (
  <StartMessage>
    {message && <Message>{message}</Message>}
    <CircleButton data-qa-id={"startBtn"} onClick={handleClick}>
      <span>{buttonMessage}</span>
    </CircleButton>
  </StartMessage>
);

export {
  HompageBox,
  HomepageIconTitle,
  HomepageMessageCircle,
  HomepageTable,
  HomepageColumn,
};

import FeatureFlagsRepository from '@repository/sync/feature-flags/FeatureFlagsRepository';
import type FeaturesDto from './dto/features.dto';
import { type FeatureFlag } from '@datasource/sync/feature-flags/entities/feature-flags.entity';
import { type UpdateFeatureFlagsDto } from '@datasource/sync/feature-flags/dto/update-feature-flags.dto';
import { transformOnlyFlags } from './utils/transforms';
import { toast } from '@/src-v2/Presentation/Common/Components/ui/use-toast';

interface FeatureFlagsUseCaseReturn {
  getFeatureFlags: (organizationId: string) => Promise<FeaturesDto | undefined>;
  updateFeatureFlags: (
    organizationId: string,
    updateFeatureFlagsDto: UpdateFeatureFlagsDto,
  ) => Promise<FeatureFlag | undefined>;
  utils: {
    transformOnlyFlags: typeof transformOnlyFlags;
  };
}

export default function FeatureFlagsUseCase(): FeatureFlagsUseCaseReturn {
  const { getFeatureFlags, updateFeatureFlags } = FeatureFlagsRepository();

  return {
    async getFeatureFlags(organizationId: string) {
      const featureFlags = await getFeatureFlags(organizationId);

      if (featureFlags === undefined) {
        return undefined;
      }

      return transformOnlyFlags(featureFlags);
    },
    async updateFeatureFlags(organizationId: string, updateFeatureFlagsDto: UpdateFeatureFlagsDto) {
      const updated = await updateFeatureFlags(organizationId, updateFeatureFlagsDto);

      if (updated === undefined) {
        toast({
          title: 'Oops!',
          description: 'Something went wrong while updating feature flags.',
          variant: 'destructive',
        });
        return undefined;
      }

      toast({
        title: 'Success!',
        description: 'Feature flags updated successfully.',
      });

      return updated;
    },
    utils: {
      transformOnlyFlags,
    },
  };
}

import React, { useEffect } from "react";
import styled from "styled-components";

const CountWrapper = styled.div`
  font-size: 1.5rem;
  font-weight: normal;
  color: #a6a8aa;
  margin-top: 1.3rem;
  margin-bottom: 0.3rem;
`;

const Counter = ({ remainingTTL, setRemainingTTL }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTTL((prevCount: number) => prevCount - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const seconds = remainingTTL % 60;
  const minutes = Math.floor(remainingTTL / 60);

  return (
    <CountWrapper>
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </CountWrapper>
  );
};

export default Counter;

import React, { type ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import ButtonLink from '../Buttons/ButtonLink';

interface NoLinkedAccountsProps {
  goToLink: string;
}

const NoLinkedAccounts = ({ goToLink }: NoLinkedAccountsProps): ReactElement => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-[80%] grid place-items-center">
      <div className="text-lg text-gray-500 text-center leading-relaxed">
        <FA
          icon={faInfo}
          size="1x"
          className="text-app-blue-light border-solid border-2 border-app-blue-light rounded-full px-3 py-1.5 align-middle mr-2"
        />
        <div>
          <p>There are no accounts linked to this profile. </p>
          <p>To link an account go to integration accounts.</p>
          <ButtonLink
            onClick={() => {
              navigate(goToLink);
            }}
            text="Accounts settings"
          />
        </div>
      </div>
    </div>
  );
};

export default NoLinkedAccounts;

import React, { useState, useEffect } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/src-v2/Presentation/Common/Components/ui/popover';
import { Alert, AlertDescription } from '@/src-v2/Presentation/Common/Components/ui/alert';
import { UserCog, UserRoundCheck, SlidersHorizontal, UserX, UserPlus, UserMinus } from 'lucide-react';
import { LoadingState } from '@/src/types/LoadingStates.type';
import { FilterUserType } from '../../../../Domain/enums/Users/users-filter';
interface UsersFilterProps {
  onChangeFilters: (e: any, filters: string[]) => void;
}

interface useUsersViewModelLoadingState {
  onChangeFilters: LoadingState;
}

const { IDLE, LOADING, ERROR, SUCCESS } = LoadingState;

const UsersFilter: React.FC<UsersFilterProps> = ({ onChangeFilters }) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([FilterUserType.NOT_DELETED]);
  const [loadingState, setLoadingState] = useState<useUsersViewModelLoadingState>({
    onChangeFilters: IDLE,
  });

  useEffect(() => {
    setLoadingState((prevState) => ({
      ...prevState,
      updateUsersViewStates: LOADING,
    }));
    try {
      onChangeFilters(null, selectedFilters);
      setLoadingState((prevState) => ({
        ...prevState,
        updateUsersViewStates: SUCCESS,
      }));
    } catch (error) {
      setLoadingState({ ...loadingState, onChangeFilters: ERROR });
    }
  }, [selectedFilters]);

  const handleCheckboxChange = (filter: string): void => {
    setSelectedFilters((prevSelectedFilters) => {
      if (prevSelectedFilters.includes(filter)) {
        if (prevSelectedFilters.length === 1) {
          return [FilterUserType.NOT_DELETED];
        }
        return prevSelectedFilters.filter((selectedFilter) => selectedFilter !== filter);
      } else {
        return [...prevSelectedFilters, filter];
      }
    });
  };

  return (
    <div className="flex items-center ml-4 border border-gray-200 px-4">
      <Popover>
        <PopoverTrigger>
          <div className="flex items-center cursor-pointer">
            <SlidersHorizontal size={30} style={{ color: '#c9d5df' }} className="mr-4" />
            <span style={{ color: '#c9d5df' }}>Filters</span>
          </div>
        </PopoverTrigger>
        <PopoverContent className="sm:ml-40">
          <div className="flex flex-col">
            <FilterCheckbox
              filter={FilterUserType.ADMIN}
              icon={<UserCog />}
              checked={selectedFilters.includes(FilterUserType.ADMIN)}
              onCheckedChange={() => {
                handleCheckboxChange(FilterUserType.ADMIN);
              }}
            />
            <FilterCheckbox
              filter={FilterUserType.SDR}
              icon={<UserPlus />}
              checked={selectedFilters.includes(FilterUserType.SDR)}
              onCheckedChange={() => {
                handleCheckboxChange(FilterUserType.SDR);
              }}
            />
            <FilterCheckbox
              filter={FilterUserType.AGENT}
              icon={<UserMinus />}
              checked={selectedFilters.includes(FilterUserType.AGENT)}
              onCheckedChange={() => {
                handleCheckboxChange(FilterUserType.AGENT);
              }}
            />
            <FilterCheckbox
              filter={FilterUserType.DELETED}
              icon={<UserX />}
              checked={selectedFilters.includes(FilterUserType.DELETED)}
              onCheckedChange={() => {
                handleCheckboxChange(FilterUserType.DELETED);
              }}
            />
            <FilterCheckbox
              filter={FilterUserType.NOT_DELETED}
              icon={<UserRoundCheck />}
              checked={selectedFilters.includes(FilterUserType.NOT_DELETED)}
              onCheckedChange={() => {
                handleCheckboxChange(FilterUserType.NOT_DELETED);
              }}
            />
            <Alert className="mt-4 bg-blue-200 bg-opacity-50">
              <AlertDescription>
                <span style={{ color: '#8b9ca9' }}>Users not deleted are shown by default.</span>
              </AlertDescription>
            </Alert>
          </div>
        </PopoverContent>
      </Popover>
      {selectedFilters.length > 0 && (
        <span className="ml-1" style={{ color: '#c9d5df' }}>
          {' '}
          | {selectedFilters.length}
        </span>
      )}
    </div>
  );
};

interface FilterCheckboxProps {
  filter: string;
  icon: React.ReactNode;
  checked: boolean;
  onCheckedChange: () => void;
}

const FilterCheckbox: React.FC<FilterCheckboxProps> = ({ filter, icon, checked, onCheckedChange }) => {
  const clonedIcon = React.isValidElement(icon)
    ? React.cloneElement(icon as React.ReactElement, {
        className: 'icon-class',
        size: 15,
      })
    : null;

  return (
    <label className="checkbox-label flex items-center space-x-2" style={{ color: '#8b9ca9' }}>
      <input
        type="checkbox"
        className="form-checkbox text-indigo-600 h-4 w-4"
        checked={checked}
        onChange={onCheckedChange}
      />
      {clonedIcon !== undefined && <span style={{ color: '#8b9ca9' }}>{clonedIcon}</span>}
      <span>{`${filter}`}</span>
    </label>
  );
};

export default UsersFilter;

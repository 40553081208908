import { SettingMenuOption } from "../types/settings-menu-option.type";

export const selectFirstActiveFeature = (
  menuOptions: SettingMenuOption[]
): SettingMenuOption => {
  const firstActiveFeature =
    menuOptions.find((option) => option.isAvailable) ?? null;

  if (firstActiveFeature?.options?.length) {
    return selectFirstActiveFeature(firstActiveFeature.options);
  }

  return firstActiveFeature || {} as SettingMenuOption;
};

import routes from "../../constants/routes";
import axios from "axios";
import { ApiResponse } from "../../types/ApiResponse";
import { SdrCallLogSuccessResponse } from "../CallLog/deleteSDRCallLogAndUpdateCallLogAsync";

export const deleteSdrLogAsync = async (
  sdrId: string | undefined
): Promise<ApiResponse<SdrCallLogSuccessResponse>> => {
  try {
    const { data } = await axios.delete(
      `${routes.delete.twilio.deleteSdrLogNest}/${sdrId}`
    );
    console.info(
      `SdrLog for SDR with id: ${sdrId} was successfully deleted. Transfer unlocked.`
    );
    return { success: true, data: data };
  } catch (e) {
    return {
      success: false,
      error: { message: `Error deleting SdrLog for SDR with id: ${sdrId}` },
    };
  }
};

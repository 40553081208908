import routes from "../../constants/routes";
import axios from "axios";
import { NumberPool } from "../../types/index";

type ResponseProps = {
  data?: NumberPool[];
  totalPages?: number;
  totalCount?: number;
  pageNumber?: number;
  error?: { message: string };
};

const getUserNumbersAsync = async (
  pageNumber: number,
  pageLimit: number,
  search: string
): Promise<ResponseProps> => {
  try {
    const { data } = await axios.get(`${routes.get.callerId.getNumbers}`, {
      params: {
        page: pageNumber,
        pageLimit: pageLimit,
        search,
      },
    });
    return {
      data: data.data,
      pageNumber: data.pageNumber,
      totalPages: data.totalPages,
      totalCount: data.totalCount,
    };
  } catch {
    return { error: { message: "Error getting organizations" } };
  }
};

export default getUserNumbersAsync;

import routes from "../../constants/routes";
import axios from "axios";
import { ApiResponse } from "../../types/ApiResponse";
import { StatusResponse } from "../../types";

const updatePassword = async (
  newToken: string | null,
  password: string,
  currentPassword: string | null
): Promise<ApiResponse<StatusResponse>> => {
  try {
    const body = {
      password: password,
      currentPassword: currentPassword,
      token: newToken,
    };

    const response = await axios.put<StatusResponse>(
      routes.put.users.password.changeNest,
      body
    );

    return { success: true, data: { status: response.status } };
  } catch (error) {
    const response = {
      error: { message: "" },
      success: false,
    };

    if (axios.isAxiosError(error) && error.response) {
      response.error.message = error.response.data.message;
    } else {
      response.error.message = "Something went wrong, please try later";
    }
    return response;
  }
};

export default updatePassword;

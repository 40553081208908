import routes from "../../constants/routes";
import axios from "axios";
import { CallSession } from "../../components/shared/CallSession";

type Props = {
  sdrId: string;
};

type ResponseProps = {
  previewCallSession?: CallSession | null;
  error?: { message: string };
};

const getPreviewAsync = async ({ sdrId }: Props): Promise<ResponseProps> => {
  try {
    const callSession = await axios.get<CallSession>(
      routes.get.callSession.getPreviewNest + `/${sdrId}`
    );

    return { previewCallSession: callSession.data };
  } catch {
    return {
      error: {
        message: "Error getting call session preview, try again later.",
      },
    };
  }
};

export default getPreviewAsync;

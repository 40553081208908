import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Flex, Text, Image, Button } from "../../../core/ui";
import {
  GenericTableHeader,
  PaginatedProps,
  SearchProps,
} from "../../../core/ui/UITable";
import CreateEditModal from "./CreateEditModal";
import DeleteModal from "./DeleteModal";
import getUsersAsync from "../../../api/Management/getUsersAsync";
import AddUserIcon from "../../../assets/icons/add_user_white.svg";
import UICard from "../../../core/ui/UICard";
import UITable from "../../../core/ui/UITable";
import Loader from "../../../core/ui/UILoader";
import { showUIToast } from "../../../core/ui/UIToast";
import { UserProviderContext } from "../../app/UserProvider";
import { UIIconButton } from "../../../core/ui/UIElements";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

const TypeContainer = styled(Box)`
  width: 65px;
  padding: 4px;
  border-radius: 5px;
  text-align: center;
  color: white;
  background: linear-gradient(90deg, #0093d2 0%, #0a5690 50%);
`;

const UsersManagementContainer = (): JSX.Element => {
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [showCreateEditModal, setShowCreateEditModal] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [filters, setFilters] = useState<string[]>([]);
  const [paginatedData, setPaginatedData] = useState<
    PaginatedProps | undefined
  >(undefined);
  const [searchData, setSearchData] = useState<SearchProps | undefined>(
    undefined
  );
  const [search, setSearch] = useState("");
  const [loadingStatus, updateLoadingStatus] = useState<{
    users: boolean;
  }>({ users: true });
  const {
    currentUser: { isSuperAdmin },
  } = useContext(UserProviderContext);

  //For table fields, custom render will be the preferred way if the value is not string
  const renderUserType = (user) => {
    const { userType } = user;
    if (userType.length > 1) {
      return (
        <Flex gap="10px">
          {userType.map((type) => (
            <TypeContainer key={type}>{type}</TypeContainer>
          ))}
        </Flex>
      );
    }
    return userType.length ? (
      <Flex>
        <TypeContainer>{userType[0]}</TypeContainer>
      </Flex>
    ) : null;
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setShowCreateEditModal(true);
  };

  const handleShowDeleteUserModal = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const renderOperations = (user) => {
    return (
      <Flex gap="1rem">
        {isSuperAdmin && (
          <>
            <UIIconButton
              data-qa-id={`editUser_${user.name.split(" ").join("_")}`}
              onClick={() => handleEditUser(user)}
            >
              <FA
                icon={faPenToSquare}
                size="lg"
                className="text-app-blue-light"
              />
            </UIIconButton>
            <UIIconButton
              data-qa-id={`deleteUser_${user.name.split(" ").join("_")}`}
              onClick={() => handleShowDeleteUserModal(user)}
            >
              <FA icon={faTrash} size="lg" />
            </UIIconButton>
          </>
        )}
      </Flex>
    );
  };

  const headers: GenericTableHeader[] = [
    { text: "Id", width: "1.6" },
    { text: "Name", width: "1.4" },
    { text: "Email", width: "2" },
    { text: "User type(s)", width: "1.2" },
    { text: "Organization", width: "1.2" },
    { text: "", width: "0" },
  ];

  const tableBodies = [
    "_id",
    "name",
    "email",
    { id: "userType", customRender: true, renderFunc: renderUserType },
    "organizationName",
    { id: "operations", customRender: true, renderFunc: renderOperations },
  ];

  const getUsers = async (pageNumber: number, searchValue: string, filters:string[]) => {
    const { data, success, error } = await getUsersAsync(
      pageNumber,
      pageSize,
      searchValue,
      filters
    );
    if (data && data.data && success) {
      const { totalCount, totalPages } = data;
      setPageNumber(pageNumber);
      setUsers(data.data);
      setPaginatedData({
        pageNumber,
        totalCount: totalCount ? totalCount : 0,
        totalPages: totalPages ? totalPages : 1,
        pageSize,
        onPrevPage: handlePrev,
        onNextPage: handleNext,
      });
      setSearchData({
        placeHolder: "Search by name, email or organization",
        onSearch: handleSearch,
      });

      updateLoadingStatus({ ...loadingStatus, users: false });
    }
    error && showUIToast({ type: "error", text: error.message });
  };

  useEffect(() => {
    getUsers(pageNumber, search, filters);
  }, [pageNumber, search, filters]);

  const handleSearch = async (searchData: string | null,filters:string[] | null) => {
    await setPageNumber(1);
    searchData !== null && await setSearch(searchData);
    filters !== null && setFilters(filters);
  };

  const handlePrev = () => {
    setPageNumber((last) => last - 1);
  };

  const handleNext = () => {
    setPageNumber((last) => last + 1);
  };

  const handleOnCloseCreateOrEditModal = () => {
    setShowCreateEditModal(false);
    cleanup();
  };

  const handleOnSuccessModal = () => {
    getUsers(1, search, filters);
    setShowDeleteModal(false);
    setShowCreateEditModal(false);
    cleanup();
  };

  const handleOnCloseDeleteUserModal = () => {
    setShowDeleteModal(false);
    cleanup();
  };

  const cleanup = () => {
    setSelectedUser(null);
  };
  return (
    <>
      <UICard
        title={"Contacts from selected List"}
        showTitle={false}
        width={"100%"}
        height="580px"
      >
        {loadingStatus.users ? (
          <Loader message={"Loading call sessions, please wait..."} />
        ) : (
          <UITable
            headers={headers}
            tableBodies={tableBodies}
            data={users}
            visible={false}
            paginatedData={paginatedData}
            searchData={searchData}
            filterEnabled={true}
          >
            {isSuperAdmin && (
              <Flex padding="0 2rem" justifyContent="flex-end">
                <Button
                  bg="lightBlue"
                  px={25}
                  py={12}
                  data-qa-id="createUserBtn"
                  onClick={() => setShowCreateEditModal(true)}
                  style={{ paddingBottom: "6px", paddingTop: "6px" }}
                >
                  <Flex alignItems="center">
                    <Image src={AddUserIcon} width={20} mr={15}></Image>
                    <Text fontSize={15} color="white">
                      Create user
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            )}
          </UITable>
        )}
      </UICard>
      <CreateEditModal
        isOpen={showCreateEditModal}
        onClose={handleOnCloseCreateOrEditModal}
        selectedUser={selectedUser}
        onSuccess={handleOnSuccessModal}
      />
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={handleOnCloseDeleteUserModal}
        selectedUser={selectedUser}
        onSuccess={handleOnSuccessModal}
      />
    </>
  );
};

export default UsersManagementContainer;

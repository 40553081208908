import routes from "../../constants/routes";
import axios from "axios";
import { logAxiosErrorWithSentry } from "../../core/utils/axios.utils";

type ResponseProps = {
  job?: any;
  error?: { message: string };
};

const jobUrl = {
  OUTREACH: routes.post.outreach.updateJob,
  HUBSPOT: routes.post.hubspot.updateJob,
  CUSTOM_HUBSPOT: routes.post.hubspot.updateJob,
};

const updateSyncJobAsync = async (
  type,
  nonce,
  orgId,
  contactListId
): Promise<ResponseProps> => {
  try {
    const job = await axios.post<any>(
      `${jobUrl[type]}/${nonce}/${orgId}/${contactListId}`
    );
    return { job };
  } catch (e) {
    const message = "Error updating sync job";

    if (axios.isAxiosError(e)) {
      logAxiosErrorWithSentry({
        payload: e,
        description: message,
      });
    }

    return { error: { message } };
  }
};

export default updateSyncJobAsync;

import routes from "../../constants/routes";
import axios from "axios";
import { MultipleStatusAndSdrIdResponse } from "../../components/shared/CallSession";

type Props = {
  status: string[];
  sdrId: string;
};

type ResponseProps = {
  response?: MultipleStatusAndSdrIdResponse | null;
  error?: { message: string };
};

const getByMultipleStatusAndSdrIdAsync = async ({
  status,
  sdrId,
}: Props): Promise<ResponseProps> => {
  try {
    const params = status.map((e) => `status=${e}`).join("&");
    const response = await axios.get<MultipleStatusAndSdrIdResponse>(
      routes.get.callSession.getByMultipleStatusAndSdrIdNest +
        `/${sdrId}?${params}`
    );
    return { response: response.data };
  } catch (e) {
    const message =
      "Something went wrong getting call session by status and sdrId";

    return {
      error: { message },
    };
  }
};

export default getByMultipleStatusAndSdrIdAsync;

import React, { useState } from "react";
import axios from "axios";
import routes from "../../constants/routes";

import LoginLeftColumn from "./LoginLeftColumn";
import {
  LoginGrid,
  LoginCell,
  LoginColumn,
  LoginErrorMessage,
  LoginSuccessMesage,
  LoginFromText,
  LoginForm,
  LoginInputText,
  LoginButton,
} from "./LoginElements";
import { showUIToast } from "../../core/ui/UIToast";
import recover from "../../api/Auth/recover";

type TodoErrorResponse = {
  error: string;
};

const PasswordRecover: React.FC = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState(false);

  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const successMessage =
    "We sent an email to recover your password, follow the instructions.";

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { data, error } = await recover(email);
    if (data && data.status === 201) {
      setErrors(false);
      setSuccess(true);

      showUIToast({
        type: "success",
        text: successMessage,
      });
    } else {
      setErrors(true);

      if (error) {
        // Request made and server responded
        setErrorMessage(error.message);
        showUIToast({
          type: "warning",
          text: "Error recovering password, try again later.",
        });
        setSuccess(false);
      } else {
        // The request was made but no response was received
        setErrorMessage("Something went wrong, please try later");
        showUIToast({
          type: "error",
          text: "Something went wrong, try again later.",
        });
        setSuccess(false);
      }
    }
  };

  return (
    <LoginGrid columns={["35%", "1fr"]}>
      <LoginLeftColumn />
      <LoginCell backgroundColor={"#EFEFEF"} color="black">
        <LoginColumn
          alignItems="center"
          justifyContent="center"
          gap="2rem"
          padding="3em 0"
        >
          <LoginFromText>Send an email to recover your password</LoginFromText>
          {errors && <LoginErrorMessage>{errorMessage}</LoginErrorMessage>}
          {success && <LoginSuccessMesage>{successMessage}</LoginSuccessMesage>}
          <LoginForm onSubmit={handleSubmit}>
            <LoginInputText
              type="email"
              placeholder={"Email Address"}
              value={email}
              onChange={handleEmailChange}
              fontSize="1.625rem"
            />
            <LoginButton
              type="submit"
              value="Recover password"
              fontSize="1.625rem"
            />
          </LoginForm>
        </LoginColumn>
      </LoginCell>
    </LoginGrid>
  );
};

export default PasswordRecover;

import React, { ButtonHTMLAttributes, ReactElement } from "react";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { IconDefinition, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { shouldHaveAtLeastOneProp } from "../utils/view-validations.util";

interface ButtonOutlinedProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: IconDefinition;
  text?: string;
  loading?: boolean;
}

export default function ButtonOutlined({
  text,
  loading,
  icon,
  ...buttonProps
}: ButtonOutlinedProps): ReactElement<HTMLButtonElement> {
  shouldHaveAtLeastOneProp({ text, icon });

  return (
    <button
      type="button"
      disabled={loading === true || buttonProps?.disabled}
      name={text}
      className={`
              bg-white px-5 py-2.5 
              text-sm font-medium text-gray-500
              rounded-lg
              border border-1 border-solid border-gray-200
              focus:outline-none focus:ring-2 focus:ring-gray-200 focus:z-10
              hover:bg-gray-100 hover:text-gray-600 hover:border-gray-300
              disabled:opacity-50 disabled:cursor-not-allowed
              transition-all ease-in-out duration-200 delay-75
              `}
      {...buttonProps}
    >
      <>
        {loading === true ? (
          <FA icon={faSpinner} spinPulse size="xl" name="Loading Icon" />
        ) : (
          icon !== undefined && <FA icon={icon} size="xl" name="Icon Button" />
        )}
        {text}
      </>
    </button>
  );
}

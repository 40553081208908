import { type ApiResponse } from '@/src/types/ApiResponse';

import getLoggerTokenAsync from '@/src-v2/Data/DataSource/edge/auth/getLoggerTokenAsync';

interface AuthRepo {
  getLoggerTokenAsync: () => Promise<ApiResponse<string>>;
}

export function AuthRepository(): AuthRepo {
  return {
    async getLoggerTokenAsync() {
      const { data, success, error } = await getLoggerTokenAsync();
      if (data === null || data === undefined) {
        return { error, success };
      }
      return { data, success: true };
    },
  };
}

import React, { type ReactElement } from 'react';
import { type OutreachMappingsViewModel } from '../OutreachMappings.viewmodel';
import ReactTooltip from 'react-tooltip';
import { colors } from '../../../../../../../../src/core/theme';
import { type OutreachCallDispositions } from '../../../../../../../Data/DataSource/sync/outreach-mappings/dto/getOutreachCustomDispositions.dto';
import { type Disposition, DispositionAbbreviation, type Outcome } from '../../../../../types/disposition-mapping.type';

interface MappingDropdownProps {
  rowMapping: Disposition;
  viewModel: OutreachMappingsViewModel;
}
const MappingDropdown = ({
  viewModel: { selectDisposition, customDispositions, viewState },
  rowMapping,
}: MappingDropdownProps): ReactElement => {
  const { isOutreachAccount, isSuperAdmin, isAdmin } = viewState;

  const isSelectAvailable = isOutreachAccount && (isSuperAdmin || isAdmin);

  const SELECT_ID = String(rowMapping.customName + rowMapping.defaultAction + rowMapping.id).replace(' ', '-');

  const handleSelectItem = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    e.preventDefault();
    const selectedItem = JSON.parse(e.target.value) as OutreachCallDispositions;

    selectDisposition(rowMapping, selectedItem);
  };

  const selected = customDispositions.find((customDisposition) => {
    if (customDisposition.id === rowMapping.id) {
      rowMapping.customName = customDisposition.attributes.name;
      rowMapping.outcome = customDisposition.attributes.outcome as Outcome;
      return customDisposition;
    }
    return false;
  });

  return (
    <>
      <label aria-hidden="true" htmlFor={rowMapping.abbreviation} className="hidden">
        Custom Disposition
      </label>
      <select
        id={SELECT_ID}
        key={rowMapping.abbreviation}
        className="block w-4/5 px-4 py-2 text-base text-gray-400 border border-gray-300 rounded-lg bg-gray-50 ring-none focus:ring-2 focus:ring-app-blue-lighter focus:border-app-blue-light"
        onChange={handleSelectItem}
        disabled={rowMapping.abbreviation === DispositionAbbreviation.default_action || !isSelectAvailable}
        data-tip="Select a call disposition"
        data-for={SELECT_ID + '-tooltip'}
        defaultValue={JSON.stringify(selected)}
      >
        <OptionDefault rowMapping={rowMapping} />
        {customDispositions.map((customDisposition, index) => {
          const customId = customDisposition.id;
          const customName = rowMapping.id !== customId ? customDisposition.attributes.name : rowMapping.customName;
          return (
            <option key={`${index} + ${rowMapping.abbreviation}`} value={JSON.stringify(customDisposition)}>
              {customName}
            </option>
          );
        })}
      </select>
      <ReactTooltip
        id={SELECT_ID + '-tooltip'}
        place="top"
        type="info"
        effect="solid"
        backgroundColor={colors.lightBlue}
        multiline={true}
      />
    </>
  );
};

const OptionDefault = ({ rowMapping }: { rowMapping: Disposition }): ReactElement => {
  const { abbreviation, customName, defaultName } = rowMapping;

  return (
    <option key={abbreviation + '-option' + rowMapping.id} value="">
      {customName ?? defaultName}
    </option>
  );
};

export default MappingDropdown;

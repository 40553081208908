import routes from "../../constants/routes";
import axios, { AxiosResponse } from "axios";

export type UserResponseProps = {
  currentNumber?: string;
  error?: { message: string };
};

const getCurrentNumberUser = async (): Promise<UserResponseProps> => {
  try {
    const twilioURL = `${routes.get.twilio.getCurrentNumber}`;
    const response: AxiosResponse<string> = await axios.get(twilioURL);
    return { currentNumber: response.data };
  } catch (e) {
    return { error: { message: "Error getting the requested organization" } };
  }
};

export default getCurrentNumberUser;

import { AuthRepository } from '../../../Data/Repository/edge/auth/authRepository';
import { type ApiResponse } from '@/src/types/ApiResponse';

interface AuthUseCaseProps {
  getLoggerTokenAsync: () => Promise<ApiResponse<string>>;
}

export function AuthUseCase(): AuthUseCaseProps {
  const { getLoggerTokenAsync } = AuthRepository();
  return {
    async getLoggerTokenAsync() {
      const { data, error } = await getLoggerTokenAsync();
      if (data === null) {
        return { error };
      }
      return { data };
    },
  };
}

import {
  AgentValidation,
  CallSessionValidation,
  ContactListValidation,
  VoiceNoteValidation,
  ScriptValidation,
  SdrValidation,
} from "../../../types/AgentValidationsTypes";
import getAgentValidations from "../../../api/Users/getAgentValidations";
import { handleValidationResult } from "./validations";

const checkAgentValidation = (agent?: AgentValidation | null) => {
  if (!agent) {
    return handleValidationResult(
      false,
      "There was an issue with your user; was not found"
    );
  }
  return true;
};

const checkSDRValidation = (
  sdr?: SdrValidation | null,
  currentSdrId?: string
) => {
  if (!sdr || !currentSdrId) {
    return handleValidationResult(
      false,
      "There was an issue with your SDR; was not found"
    );
  }
  if (sdr.id !== currentSdrId) {
    return handleValidationResult(
      false,
      "Your current SDR doesn't match the assigned SDR for this session"
    );
  }
  if (!sdr.userType.some(({ type }) => type === "sdr")) {
    return handleValidationResult(
      false,
      "Your current SDR doesn't have proper permissions"
    );
  }
  return true;
};

const checkCallSessionValidation = (
  callSession?: CallSessionValidation | null
) => {
  if (!callSession) {
    return handleValidationResult(
      false,
      "There was an issue with your call session; was not found"
    );
  }
  if (!callSession.isAgentAssigned) {
    return handleValidationResult(
      false,
      "You aren't assigned to this call session"
    );
  }
  if (!callSession.isSdrAssigned) {
    return handleValidationResult(
      false,
      "Your User is not matching the assigned SDR for the call session"
    );
  }
  if (callSession.status === "paused") {
    return handleValidationResult(
      true,
      "Warning: Your assigned call session is paused"
    );
  }
  return true;
};

const checkContactListValidation = (
  contactList?: ContactListValidation | null
) => {
  const minContactListSize = 30;
  if (!contactList) {
    return handleValidationResult(
      false,
      "There was an issue with your contact list; was not found"
    );
  }
  if (contactList.size < minContactListSize) {
    return handleValidationResult(
      true,
      `Warning: You should have at least ${minContactListSize} contacts, you only have ${contactList.size}`
    );
  }
  return true;
};

const checkVoicemailValidation = (voiceNote?: VoiceNoteValidation | null) => {
  if (!voiceNote) {
    return handleValidationResult(
      false,
      "There was an issue with your voice mail; was not found"
    );
  }
  return true;
};

const checkIntentValidation = (script?: ScriptValidation | null) => {
  if (!script) {
    return handleValidationResult(
      false,
      "There was an issue with your intent; was not found"
    );
  }
  return true;
};

const getAgentCallSessionValidations = async (
  agentId: string,
  currentSdrId: string | undefined
): Promise<boolean> => {
  const { data, error } = await getAgentValidations(agentId);

  if (error) {
    console.error(error);
    return handleValidationResult(false, error.message);
  }

  return (
    checkAgentValidation(data?.agent?.data) &&
    checkSDRValidation(data?.sdr?.data, currentSdrId) &&
    checkCallSessionValidation(data?.callSession?.data) &&
    checkVoicemailValidation(data?.voiceNote?.data) &&
    checkIntentValidation(data?.script?.data)
  );
};

export { getAgentCallSessionValidations };

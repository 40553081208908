import React from "react";
import styled from "styled-components";
import { theme } from "../../core/theme";
import { ContactDialingStatus } from "../shared/Contact";
import UICard from "../../core/ui/UICard";
import { Image } from "../../core/ui";
import { UIText } from "../../core/ui/UIElements";
import EmptyListSVG from "../../assets/icons/empty_list.svg";
import { UICol, UIRow } from "../../core/ui/UIStructures";

const { lightBlue, darkGray, lightGray } = theme.colors;

const Table = styled.table<{
  cols: string;
  maxHeight?: boolean;
}>`
  display: block;
  text-align: left;
  background-color: white;
  color: ${darkGray};

  border-radius: 0 0 0.5rem 0.5rem;
  min-height: 480px;
  min-width: 100%;

  ${({ maxHeight }) =>
    maxHeight && {
      maxHeight: "480px",
      overflowY: "auto",
    }}
  th, td {
    padding: 0.5em 0 0.5em 0.5em;
    font-weight: 400;
    word-wrap: break-word;
    white-space: pre-line;
  }
  td {
    padding-left: 1em;
  }
  thead,
  tbody {
    display: block;
  }
  thead {
    background: ${lightBlue};
    color: white;
    padding: 0.5em;
    ${({ maxHeight }) =>
      maxHeight && {
        position: "sticky",
        top: 0,
      }};
  }
  tr {
    display: grid;
    align-items: center;
    grid-template-columns: ${({ cols }) => cols};
    &:nth-child(even) {
      background: ${lightGray};
    }
    &.transferred-row {
      background-color: rgba(50, 199, 65, 255);
      color: white;
    }
  }
`;

type CallLogTableProps = {
  data: ContactDialingStatus[];
};

const getTransferredClassName = (
  contactDialingStatus: ContactDialingStatus
) => {
  const isTransferred: boolean = Object.values(contactDialingStatus).some(
    (value) => value === "transferred"
  );
  return isTransferred ? "transferred-row" : "";
};

const CallLogTable = ({ data }: CallLogTableProps): JSX.Element => {
  const tHeaders = [
    { text: "Name", width: 1 },
    { text: "Company", width: 1 },
    { text: "Position", width: 1 },
    { text: "Phone Number", width: 1 },
    { text: "Notes", width: 2 },
    { text: "Status", width: 1 },
  ];
  const tBody = ["name", "accountName", "jobTitle", "phone", "notes", "action"];
  const cols = tHeaders.reduce((acc, curr) => acc + `${curr.width}fr `, "");

  return (
    <>
      {data.length > 0 ? (
        <Table cols={cols} maxHeight={true}>
          <thead>
            <tr>
              {tHeaders.map(({ text }, index) => (
                <th key={index}>{text}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((data, index) => (
              <tr
                className={getTransferredClassName(data)}
                key={`${data._id}-tr-${index}`}
              >
                {tBody.map((body) => (
                  <td key={`${data._id}-${body}`}>{data[body]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <UICard
          title="There's no call logs yet..."
          showTitle={false}
          width="100%"
          minHeight="430px"
        >
          <UIRow justifyContent="center" alignItems="stretch" minHeight="480px">
            <UICol justifyContent="center" alignItems="center" xs={12}>
              <Image src={EmptyListSVG} alt="EmptyList" width="300px" />
            </UICol>
            <UIText>There&apos;s no call logs yet...</UIText>
          </UIRow>
        </UICard>
      )}
    </>
  );
};

export default CallLogTable;

import React from 'react';
import { PrivateRoutes, PublicRoutes } from '../constants/routesConnect';
import { Navigate, Outlet } from 'react-router-dom';
import { Roles } from '../types/Roles';

const window = globalThis as any;

const AuthGuard = () => {
    const userSession = JSON.parse(window.localStorage.getItem('user') || '{}');

    if (!userSession.type) {
        return <Navigate replace to={PublicRoutes.LOGIN}/>
    }

    const isAdmin = userSession.type.some(item => item.type === Roles.ADMIN);

    const isSdr = userSession.type.some(item => item.type === Roles.SDR);

    if(isAdmin || isSdr){
			return <Outlet/>;
    }

    return (
      <>
          <Navigate replace to={PrivateRoutes.DIALER}/>
          <Outlet/>
      </>
    );
}
export default AuthGuard;

import axios from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import routes from '../../../../../src/constants/routes';
import { type PostOutreachMappingDto } from './dto/post-outreach-mapping.dto';
import { type OutreachMappingDto } from './dto/get-outreach-mappings.dto';

const postOutreachMappings = async (
  id: string,
  updateOutreachMappingDto: PostOutreachMappingDto,
): Promise<ApiResponse<OutreachMappingDto>> => {
  try {
    const url = `${routes.patch.outreach.updateMappings}/${id}`;
    const mappings = await axios.post<OutreachMappingDto>(url, updateOutreachMappingDto);
    return {
      success: true,
      data: mappings.data,
    };
  } catch (e: unknown) {
    return {
      success: false,
      error: {
        message:
          'An error occurred while updating outreach dispositions If the issue persists, please contact customer support.',
      },
    };
  }
};

export default postOutreachMappings;

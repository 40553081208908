import React, { useState } from "react";
import { Flex } from "../../core/ui";
import styled from "styled-components";
import Select from "react-select";
import Modal from "../shared/Modal";
import { debounce } from "lodash";

type Props = {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void;
  options: {
    value: string;
    label: string;
  }[];
  selectedOption: {
    value: string;
    label: string;
  };
  onDropdownChange: (value: any) => void;
  additionalNotes: string;
  setAdditionalNotes: React.Dispatch<React.SetStateAction<string>>;
  showAdditionalNotes: boolean;
  isDisabled: boolean;
};

const StyledFlex = styled(Flex)`
  align-self: end;
  margin-bottom: 2rem;
`;

const NewSelect = styled(Select)`
  margin-bottom: 1.5rem;
`;

const StyledLabel = styled.label`
  margin-bottom: 5px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 1rem;
  color: #333333;
  margin-bottom: 5px;
  outline: 0.5px solid #cccccc;
  resize: none;
`;

const DispositionsModal = ({
  isOpen,
  setOpen,
  onSubmit,
  options,
  selectedOption,
  additionalNotes,
  setAdditionalNotes,
  showAdditionalNotes,
  onDropdownChange,
  isDisabled,
}: Props) => {
  const [characterCount, setCharacterCount] = useState(0);

  return (
    <Modal
      state={isOpen}
      setState={setOpen}
      title={"Dispositions"}
      showHeader={true}
      showOverlay={true}
      modalPosition={"center"}
      padding={"20px"}
      showCloseBtn={false}
    >
      <>
        <p>Please Select a Call Disposition</p>

        <div>
          <NewSelect
            data-qa-id={"disposition_" + selectedOption}
            value={selectedOption}
            onChange={onDropdownChange}
            options={options}
          />
        </div>

        {showAdditionalNotes && (
          <Flex flexDirection="column" flex="1">
            <StyledLabel>Please specify:</StyledLabel>
            <StyledTextArea
              value={additionalNotes}
              maxLength={200}
              rows={4}
              onChange={(e) => {
                setAdditionalNotes(e.target.value);
                setCharacterCount(e.target.value.length);
              }}
            />
            <StyledFlex flexDirection="row" flex="1">
              <StyledLabel>{200 - characterCount} characters left</StyledLabel>
            </StyledFlex>
          </Flex>
        )}

        <Flex flexDirection="row" justifyContent="space-between">
          <button onClick={debounce(onSubmit, 10)} disabled={!isDisabled}>
            {" "}
            Submit{" "}
          </button>
        </Flex>
      </>
    </Modal>
  );
};

export default DispositionsModal;

import React, { type FC } from 'react';
import { Separator } from '@components/ui/separator';

interface SettingsTitleDividerProps {
  title: string;
  description: string;
}

const SettingsTitleDivider: FC<SettingsTitleDividerProps> = ({ title, description }) => {
  return (
    <>
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium">{title}</h3>
          <p className="text-sm text-muted-foreground">{description}</p>
        </div>
        <Separator />
      </div>
    </>
  );
};

export default SettingsTitleDivider;

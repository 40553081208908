export enum ActionType {
  VOICEMAIL = 'voicemail',
  NOT_ANSWERED = 'not_answered',
  CUT_OFF = 'cut_off',
  TRANSFER_ERROR = 'transfer_error',
}

export enum ContactType {
  FlAGGED = 'flagged',
}

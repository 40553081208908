import React, { useState, useEffect, useContext, ReactElement } from "react";
import styled from "styled-components";
import outreachLogo from "./outreach.svg";
import routes from "../../../constants/routes";
import { UserProviderContext } from "../../../components/app/UserProvider";
import axios from "axios";
import { UIMiniButton } from "../../../core/ui/UIElements";
import Loader from "../../../core/ui/UILoader";
import { Account, AccountType } from "../Shared/Entities/Account";
import { showUIToast } from "../../../core/ui/UIToast";
import IntegrationUser from "../Shared/Components/integrations/IntegrationUser.component";
import DeleteAccountModal from "../Shared/Components/DeleteAccountModal";
import deleteOutreachAccountAsync from "../../../api/Sync/deleteOutreachAccountAsync";

const IntegrationsList = styled.div<{
  scrollStartingAt: number;
}>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  ${({ scrollStartingAt }) =>
    scrollStartingAt > 0 &&
    `
    max-height: calc(82px * ${scrollStartingAt} + (.5rem * ${scrollStartingAt}));
    overflow-y: scroll;
  `}
`;

const OutreachContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-height: 395px;
`;

export const Outreach = (): ReactElement => {
  const { currentUser } = useContext(UserProviderContext);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    nonce: "",
  });

  const [state, dispatch] = useState<{
    ready: boolean;
    configuring: boolean;
    message: string;
    loading: boolean;
    loadingMessage: string;
  }>({
    ready: true,
    configuring: false,
    message: "Use button above to connect Outreach.",
    loading: true,
    loadingMessage: "Loading linked accounts",
  });

  const handleConnect = async () => {
    try {
      const url = `${routes.post.outreach.oAuthLogin}/${currentUser.organizationId}/${currentUser.userId}`;
      const res = await axios.post<{
        url: string;
      }>(url);
      if (res.status === 201) {
        window.open(
          res.data.url,
          "outreachPopup",
          `width=${500},height=${500}`
        );
        getAccountsInterval();
        showUIToast({
          type: "default",
          text: "Outreach connected",
        });
        return dispatch((state) => ({
          ...state,
          configuring: true,
        }));
      }
    } catch (err) {
      oureachError();
    }
  };

  const oureachError = () => {
    showUIToast({
      type: "error",
      text: "Can't configure Outreach right now, please try again later.",
    });
    return dispatch((state) => ({
      ...state,
      ready: false,
      message: "Can't configure Outreach right now, please try again later.",
    }));
  };

  const changeMessage = (message: string) =>
    dispatch((state) => ({ ...state, message }));

  const handleCancel = () => {
    // TODO: stop current configuration and show the corresponding ui toast messages
    dispatch((state) => ({
      ...state,
      configuring: false,
      message: "Use button above to connect Outreach.",
    }));
  };

  const getAccounts = async (wipeInterval?: () => void) => {
    try {
      setAccounts([]);
      const listenUrl = `${routes.get.outreach.getLinkedAccounts}/${currentUser.organizationId}/${currentUser.userId}`;
      const res = await axios.get<Account[]>(listenUrl);
      if (res.status === 202) {
        showUIToast({
          type: "info",
          text: "Outreach syncing in progress.",
        });
        return dispatch((state) => ({
          ...state,
          configuring: true,
          message: "Configuration in process.",
        }));
      }
      if (res.status === 204) {
        if (wipeInterval) wipeInterval();
        dispatch((state) => ({
          ...state,
          configuring: false,
        }));
        showUIToast({
          type: "info",
          text: "Outreach synced.",
        });
        return changeMessage("Nothing was configured.");
      }
      if (res.status === 200) {
        if (wipeInterval) wipeInterval();
        const amount = res.data.length;
        changeMessage(
          `You have ${amount} ${amount > 1 ? "account" : "accounts"} linked.`
        );
        dispatch((state) => ({
          ...state,
          loading: false,
          configuring: false,
        }));
        showUIToast({
          type: "info",
          text: `Outreach linked with ${amount} ${
            amount > 1 ? "account" : "accounts"
          }.`,
        });
        return setAccounts(res.data);
      }
    } catch (err) {
      if (wipeInterval) wipeInterval();
      dispatch((state) => ({
        ...state,
        loading: false,
      }));
      console.log(err);
      return changeMessage("No accounts linked.");
    }
  };

  const getAccountsInterval = () => {
    const interval = setInterval(
      () => getAccounts(() => clearInterval(interval)),
      100
    );
  };

  const removeOutreachUser = async (nonce: string) => {
    /// TODO remove user from state and everywhere else
    const { success, error } = await deleteOutreachAccountAsync(
      currentUser.organizationId,
      currentUser.userId,
      nonce
    );

    if (success) {
      const newUsers = accounts.filter((elem) => {
        return elem.nonce !== nonce;
      });
      setAccounts(newUsers);
      showUIToast({
        type: "info",
        text: "Outreach account removed.",
      });
      setDeleteModal({ isOpen: false, nonce: "" });
    } else if (error) {
      showUIToast({
        type: "error",
        text: "Error removing Outreach account.",
      });
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    if (accounts.length === 0) {
      changeMessage("No accounts linked.");
    } else {
      changeMessage(
        `You have ${accounts.length} ${
          accounts.length === 1 ? "account" : "accounts"
        } linked.`
      );
    }
  }, [accounts.length]);

  return (
    <OutreachContainer>
      <DeleteAccountModal
        isOpen={deleteModal.isOpen}
        onClose={() => setDeleteModal({ isOpen: false, nonce: "" })}
        handleDelete={() => removeOutreachUser(deleteModal.nonce)}
      />

      {state.loading ? (
        <Loader message={state.loadingMessage} />
      ) : (
        <>
          <UIMiniButton
            className="outreach"
            style={{ justifySelf: "center" }}
            disabled={state.configuring}
            onClick={handleConnect}
          >
            <img alt="Outreach" src={outreachLogo} width="120" />
          </UIMiniButton>
          {state.configuring && (
            <UIMiniButton onClick={handleCancel}>
              Cancel Configuration
            </UIMiniButton>
          )}
          <IntegrationsList scrollStartingAt={accounts.length > 10 ? 5 : 0}>
            {accounts.map((user) => {
              console.log("accounts", accounts);
              const { id, email, attributes, nonce } = user;
              console.log("user", user);
              return (
                <IntegrationUser
                  key={nonce}
                  remove={(nonce, type) => {
                    console.log("Remove outreach user", nonce, type);
                    setDeleteModal({ isOpen: true, nonce });
                  }}
                  id={id}
                  email={email}
                  nonce={nonce}
                  type={AccountType.Outreach}
                  {...attributes}
                />
              );
            })}
          </IntegrationsList>
          <div>{state.message}</div>
        </>
      )}
    </OutreachContainer>
  );
};

/** @deprecated in favour of Timezone Enums in v2 > Data > Constants > Enums >
 *
 */
export enum Timezone {
  US_Hawaii = "US/Hawaii",
  US_Alaska = "US/Alaska",
  US_Pacific = "US/Pacific",
  US_Mountain = "US/Mountain",
  US_Central = "US/Central",
  US_Eastern = "US/Eastern",
  Canada_Atlantic = "Canada/Atlantic",
  Mexico_BajaCalifornia = "Mexico/BajaCalifornia",
  Mexico_Chihuahua = "Mexico/Chihuahua",
  America_CentralAmerica = "America/CentralAmerica",
  Mexico_General = "Mexico/General",
  UTC = "UTC",
}

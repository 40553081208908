import React, { useState, useEffect } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/src-v2/Presentation/Common/Components/ui/popover';
import { Alert, AlertDescription } from '@/src-v2/Presentation/Common/Components/ui/alert';
import { ActionType, ContactType } from '../../../../Domain/enums/Contacts/contacts-filter';
import { type Contact } from '@/src-v2/Data/DataSource/contacts-nest/contacts/entities/contact.entity';
import { PhoneOff, Voicemail, AlertOctagon, Ban, Filter } from 'lucide-react';
import { LoadingState } from '@/src/types/LoadingStates.type';
import { type SelectedFilters } from '@/src-v2/Domain/interfaces/Contacts/filter.interfaces';
import Fuego from '../../../../../src/assets/icons/fuego-gray.svg';

interface ContactsFilterProps {
  selectedSession: string;
  onChangeFilters: (sessionId: string, selectedFilters: SelectedFilters) => Promise<Contact[]>;
  updateContactsViewStates: (contacts: Contact[]) => void;
}

interface useContactsViewModelLoadingState {
  updateContactsViewStates: LoadingState;
}

const { IDLE, LOADING, ERROR, SUCCESS } = LoadingState;

const ContactsFilter: React.FC<ContactsFilterProps> = ({
  selectedSession,
  onChangeFilters,
  updateContactsViewStates,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    metricsFilter: {
      actions: [],
    },
    contactsFilter: {
      flagged: [],
    },
  });

  const [totalSelectedFilters, setTotalSelectedFilters] = useState<number>(0);

  const [loadingState, setLoadingState] = useState<useContactsViewModelLoadingState>({
    updateContactsViewStates: IDLE,
  });

  useEffect(() => {
    const updateContacts = async (): Promise<void> => {
      setLoadingState((prevState) => ({
        ...prevState,
        updateContactsViewStates: LOADING,
      }));
      try {
        const updatedContacts = await onChangeFilters(selectedSession, selectedFilters);
        updateContactsViewStates(updatedContacts);
        setLoadingState((prevState) => ({
          ...prevState,
          updateContactsViewStates: SUCCESS,
        }));
      } catch (error) {
        setLoadingState({ ...loadingState, updateContactsViewStates: ERROR });
      }
    };

    void updateContacts();
  }, [selectedFilters, selectedSession]);

  const handleCheckboxChange = (filter: ActionType | ContactType): void => {
    setSelectedFilters((prevSelectedFilters: SelectedFilters) => {
      const newFilters = { ...prevSelectedFilters };

      if (Object.values(ActionType).includes(filter as ActionType)) {
        const actionsIndex = newFilters.metricsFilter.actions.indexOf(filter as ActionType);

        if (actionsIndex !== -1) {
          newFilters.metricsFilter.actions.splice(actionsIndex, 1);
        } else {
          newFilters.metricsFilter.actions.push(filter as ActionType);
        }
      } else if (Object.values(ContactType).includes(filter as ContactType)) {
        const contactsIndex = newFilters.contactsFilter.flagged.indexOf(filter as ContactType);

        if (contactsIndex !== -1) {
          newFilters.contactsFilter.flagged.splice(contactsIndex, 1);
        } else {
          newFilters.contactsFilter.flagged.push(filter as ContactType);
        }
      }

      const totalSelected = newFilters.metricsFilter.actions.length + newFilters.contactsFilter.flagged.length;
      setTotalSelectedFilters(totalSelected);

      return newFilters;
    });
  };

  return (
    <div className="flex items-center ml-4">
      <Popover>
        <PopoverTrigger>
          <div className="flex items-center cursor-pointer">
            <Filter size={30} style={{ color: '#c9d5df' }} className="mr-2" />
            <span style={{ color: '#c9d5df' }}>Filters</span>
          </div>
        </PopoverTrigger>
        <PopoverContent className="sm:ml-40">
          <div className="flex flex-col">
            <FilterCheckbox
              filter={ActionType.NOT_ANSWERED === 'not_answered' ? 'Not answer' : ''}
              icon={<Ban />}
              checked={selectedFilters.metricsFilter.actions.includes(ActionType.NOT_ANSWERED)}
              onCheckedChange={() => {
                handleCheckboxChange(ActionType.NOT_ANSWERED);
              }}
            />
            <FilterCheckbox
              filter={ActionType.CUT_OFF === 'cut_off' ? 'Cut off' : ''}
              icon={<PhoneOff />}
              checked={selectedFilters.metricsFilter.actions.includes(ActionType.CUT_OFF)}
              onCheckedChange={() => {
                handleCheckboxChange(ActionType.CUT_OFF);
              }}
            />
            <FilterCheckbox
              filter={ActionType.TRANSFER_ERROR === 'transfer_error' ? 'Transfer error' : ''}
              icon={<AlertOctagon />}
              checked={selectedFilters.metricsFilter.actions.includes(ActionType.TRANSFER_ERROR)}
              onCheckedChange={() => {
                handleCheckboxChange(ActionType.TRANSFER_ERROR);
              }}
            />
            <FilterCheckbox
              filter={ActionType.VOICEMAIL === 'voicemail' ? 'Voicemail' : ''}
              icon={<Voicemail />}
              checked={selectedFilters.metricsFilter.actions.includes(ActionType.VOICEMAIL)}
              onCheckedChange={() => {
                handleCheckboxChange(ActionType.VOICEMAIL);
              }}
            />

            <FilterCheckbox
              filter={ContactType.FlAGGED === 'flagged' ? 'Fuego Lead' : ''}
              icon={Fuego as unknown as string}
              checked={selectedFilters.contactsFilter.flagged.includes(ContactType.FlAGGED)}
              onCheckedChange={() => {
                handleCheckboxChange(ContactType.FlAGGED);
              }}
            />
            <Alert className="mt-4 bg-blue-200 bg-opacity-50">
              <AlertDescription>
                <span style={{ color: '#8b9ca9' }}>
                  If you select an old session, it will show the no transfer contacts by default.
                </span>
              </AlertDescription>
            </Alert>
          </div>
        </PopoverContent>
      </Popover>
      {totalSelectedFilters > 0 && (
        <span className="ml-1" style={{ color: '#c9d5df' }}>
          {' '}
          | {totalSelectedFilters}
        </span>
      )}
    </div>
  );
};

interface FilterCheckboxProps {
  filter: string;
  icon: React.ReactNode | string;
  checked: boolean;
  onCheckedChange: () => void;
}

const FilterCheckbox: React.FC<FilterCheckboxProps> = ({ filter, icon, checked, onCheckedChange }) => {
  const clonedIcon = React.isValidElement(icon)
    ? React.cloneElement(icon as React.ReactElement, {
        className: 'icon-class',
        size: 15,
      })
    : null;

  return (
    <label className="checkbox-label flex items-center space-x-2" style={{ color: '#8b9ca9' }}>
      <input
        type="checkbox"
        className="form-checkbox text-indigo-600 h-4 w-4"
        checked={checked}
        onChange={onCheckedChange}
      />
      {clonedIcon !== null ? (
        <span style={{ color: '#8b9ca9' }}>{clonedIcon}</span>
      ) : (
        <span style={{ color: '#8b9ca9', width: 15, height: 15 }}>
          <img className={'icon-class'} src={icon as string} alt="" />
        </span>
      )}
      <span>{`${filter}`}</span>
    </label>
  );
};

export default ContactsFilter;

import React, { FC } from "react";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { BaseLabel } from "../../core/ui/base/FormElements";
import { theme } from "../../core/theme";

const LIGHT_BLUE_APP_COLOR = theme.colors.lightBlue;

interface LabelWithTooltipProps {
  label: {
    text: string;
    htmlFor: string;
  };
  icon?: IconDefinition;
  tooltip: {
    text: string;
    multiline?: boolean;
  };
}

const LabelWithTooltip: FC<LabelWithTooltipProps> = ({
  label: { text: labelText, htmlFor },
  icon = faQuestion,
  tooltip: { text: tooltipText, multiline = true },
}) => {
  const tooltipId = `label-tooltip-${labelText
    .replace(/\s/g, "-")
    .toLowerCase()}`;
  return (
    <>
      <LabelWrapper>
        <BaseLabel htmlFor={htmlFor}>{labelText}</BaseLabel>
        <TooltipIcon
          icon={icon}
          data-tip={tooltipText}
          data-for={tooltipId}
          size="xs"
          color={LIGHT_BLUE_APP_COLOR}
        />
        <ReactTooltip
          id={tooltipId}
          place="right"
          type="info"
          effect="solid"
          backgroundColor={LIGHT_BLUE_APP_COLOR}
          multiline={multiline}
        />
      </LabelWrapper>
    </>
  );
};

const LabelWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 2rem;
`;

const TooltipIcon = styled(FA)`
  position: absolute;
  top: 0;
  right: -1.85rem;
  width: 0.85rem;
  height: 0.85rem;
  cursor: help;
  border-radius: 50%;
  margin: 0;
  padding: 0.25rem;
  border: 0.125rem solid ${LIGHT_BLUE_APP_COLOR};
`;

export default LabelWithTooltip;

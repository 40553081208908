import React, { type FC } from 'react';
import AccountViewmodel from '@/src-v2/Presentation/Views/Settings/Account/Account.viewmodel';
import SettingsTitleDivider from '@/src-v2/Presentation/Views/Settings/Components/SettingsTitleDivider.component';
import SettingsSection from '@/src-v2/Presentation/Views/Settings/Components/Layouts/SettingsSection.component';
import { Button } from '@components/ui/button';
import SettingsSectionOption from '@/src-v2/Presentation/Views/Settings/Components/Layouts/SettingsSectionOption.component';
import UserAvatarImage from '@/src-v2/Presentation/Views/Settings/Account/Components/UserAvatarImage.component';
import { Label } from '@components/ui/label';
import { Input } from '@components/ui/input';
import {
  Pencil1Icon,
  ResetIcon,
  ImageIcon,
  CheckIcon,
  IdCardIcon,
  EnvelopeClosedIcon,
  InfoCircledIcon,
} from '@radix-ui/react-icons';
import { Alert, AlertDescription, AlertTitle } from '@components/ui/alert';

const AccountView: FC = () => {
  const {
    toggleEditView,
    cancelEdit,
    saveChanges,
    chooseFile,
    removeImage,
    handleUserNameChange,
    viewState: { isEditing, hasImage, image, userName, email },
  } = AccountViewmodel();

  return (
    <>
      <div id="ButtonContainer" className="flex gap-8 align-middle justify-end place-self-end">
        {!isEditing ? (
          <Button
            className="gap-1.5"
            variant="outline"
            type="submit"
            onClick={toggleEditView}
            data-qa-id="edit-Account-Settings"
          >
            <Pencil1Icon className="mr-2 h-4 w-4" />
            Edit Account Settings
          </Button>
        ) : (
          <div className="flex w-[32%] gap-8 align-middle justify-end place-self-end">
            <Button variant="destructive" type="submit" onClick={cancelEdit} data-qa-id="cancelEditAccount">
              <ResetIcon className="mr-2 h-4 w-4" /> Cancel
            </Button>

            <Button
              className="bg-app-blue-light"
              variant="default"
              type="submit"
              formTarget="avatarInput"
              onClick={saveChanges}
              data-qa-id="saveAccountChanges"
            >
              <CheckIcon className="mr-2 h-4 w-4" />
              Save Changes
            </Button>
          </div>
        )}
      </div>

      <SettingsTitleDivider title="Account Settings" description="Manage Settings for your Tendril Connect account" />
      <SettingsSection name="Account Details">
        <SettingsSectionOption
          subtitle="Profile Picture"
          details="Supported formats are: .jpeg, .jpg, .png, .svg, .bmp, .tiff"
          actions={
            <>
              {isEditing && (
                <>
                  {hasImage ? (
                    <Button onClick={removeImage} variant="secondary" type={'submit'} data-qa-id={'removeProfilePic'}>
                      Remove
                    </Button>
                  ) : null}
                  <Button
                    className="relative bg-app-blue-light"
                    variant="default"
                    type={'submit'}
                    data-qa-id={'changeProfilePic'}
                  >
                    <Label htmlFor="avatarInput" className="absolute w-full h-full"></Label>
                    <ImageIcon className="mr-2 h-4 w-4" />
                    Change picture
                  </Button>
                  <Input
                    onChange={(e) => {
                      chooseFile(e);
                    }}
                    id="avatarInput"
                    className="hidden"
                    type={'file'}
                    accept={'.jpeg, .jpg, .png, .svg, .bmp, .tiff, .webp'}
                    data-qa-id={'chooseProfilePic'}
                  />
                </>
              )}
            </>
          }
        />
        <UserAvatarImage
          src={image}
          twClass="object-fill rounded-full md:w-32 md:h-32 xl:h-36 xl:w-36 relative padding-auto"
        />
        <SettingsSectionOption subtitle="Display name" details="Your display name is your Tendril Connect identity" />
        <div className="flex min-[1280px]:w-[60%]  items-center relative">
          <IdCardIcon className="absolute ml-4"></IdCardIcon>
          <Input
            type="text"
            placeholder={userName}
            readOnly={!isEditing}
            contentEditable={isEditing}
            className="w-full pl-12"
            data-qa-id="userNameInput"
            onInput={handleUserNameChange}
          />
        </div>
        <SettingsSectionOption subtitle="Account Email" details="Email linked to your Tendril Connect account" />
        {isEditing && (
          <>
            <Alert className="w-fit bg-sky-100 border-dashed border-app-blue-light">
              <InfoCircledIcon className="h-4 w-4" />
              <AlertTitle>Email Information</AlertTitle>
              <AlertDescription>Your registered email account cannot be changed for security reasons.</AlertDescription>
            </Alert>
          </>
        )}
        <div className="flex min-[1280px]:w-[60%] items-center relative">
          <EnvelopeClosedIcon className="absolute ml-4"></EnvelopeClosedIcon>
          <Input type="email" readOnly value={email} className="w-full pl-12 flex-shrink-0" />
        </div>
      </SettingsSection>
    </>
  );
};

export default AccountView;

import { Logtail } from '@logtail/browser';
import constants from '../../../src/constants.json';

export interface Context {
  componentName: string;
  [key: string]: string;
}

export interface ILogger {
  log: (message: string, context: Context) => void;
  info: (message: string, context: Context) => void;
  warn: (message: string, context: Context) => void;
  error: (message: string, context: Context) => void;
  debug: (message: string, context: Context) => void;
  fatal: (message: string, context: Context) => void;
  verbose: (message: string, context: Context) => void;
  setInstance: (token: string) => void;
}

export class LogtailLoggerService implements ILogger {
  private logtailInstance: Logtail | null = null;

  constructor(token?: string) {
    if (token !== null && token !== undefined) {
      this.logtailInstance = new Logtail(token);
    }
  }

  setInstance(token: string): void {
    this.logtailInstance = new Logtail(token);
  }

  private async captureLog(level: string, message: string, context: Context): Promise<void> {
    if (this.logtailInstance !== null) {
      await this.logtailInstance.log(message, message, { level, ...constants, ...context });
    } else {
      console.log(`${level}:`, message, context);
    }
  }

  async log(message: string, context: Context): Promise<void> {
    await this.captureLog('info', message, context);
  }

  async info(message: string, context: Context): Promise<void> {
    await this.captureLog('info', message, context);
  }

  async warn(message: string, context: Context): Promise<void> {
    await this.captureLog('warn', message, context);
  }

  async error(message: string, context: Context): Promise<void> {
    await this.captureLog('error', message, context);
  }

  async debug(message: string, context: Context): Promise<void> {
    await this.captureLog('debug', message, context);
  }

  async fatal(message: string, context: Context): Promise<void> {
    await this.captureLog('fatal', message, context);
  }

  async verbose(message: string, context: Context): Promise<void> {
    await this.captureLog('verbose', message, context);
  }
}

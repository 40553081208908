import React, { useCallback, useEffect, useRef, useState } from "react";

interface UseRetrySettings {
  /**
   * The number of seconds to wait before retrying.
   * @default 10 seconds
   */
  delaySeconds?: number;
}

export interface RetryTimer {
  startRetryTimer: (callback?: () => unknown) => void;
  isWaitingRetry: boolean;
  retryDelay: number;
}

/**
 * @description A hook that will start a timer when called. The timer will wait for the
 *  specified number of seconds (default 10 sec.) before setting the `isWaitingRetry` flag to false
 *  and decrementing the `retryDelay` by 1 second.
 *
 *  This hook is useful for retrying an operation after a certain amount of time.
 *  For example, if you want to retry an API call after 10 seconds, or if you want to
 *  block a user action after retry again.
 *
 *  @example
 *  const { startRetryTimer, isWaitingRetry, retryDelay } = UseRetryTimer({ delaySeconds: 10 });
 *
 *  const retry = async () => {
 *  if(isWaitingRetry) {
 *    return;
 *  }
 *  startRetryTimer();
 *  await apiCall();
 * }
 *
 *  return (
 *    <button onClick={retry}>{ isWaitingRetry ? `Retry again in ${retryDelay} sec.` : "Retry"}</button>
 *  )
 *
 *  @param {UseRetrySettings} settings - The settings for the hook.
 *
 *  @returns {RetryTimer} - The current retry timer object states.
 *
 * */
const UseRetryTimer = (settings?: UseRetrySettings): RetryTimer => {
  const { delaySeconds = 10 } = settings || {};

  const callbackRef = useRef<() => unknown>();
  const [retryDelay, setRetryDelay] = useState<number>(delaySeconds);
  const [isWaitingRetry, setIsWaitingRetry] = useState<boolean>(false);

  const startRetryTimer = (callback?: () => void) => {
    if (!!callback) {
      callbackRef.current = callback;
    }

    setIsWaitingRetry(true);
  };

  const resetHookStates = useCallback(() => {
    setIsWaitingRetry(false);
    setRetryDelay(delaySeconds);
    callbackRef.current = undefined;
  }, [delaySeconds]);

  useEffect(() => {
    /** Timeout to reset the hook states and execute the callback if needed */
    let timer: NodeJS.Timeout;

    /** Interval to decrement the retry delay */
    let retryDelayInterval: NodeJS.Timeout;

    if (isWaitingRetry) {
      timer = setTimeout(() => {
        callbackRef?.current && callbackRef.current();
        resetHookStates();
      }, (delaySeconds + 1) * 1000);

      retryDelayInterval = setInterval(() => {
        setRetryDelay((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
      clearInterval(retryDelayInterval);
    };
  }, [delaySeconds, isWaitingRetry, resetHookStates]);

  return {
    startRetryTimer,
    isWaitingRetry,
    retryDelay,
  };
};

export default UseRetryTimer;

import { Timezone } from "../../types/TimezoneTypes";

const getAvailableTimezones = (): Array<Timezone> => {
  return [
    Timezone.UTC,
    Timezone.Canada_Atlantic,
    Timezone.US_Hawaii,
    Timezone.US_Alaska,
    Timezone.US_Pacific,
    Timezone.US_Mountain,
    Timezone.US_Central,
    Timezone.US_Eastern,
    Timezone.Mexico_BajaCalifornia,
    Timezone.Mexico_Chihuahua,
    Timezone.Mexico_General,
    Timezone.America_CentralAmerica,
  ];
};

export default getAvailableTimezones;

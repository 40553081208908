import axios from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import routes from '../../../../../src/constants/routes';
import { type Account } from './entities/account.entity';

const getHubspotAccountsAsync = async (
  userId: string,
  orgId: string,
  isCustom = false,
): Promise<ApiResponse<Account[]>> => {
  try {
    const hubspotAccounts = await axios.get<Account[]>(
      `${
        isCustom ? routes.get.customHubspot.getLinkedAccounts : routes.get.hubspot.getLinkedAccounts
      }/${orgId}/${userId}`,
    );

    return { data: hubspotAccounts.data, success: true };
  } catch (error: unknown) {
    return {
      error: { message: 'Error while getting sequences' },
      success: false,
    };
  }
};

export default getHubspotAccountsAsync;

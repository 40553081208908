import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";

type ChronometerProps = {
  isTimerOn: boolean;
};

const ChronometerComponent = ({
  isTimerOn = false,
}: ChronometerProps): ReactElement => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval: any = null;
    if (isTimerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isTimerOn]);

  return (
    <ChronometerWrapper>
      <ChronometerDisplay>
        <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
        <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
      </ChronometerDisplay>
    </ChronometerWrapper>
  );
};

const ChronometerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const ChronometerDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkGray};
`;

export default ChronometerComponent;

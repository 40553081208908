import routes from "../../constants/routes";
import axios from "axios";
import { ApiResponse } from "../../types/ApiResponse";
import { StatusResponse } from "../../types";

const recover = async (email: string): Promise<ApiResponse<StatusResponse>> => {
  try {
    const data = {
      email,
    };

    const response = await axios.post(
      routes.post.users.password.recoverNest,
      data
    );
    return { data: { status: response.status } };
  } catch (e) {
    return {
      error: { message: "Error recovering password, try again later." },
    };
  }
};

export default recover;

import React, { type FC } from 'react';
import { motion } from 'framer-motion';
import tabTitle from '../../../../../../../src/utils/updateTitle';
import HubspotMappingsViewModel from './HubspotMappings.viewmodel';
import animations from '../../../../../../../src/core/ui/base/Animations';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { GearLoader } from '../../../../../Common/Loaders/GearLoader';
import MappingRow from './Components/MappingRow.component';
import MappingActions from './Components/MappingActions.component';
import NoLinkedAccounts from '../../../../../Common/Integrations/NoLinkedAccounts.component';

const { table: Table, div: Div } = motion;

const HubspotMappingsView: FC = () => {
  tabTitle('Tendril Connect | Settings | Hubspot Mappings');

  const viewModel = HubspotMappingsViewModel();

  const {
    viewState: { isTableLoading, isHubspotAccount, isAdmin, isSuperAdmin, isHubspotCustomFeature, isHubspotFeature },
    mappings,
  } = viewModel;

  const isNotLinkedAccount = !isHubspotAccount && (isAdmin || isSuperAdmin);

  const showTable = !isNotLinkedAccount && !isTableLoading;

  const showHubspotWarn = isHubspotCustomFeature && isHubspotFeature && (isAdmin || isSuperAdmin);

  return (
    <>
      <Div className="grid grid-cols-12 gap-4 px-2 py-6" {...animations.fadeInFromTop}>
        <div className="col-span-12">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <MappingActions viewModel={viewModel} />
            </div>
            <div className="col-span-12">
              <div className="flex min-h-full gap-2 pt-2 justify-start place-items-center">
                <FA icon={faTriangleExclamation} size="lg" className="py-1 px-1.5 rounded-full text-app-blue-light" />
                <p className="w-full max-w-2xl text-lg">Organization-Wide Mapping Dispositions</p>
              </div>
            </div>

            {showHubspotWarn && (
              <div className="col-span-12">
                <div className="flex min-h-full gap-2 pt-2 justify-start place-items-center">
                  <FA icon={faTriangleExclamation} size="lg" className="py-1 px-1.5 rounded-full text-app-blue-light" />
                  <p className="w-full max-w-2xl text-lg">
                    HubSpot and HubSpot Custom share the same call dispositions.
                  </p>
                </div>
              </div>
            )}

            <div className="col-span-12">
              <div className="flex min-h-full gap-2 pt-2 justify-start place-items-center">
                <FA
                  icon={faQuestion}
                  size="lg"
                  className="border-solid border-2 border-app-blue-light py-1 px-2 rounded-full text-app-blue-light"
                />
                <p className="w-full max-w-2xl text-lg">
                  Create or edit disposition mappings. These mappings will be synced back as a result of your calls.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Div>

      {isTableLoading && <GearLoader message="Loading dispositions mappings, please wait..." />}

      {showTable && (
        <div className="relative overflow-x-hidden overflow-y-auto w-full md:max-h-[20rem] lg:max-h-[25rem] xl:max-h-[31rem] 2xl:max-h-[42rem]">
          <Table className="table w-full h-full px-2 pt-6" {...animations.fadeInFromTop}>
            <thead className="table-header-group overflow-none sticky top-0 bg-white bg-opacity-75 overflow-hidden">
              <tr className="table-row text-lg leading-relaxed text-gray-500  overflow-none">
                <th className="table-cell text-left p-2">Tendril Disposition</th>
                <th className="table-cell text-center p-2">Mapped to</th>
                <th className="table-cell text-left p-2">Custom Disposition</th>
              </tr>
            </thead>
            <tbody className="table-row-group">
              {mappings.map((row, index) => (
                <MappingRow key={`${index} + ${row.abbreviation}`} viewModel={viewModel} mapping={row} />
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {isNotLinkedAccount && !showTable && !isTableLoading && (
        <NoLinkedAccounts goToLink={'/settings/integrations/hubspot/accounts'} />
      )}
    </>
  );
};

export default HubspotMappingsView;

import { type CallSession } from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import { type ApiResponse } from '@/src/types/ApiResponse';
import axios from 'axios';
import routes from '@/src/constants/routes';
import { type UpdateCallSessionDto } from '@datasource/contacts-nest/callSession/entities/updateCallSessionDto';

async function updateCallSessionAsync(
  callSessionId: string,
  payload: UpdateCallSessionDto,
): Promise<ApiResponse<CallSession>> {
  try {
    const callSession = await axios.put<CallSession>(`${routes.put.callSession.updateNest}/${callSessionId}`, payload);
    return { data: callSession.data };
  } catch {
    return { error: { message: 'The requested call session could not been updated' }, success: false };
  }
}

export default updateCallSessionAsync;

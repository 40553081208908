import React, { ReactElement, useEffect, useState } from "react";
import { BaseDropdown, BaseField } from "../../../../core/ui/base/FormElements";
import getAvailableTimezones from "../../../../api/Users/getAvailableTimezones";
import { UICol, UIRow } from "../../../../core/ui/UIStructures";
import LabelWithTooltip from "../../../../components/Form/LabelWithTooltip.component";
import { Timezone } from "../../../../types/TimezoneTypes";

interface Props {
  timezoneSelectRef: React.RefObject<HTMLSelectElement>;
  handleUserDataChange: (userData: any) => void;
  currentUser: any;
}
const TimezoneDropdown = ({
  timezoneSelectRef,
  handleUserDataChange,
  currentUser,
}: Props): ReactElement => {
  const [currentUserTimezone, setCurrentUserTimezone] = useState<Timezone>(
    Timezone.UTC
  );
  const timezoneOptions: Timezone[] = getAvailableTimezones();

  useEffect(() => {
    if (currentUser.timezone) {
      setCurrentUserTimezone(currentUser.timezone);
    }
  }, [currentUser.timezone]);

  return (
    <UIRow justifyContent="flex-end" alignItems="center">
      <UICol sm={6}>
        <LabelWithTooltip
          label={{ text: "Timezone", htmlFor: "timezone" }}
          tooltip={{
            text: "Helps you to display information that is relevant <br /> into your current location time.",
          }}
        />
      </UICol>
      <UICol sm={6}>
        <BaseField>
          <BaseDropdown
            name="timezone"
            onChange={(e) => handleUserDataChange(e)}
            ref={timezoneSelectRef}
            data-qa-id={"timezoneEditDropdown"}
          >
            <option value={currentUserTimezone}>{currentUserTimezone}</option>
            {timezoneOptions.map(
              (tz) =>
                tz !== currentUserTimezone && (
                  <option data-qa-id={`option_${tz}`} key={tz} value={tz}>
                    {tz}
                  </option>
                )
            )}
          </BaseDropdown>
        </BaseField>
      </UICol>
    </UIRow>
  );
};

export default TimezoneDropdown;

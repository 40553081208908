import routes from "../../constants/routes";
import axios from "axios";
import { DispositionMetrics } from "../../components/shared/Metric";
import { ApiResponse } from "../../types/ApiResponse";

const getBuildDispositionMetricsAsync = async (
  callSessionId: string
): Promise<ApiResponse<DispositionMetrics>> => {
  try {
    const metrics = await axios.get<DispositionMetrics>(
      `${routes.get.metrics.buildDispositionMetricsNest}/${callSessionId}`
    );
    return { data: metrics?.data, success: true };
  } catch (e) {
    return {
      success: false,
      error: {
        message: "Error getting build disposition metrics",
      },
    };
  }
};

export default getBuildDispositionMetricsAsync;

import routes from '../../../../../src/constants/routes';
import axios, { type AxiosResponse } from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';

const getLoggerTokenAsync = async (): Promise<ApiResponse<string>> => {
  try {
    const loggerTokenUrl = `${routes.get.logger.token}`;
    const loggerToken: AxiosResponse<string> = await axios.get(loggerTokenUrl);
    return { data: loggerToken.data, success: true };
  } catch (e) {
    return { error: { message: 'Error getting the requested logger token' }, success: false };
  }
};

export default getLoggerTokenAsync;

import routes from "../../constants/routes";
import axios from "axios";

type ResponseProps = {
  sdrIds?: string[];
  organizationIds?: string[];
  error?: { message: string };
};
export const getSdrIdsByNameOrOrganization = async (
  encodeSearch: string
): Promise<ResponseProps> => {
  try {
    const { data } = await axios.get(
      `${routes.get.dashboard.getSdrIdsByNameOrOrganizationNest}?name=${encodeSearch}`
    );
    return {
      sdrIds: data.sdrIds,
      organizationIds: data.organizationIds,
    };
  } catch (e) {
    return {
      error: { message: "Error getting ids, try again later." },
    };
  }
};

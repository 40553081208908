import React from "react";
import styled from "styled-components";
import { Box, Flex, Button, Image, Text, BoxProps } from "../../core/ui";
import TendrilIcon from "../../assets/icons/tendril_white.svg";
import { useTheme } from "styled-components";

export const Container = styled(Flex)`
  max-width: ${({ theme }) => theme.maxWidth};
  margin: auto;
  box-sizing: border-box;
`;

export const InnerContainer = styled(Flex)`
  width: 100%;
  align-items: center;
  position: relative;
`;

export const CircleButton = styled(Button)<{
  width: number;
  bg: string;
  outsideBg: string;
}>`
  position: relative;
  border-radius: 50%;
  margin: ${({ width }) => width * 0.15}px;
  transform-style: preserve-3d;
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, -1px);
    width: 130%;
    height: 130%;
    background: ${({ outsideBg }) => outsideBg};
    border-radius: 50%;
  }
`;

/** @deprecated use {@link UIIconButton} instead */
export const TransparentButton = styled(Button)`
  padding: 0;
  border-radius: 0;
  background: transparent;
`;

const AlertBoxInner = styled(Flex)`
  align-items: center;
  justify-content: center;
  min-width: 240px;
  padding: 13px 0;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
`;

type AlertBoxProps = {
  icon: string;
  text: string;
  bg?: string;
};

export const AlertBox = ({
  icon,
  text,
  ...rest
}: AlertBoxProps): JSX.Element => (
  <AlertBoxInner bg={useTheme().statusColors.callBtnBg} {...rest}>
    <Image src={icon} width={25} mr={20} ml={20}></Image>
    <Text fontWeight="600" fontSize={20} color="white" mr={20}>
      {text}
    </Text>
  </AlertBoxInner>
);

export const Card = styled(Flex)<{ flex?: string; padding?: string }>`
  flex: ${({ flex }) => flex ?? 1};
  height: 100%;
  padding: ${({ padding }) => (padding ? padding : "20px 12px")};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};
  border-radius: 20px;
  overflow: hidden;
`;

export const CardsContainer = styled(Flex)`
  flex: 1;
  gap: 20px;
  height: 410px;
`;

const FooterContainer = styled(Box)`
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  justify-content: center;
`;

export const Footer = (props: BoxProps): JSX.Element => (
  <FooterContainer {...props}>
    <Image src={TendrilIcon} width="150px" alt="Tendril icon" />
  </FooterContainer>
);

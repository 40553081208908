import { ApiResponse } from "../../types/ApiResponse";
import { Contact } from "../../types/ContactTypes";
import axios from "axios";
import routes from "../../constants/routes";

const updateContactAsync = async (
  contactId: string,
  contact: Partial<Contact>
): Promise<ApiResponse<Contact>> => {
  try {
    const response = await axios.put<Contact>(
      `${routes.put.contacts.update}/${contactId}`,
      contact
    );
    return { success: true, data: response.data };
  } catch (e) {
    return { error: { message: "Something went wrong, try later" } };
  }
};

export default updateContactAsync;

import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SideBar from "../SideBar/SideBar";
import { UserProviderContext } from "../app/UserProvider";
import IdleTimerContainer from "../session/InactivitySession";
import ExpiredSessionModal from "../session/ExpiredSessionModal";
import jwt_decode from "jwt-decode";
import { refreshSessionToken } from "../../core/utils/sessionRefresher";
import { featuresStore } from "../app/FeaturesProvider";

import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import {
  UIMain,
  UIHeader,
  UIBurgerButton,
  UIContainer,
  UIButton,
} from "../../core/ui/UIElements";

import { useAppContext } from "../../libs/contextLib";
import { getUserInfoAsync } from "../../api/Users/getUserInfo";
import useScreenSize from "../../core/hooks/useScreenSize";
import UserAvatarImage from "../../../src-v2/Presentation/Views/Settings/Account/Components/UserAvatarImage.component";

const MINUTE_TO_EXPIRATION = 1000 * 60 * 90;

type Props = {
  sidebar?: boolean;
  user?: string;
  children: ReactNode;
  handleLogout?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intervals: { [key: string]: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIntervals: (value: any) => void | undefined;
  callStatus?: string | undefined;
  sdrStatus?: string | undefined;
  isSettings?: boolean | undefined;
  tabsIsEmpty?: boolean | undefined;
};

type decoded = {
  exp: number;
};

const Layout = ({
  sidebar,
  children,
  handleLogout,
  intervals,
  setIntervals,
  callStatus,
  sdrStatus,
  isSettings,
  tabsIsEmpty,
}: Props): JSX.Element => {
  const { currentUser, clearCurrentUser } = useContext(UserProviderContext);
  const { isAuthenticated } = useAppContext();
  const { resetAllFeatures } = useContext(featuresStore);

  const screenSize = useScreenSize();
  const isSdr = currentUser?.type?.some((user) => user.type === "sdr");
  const isAutoHideMenuAvailable: boolean = currentUser.isSuperAdmin || isSdr;
  const [menuStatus, setMenuStatus] = useState(
    isAutoHideMenuAvailable ? screenSize.width > 1180 : true
  );

  const idleTimerRef = useRef(null);
  const [showModalTimer, setShowModalTimer] = useState<boolean>(false);

  const logout = () => {
    if (handleLogout) {
      handleLogout();
      clearCurrentUser();
      resetAllFeatures();
    }
  };

  useEffect(() => {
    let timerId;
    if (isAuthenticated) {
      timerId = setInterval(async function () {
        const token = window.localStorage.getItem("target") || "";

        if (token === "") {
          logout();
        }

        const decoded: decoded = jwt_decode(token);

        if (
          Date.now() >= decoded.exp * 1000 - MINUTE_TO_EXPIRATION &&
          Date.now() <= decoded.exp * 1000
        ) {
          await refreshSessionToken();
        } else if (Date.now() > decoded.exp * 1000) {
          logout();
        }
      }, 1000);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [isAuthenticated]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const { user, error } = await getUserInfoAsync(Layout["user"]);
    };
    if (intervals?.layoutUserInfo) {
      clearInterval(intervals["layoutUserInfo"]);
    }
    const newInterval = intervals || {};
    newInterval["layoutUserInfo"] = setInterval(() => {
      fetchUserInfo;
    }, 300000);
    setIntervals(newInterval);
  }, []);

  useEffect(() => {
    isAutoHideMenuAvailable && setMenuStatus(screenSize.width > 1180);
  }, [screenSize.width, isAutoHideMenuAvailable]);

  return (
    <UIMain
      className={`${menuStatus ? "open" : "closed"} ${sidebar && "sidebar"}`}
    >
      <IdleTimerContainer
        pageRef={idleTimerRef}
        timedOutHandler={setShowModalTimer}
      />
      {showModalTimer && (
        <ExpiredSessionModal
          logoutAction={logout}
          modalTimer={showModalTimer}
          setModalTimer={setShowModalTimer}
        />
      )}
      <UIHeader>
        {sidebar && (
          <UIBurgerButton onClick={() => setMenuStatus(!menuStatus)}>
            {menuStatus ? (
              <FA icon={["fas", "bars"]} />
            ) : (
              <FA icon={["fas", "xmark"]} />
            )}
          </UIBurgerButton>
        )}
        <UserAvatarImage src="" twClass="object-fill rounded-full w-12 h-12 relative padding-auto" />
        <div>
          <span>Welcome</span> {currentUser.userName}
        </div>
        <UIButton
          data-qa-id="button-logout"
          onClick={logout}
          style={{ marginLeft: "auto" }}
        >
          <FA icon={["fas", "power-off"]} />
          Logout
        </UIButton>
      </UIHeader>
      {sidebar && (
        <SideBar
          intervals={intervals}
          setIntervals={setIntervals as (value: any) => void}
          callStatus={callStatus}
          sdrStatus={sdrStatus}
        />
      )}
      <UIContainer isSettings={isSettings} tabsIsEmpty={tabsIsEmpty}>
        {children}
      </UIContainer>
    </UIMain>
  );
};

export default Layout;

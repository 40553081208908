import React, { type ReactElement } from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

interface GearLoaderProps {
  message?: string;
}
export function GearLoader(gearLoader: GearLoaderProps): ReactElement {
  const message = gearLoader.message ?? 'Loading data, please wait...';

  return (
    <div role="status" className="grid place-items-center text-app-gray-400">
      <FA icon={faGear} spin size="6x" />
      <span className="pt-4 text-lg">{message}</span>
    </div>
  );
}

import routes from "../../constants/routes";
import axios from "axios";
import { CallLog } from "../../types/CallLogTypes";
import { ApiResponse } from "../../types/ApiResponse";
import { logAxiosErrorWithSentry } from "../../core/utils/axios.utils";

const getCallLog = async (callId: string): Promise<ApiResponse<CallLog>> => {
  try {
    const callLog = await axios.get<CallLog>(
      routes.get.callLogs.callLogNest + `callId/${callId}`
    );

    return { success: true, data: callLog.data[0] };
  } catch (e) {
    const message = " Something happened getting the call log";

    if (axios.isAxiosError(e)) {
      logAxiosErrorWithSentry({
        payload: e,
        description: message,
      });
    }
    return { success: false, error: { message } };
  }
};

export default getCallLog;

import { CallSessionRepository } from '@repository/contacts-nest/callSession/callSessionRepository';
import {
  type ActiveCallSession,
  type CallSession,
} from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type UpdateCallSessionDto } from '@datasource/contacts-nest/callSession/entities/updateCallSessionDto';

interface ActiveSessionsUseCaseProps {
  getActiveSessionsInfo: () => Promise<ApiResponse<ActiveCallSession[]>>;
  getCallSession: (callSessionId: string) => Promise<ApiResponse<CallSession>>;
  updateSessionStatus: (callSessionId: string, payload: UpdateCallSessionDto) => Promise<ApiResponse<CallSession>>;
}
function ActiveSessionsUseCase(): ActiveSessionsUseCaseProps {
  const { getActiveCallSessions, updateCallSession, getCallSessionById } = CallSessionRepository();

  return {
    async getActiveSessionsInfo() {
      const { data, error } = await getActiveCallSessions();
      if (data === null) {
        return { error };
      }
      return { data };
    },

    async getCallSession(callSessionId) {
      const { data, error } = await getCallSessionById(callSessionId);
      if (data === null) {
        return { error };
      }
      return { data };
    },

    async updateSessionStatus(callSessionId, payload) {
      const { data, error } = await updateCallSession(callSessionId, payload);
      if (data === null) {
        return { error };
      }
      return { data };
    },
  };
}

export default ActiveSessionsUseCase;

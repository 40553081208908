import React, { type ButtonHTMLAttributes, type ReactElement } from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getClassNames } from './buttons.utils';

interface ButtonDeleteProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type: 'submit' | 'button' | 'reset';
  text?: string;
  loading?: boolean;
}

export default function ButtonDelete({
  loading,
  text,
  type,
  ...buttonProps
}: ButtonDeleteProps): ReactElement<HTMLButtonElement> {
  return (
    <button
      type={type}
      disabled={loading === true || buttonProps?.disabled}
      name={'Delete'}
      className={getClassNames('danger')}
      {...buttonProps}
    >
      {loading === true ? (
        <FA icon={faSpinner} spinPulse size="lg" name="close" />
      ) : (
        <FA icon={faTrash} size="lg" name="Delete Icon" />
      )}
      {text ?? 'Delete'}
    </button>
  );
}

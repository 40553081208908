import routes from "../../constants/routes";
import axios from "axios";

type ResponseProps = {
  success?: boolean;
  error?: { message: string };
};

type Props = {
  callId: string | undefined;
  recordingUrl: string;
};

const playRecordingAsync = async ({
  callId,
  recordingUrl,
}: Props): Promise<ResponseProps> => {
  try {
    await axios.post(
      routes.post.recordings.playRecordingNest +
        `${callId}/recording?recordingUrl=${recordingUrl}`,
      { recordingUrl }
    );
    return { success: true };
  } catch (e) {
    return { error: { message: "Error getting recordings" } };
  }
};

export default playRecordingAsync;

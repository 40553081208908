import React from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { theme } from "../theme";

const rotateAnimation = keyframes`
  0% { transform: rotate(0deg) }
  20% { transform: rotate(72deg) }
  40% { transform: rotate(144deg) }
  60% { transform: rotate(216deg) }
  80% { transform: rotate(288deg) }
  100% { transform: rotate(360deg) }
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1em;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3.75px);
  -webkit-backdrop-filter: blur(3.75px);
  color: ${theme.colors.darkGray};
  font-size: 4rem;
`;

const IconRotating = styled(FA)`
  animation-name: ${rotateAnimation};
  animation-duration: 5s;
  animation-iteration-count: infinite;
`;

const LoaderMessage = styled.div`
  font-size: 1.25rem;
  line-height: 1.5;
`;

export type LoaderProps = {
  message: string;
};

const Loader: React.FC<LoaderProps> = (props) => {
  const { message } = props;
  return (
    <LoaderContainer>
      <IconRotating icon={["fas", "gear"]} />
      <LoaderMessage>{message}</LoaderMessage>
    </LoaderContainer>
  );
};

export default Loader;

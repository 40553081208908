import React, { useContext } from "react";
import styled from "styled-components";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Flex } from "../../core/ui";
import { InnerContainer, Card } from "../../components/shared";
import { UserProviderContext } from "../../components/app/UserProvider";
import Layout from "../../components/shared/Layout";
import UserManagementContainer from "../../components/management/usersManagement/UsersManagementContainer";
import OrgsManagementContainer from "../../components/management/organizationsManagement/OrgsManagementContainer";
import NumbersManagementContainer from "../../components/management/numbersManagement/NumbersManagementContainer";

import tabTitle from "../../utils/updateTitle";
import { featuresStore } from "../../components/app/FeaturesProvider";
import {useOutletContext} from "react-router-dom";

const StyledTabs = styled(Tabs)`
  height: 100%;
`;

const StyledTabList = styled(TabList)`
  display: flex;
  margin-bottom: 1rem;
`;

const StyledTab = styled(Tab)`
  flex: 1;
  padding: 20px 25px;
  background: ${({ theme }) => theme.colors.lightBlue};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  &:nth-child(2) {
    left-right: 1px solid ${({ theme }) => theme.colors.lightGray};
  }

  &.react-tabs__tab--selected {
    box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 50%);
    background: ${({ theme }) => theme.colors.darkBlue};
  }
`;

//set height 100% depending if is active;
const StyledTabPanel = styled(TabPanel)`
  &.react-tabs__tab-panel--selected {
    height: 100%;
  }
`;

const Management = (): JSX.Element => {
  const {handleRoutesLogout, intervals, setIntervals} = useOutletContext() as any;
  const {
    currentUser: { isSuperAdmin },
  } = useContext(UserProviderContext);

  const { featuresState } = useContext(featuresStore);
  const { numbersPool } = featuresState;
  const showNumbers = isSuperAdmin || numbersPool;

  tabTitle("Tendril Connect | Management");

  return (
    <Layout
      sidebar
      handleLogout={handleRoutesLogout}
      intervals={intervals}
      setIntervals={setIntervals}
    >
      <InnerContainer px="80px">
        <Flex width="100%" height="90%">
          <Card flexDirection="column" padding="0">
            <StyledTabs>
              <StyledTabList>
                <StyledTab>Users</StyledTab>
                {isSuperAdmin && <StyledTab>Organizations</StyledTab>}
                {showNumbers && <StyledTab>Numbers</StyledTab>}
              </StyledTabList>
              <StyledTabPanel>
                <UserManagementContainer />
              </StyledTabPanel>
              {isSuperAdmin && (
                <StyledTabPanel>
                  <OrgsManagementContainer />
                </StyledTabPanel>
              )}
              {showNumbers && (
                <StyledTabPanel>
                  <NumbersManagementContainer />
                </StyledTabPanel>
              )}
            </StyledTabs>
          </Card>
        </Flex>
      </InnerContainer>
    </Layout>
  );
};

export default Management;

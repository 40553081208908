import React from "react";
import { faPhoneFlip, faPuzzlePiece, faTags, faUser, faUserCircle, faHeadset} from "@fortawesome/free-solid-svg-icons";
import { customOutreachLogo } from "../../core/ui/icons/CustomOutreachLogo";
import { customHubspotLogo } from "../../core/ui/icons/CustomHubspotLogo";
import { SettingMenuOption } from "./Shared/types/settings-menu-option.type";
import { PrivateRoutes } from "../../constants/routesConnect";
import { Badge } from '../../../src-v2/Presentation/Common/Components/ui/badge';

const {
  SETTINGS_ACCOUNT,
  SETTINGS_ACCOUNT_V2,
  SETTINGS_PHONE,
  SETTINGS_ACTIVE_SESSIONS,

  SETTINGS_OUTREACH,
  SETTINGS_OUTREACH_MAPPINGS,

  SETTINGS_HUBSPOT,
  SETTINGS_HUBSPOT_MAPPINGS,

  SETTINGS_HUBSPOT_CUSTOM,
  SETTINGS_HUBSPOT_CUSTOM_MAPPINGS,
} = PrivateRoutes;

export const SETTINGS_MENU_OPTIONS: Array<SettingMenuOption> = [
  {
    label: "Account",
    icon: faUserCircle,
    link: SETTINGS_ACCOUNT,
    isAvailable: false,
    hasSubMenu: false,
  },
  {
    label: "Account v2",
    customLabel: <BetaFeatureLabel name="Account v2"/>,
    icon: faUserCircle,
    link: SETTINGS_ACCOUNT_V2,
    isAvailable: false,
    hasSubMenu: false,
  },
  {
    label: "Phone",
    icon: faPhoneFlip,
    link: SETTINGS_PHONE,
    isAvailable: false,
    hasSubMenu: false,
  },
  {
    label: "Active Call Sessions",
    icon: faHeadset,
    link: SETTINGS_ACTIVE_SESSIONS,
    isAvailable: false,
    hasSubMenu:false,
  },
  {
    label: "Integrations",
    icon: faPuzzlePiece,
    link: "integrations/",
    hasSubMenu: true,
    isAvailable: false,
    options: [
      {
        label: "Outreach",
        icon: customOutreachLogo,
        link: "outreach/",
        isAvailable: false,
        hasSubMenu: true,
        options: [
          {
            label: "Accounts",
            icon: faUser,
            link: SETTINGS_OUTREACH,
            isAvailable: true,
            hasSubMenu: false,
          },
          {
            label: "Mappings",
            icon: faTags,
            link: SETTINGS_OUTREACH_MAPPINGS,
            isAvailable: true,
            hasSubMenu: false,
          },
        ],
      },
      {
        label: "Hubspot",
        icon: customHubspotLogo,
        link: "hubspot/",
        isAvailable: false,
        hasSubMenu: true,
        options: [
          {
            label: "Accounts",
            icon: faUser,
            link: SETTINGS_HUBSPOT,
            isAvailable: true,
            hasSubMenu: false,
          },
          {
            label: "Mappings",
            icon: faTags,
            link: SETTINGS_HUBSPOT_MAPPINGS,
            isAvailable: true,
            hasSubMenu: false,
          },
        ],
      },
      {
        label: "Hubspot Custom",
        customLabel: <CustomHubspotLabel />,
        icon: customHubspotLogo,
        link: "hubspot-custom/",
        isAvailable: false,
        hasSubMenu: true,
        options: [
          {
            label: "Accounts",
            icon: faUser,
            link: SETTINGS_HUBSPOT_CUSTOM,
            isAvailable: true,
            hasSubMenu: false,
          },
          {
            label: "Mappings",
            icon: faTags,
            link: SETTINGS_HUBSPOT_CUSTOM_MAPPINGS,
            isAvailable: true,
            hasSubMenu: false,
          },
        ],
      },
    ],
  },
];

function CustomHubspotLabel() {
  return (
      <span className="flex w-full justify-between gap-1">
          Hubspot
          <Badge variant="outline" className="bg-app-integrations-hubspot text-white border-orange-300">Custom</Badge>
      </span>
    );
}

function BetaFeatureLabel({name}:{name: string}) {
  return (
    <span className="flex w-full justify-between gap-1">
        {name}
        <Badge variant="outline" className="bg-app-blue-lighter text-white border-sky-200">Beta</Badge>
    </span>
  );
}

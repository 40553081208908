import axios from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import routes from '../../../../../src/constants/routes';
import { type CallSession } from './entities/callSession.entity';

const getCallSessionsBySdrId = async (sdrId: string, orgId: string): Promise<ApiResponse<CallSession[]>> => {
  try {
    const callSession = await axios.get<CallSession[]>(
      routes.get.callSession.getBySdrIdNest + `/${sdrId}/orgId/${orgId}`,
    );
    return { data: callSession.data };
  } catch {
    return { error: { message: 'Error getting call sessions' } };
  }
};

export default getCallSessionsBySdrId;

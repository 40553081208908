import React, { type ButtonHTMLAttributes, type ReactElement } from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { getClassNames } from './buttons.utils';

interface ButtonIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconDefinition;
  iconType?: 'only-icon' | 'close-modal';
  loading?: boolean;
}

export default function ButtonOnlyIcon({
  loading,
  icon,
  iconType = 'only-icon',
  ...buttonProps
}: ButtonIconProps): ReactElement<HTMLButtonElement> {
  return (
    <button
      type="button"
      disabled={loading === true || buttonProps?.disabled}
      className={getClassNames(iconType)}
      {...buttonProps}
    >
      {loading === true ? (
        <FA icon={faSpinner} spinPulse size="lg" name="close" />
      ) : (
        <FA icon={icon} size="lg" name="button" />
      )}
    </button>
  );
}

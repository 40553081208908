import React, { StrictMode } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import './index.css';
import UserProvider from './components/app/UserProvider';
import { LoggerProvider } from './components/app/LoggerProvider';
import { init } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { config } from './config';
import constants from './constants.json';
import TagManager from 'react-gtm-module';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { createRoot } from 'react-dom/client';
import Login from './pages/Login/Login';
import { PrivateRoutes, PublicRoutes } from './constants/routesConnect';
import RoutesLayout from './components/RoutesLayout';
import DialerSDR from './pages/Dialer/DialerSDR';
import Dashboard from './pages/Dashboard/Dashboard';
import Settings from './pages/Settings/Settings';
import DialerAgent from './pages/Dialer/DialerAgent';
import Management from './pages/Management/Management';
import Summary from './pages/Dashboard/Summary';
import PasswordChange from './pages/Login/PasswordChange';
import PasswordRecover from './pages/Login/PasswordRecover';
import Contacts from './pages/Contacts/Contacts';
import Voice from './pages/Voice/Voice';
import Script from './pages/Script/Script';
import Account from './pages/Settings/Account/Account';
import CallerIdContainer from './pages/Settings/CallerId/CallerIdContainer';
import { Outreach } from './pages/Settings/Outreach/Outreach';
import OutreachMappingsView from '../src-v2/Presentation/Views/Settings/Integrations/Outreach/Mappings/OutreachMappings.view';
import { Hubspot } from './pages/Settings/Hubspot/Hubspot';
import HubspotMappingsView from '../src-v2/Presentation/Views/Settings/Integrations/Hubspot/Mappings/HubspotMappings.view';
import { HubspotCustomApp } from './pages/Settings/HubspotApp/HubspotCustomApp';
import { FeatureFlags, FeaturesProvider } from './components/app/FeaturesProvider';
import AuthGuard from './guards/auth.guard';
import RoleGuard from './guards/role.guard';
import { Roles } from './types/Roles';
import UIToastContainer from './core/ui/UIToast';
import AccountView from '../src-v2/Presentation/Views/Settings/Account/Account.view';
import { FeatureFlagGuard } from './guards/feature-flag.guard'
import { Toaster } from "../src-v2/Presentation/Common/Components/ui/toaster";
import SessionsView from "../src-v2/Presentation/Views/Settings/ActiveSessions/Sessions.view";

const window = globalThis as any;
const document = window.document;

library.add(fas)
const tagManagerArgs = {
  gtmId: config.analytics.tagManager
}
TagManager.initialize(tagManagerArgs)

init({
  dsn: 'https://8eafc96948a048e68a13ba9133cf2ca4@o1282397.ingest.sentry.io/6489830',
  release: constants.version,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: config.sentry.tracesSampleRate,
  environment: config.sentry.environment,
  debug: false,
  enabled: config.sentry.environment !== 'local'
});
const App = () => {
  return (
    <>
      <UserProvider>
        <FeaturesProvider>
          <>
            <UIToastContainer />
            <Toaster />
            <LoggerProvider>
            <RouterProvider router={JSXRouter} />
            </LoggerProvider>
          </>
        </FeaturesProvider>
      </UserProvider>
    </>
  )
}

export const JSXRouter = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path={PublicRoutes.LOGIN} element={<Login />} />
    <Route path={PublicRoutes.PASSWORD_CHANGE} element={<PasswordChange />} />
    <Route path={PublicRoutes.PASSWORD_RECOVER} element={<PasswordRecover />} />
    <Route path={'*'} element={<AuthGuard />}>
      <Route element={<RoutesLayout />}>
        <Route path={PrivateRoutes.HOME}
          element={
            <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
              <DialerSDR />
            </RoleGuard>
          }
        />
        <Route path={PrivateRoutes.DIALER}
          element={
            <RoleGuard roles={[Roles.AGENT]}>
              <DialerAgent />
            </RoleGuard>
          }
        />
        <Route path={PrivateRoutes.CONTACTS}
          element={
            <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
              <Contacts />
            </RoleGuard>
          }
        />
        <Route path={PrivateRoutes.VOICE}
          element={
            <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
              <Voice />
            </RoleGuard>
          }
        />
        <Route path={PrivateRoutes.SCRIPT}
          element={
            <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
              <Script />
            </RoleGuard>
          }
        />
        <Route path={PrivateRoutes.DASHBOARD}
          element={
            <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
              <Dashboard />
            </RoleGuard>
          }
        />
        <Route path={`${PrivateRoutes.SUMMARY}/:callsessionid`}
          element={
            <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
              <Summary />
            </RoleGuard>
          }
        />
        <Route path={PrivateRoutes.MANAGEMENT}
          element={
            <RoleGuard roles={[Roles.ADMIN]}>
              <Management />
            </RoleGuard>
          }
        />
        <Route path={PrivateRoutes.SETTINGS}
          element={
            <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
              <Settings />
            </RoleGuard>
          }
        >
          <Route path={PrivateRoutes.SETTINGS_ACCOUNT}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <Account />
              </RoleGuard>
            }
          />
          <Route path={`${PrivateRoutes.SETTINGS_ACCOUNT_V2}`}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <FeatureFlagGuard features={[FeatureFlags.ACCOUNT_SETTINGS]}>
                  <AccountView />
                </FeatureFlagGuard>
              </RoleGuard>
            }
          />
          <Route path={PrivateRoutes.SETTINGS_PHONE}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <CallerIdContainer />
              </RoleGuard>
            }
          />
            <Route path={PrivateRoutes.SETTINGS_ACTIVE_SESSIONS}
            element={
                <RoleGuard roles={[Roles.ADMIN]}>
                    <SessionsView/>
                </RoleGuard>
            }/>
          <Route path={PrivateRoutes.SETTINGS_OUTREACH}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <Outreach />
              </RoleGuard>
            }
          />
          <Route path={PrivateRoutes.SETTINGS_OUTREACH_MAPPINGS}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <OutreachMappingsView />
              </RoleGuard>
            }
          />
          <Route path={PrivateRoutes.SETTINGS_HUBSPOT}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <Hubspot />
              </RoleGuard>
            }
          />
          <Route path={PrivateRoutes.SETTINGS_HUBSPOT_MAPPINGS}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <HubspotMappingsView />
              </RoleGuard>
            }
          />
          <Route path={PrivateRoutes.SETTINGS_HUBSPOT_CUSTOM}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <HubspotCustomApp />
              </RoleGuard>
            }
          />
          <Route path={PrivateRoutes.SETTINGS_HUBSPOT_CUSTOM_MAPPINGS}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <HubspotMappingsView />
              </RoleGuard>
            }
          />
        </Route>
        <Route path="*" element={<Navigate replace to={PrivateRoutes.HOME} />} />
      </Route>
      <Route path="*" element={<Navigate replace to={PublicRoutes.LOGIN} />} />
    </Route>
    <Route path="*" element={<Navigate replace to={PublicRoutes.LOGIN} />} />
  </>
));

const rootNode = document.getElementById('root');

createRoot(rootNode).render(
  //<StrictMode>
    <App />
  //</StrictMode>
  )

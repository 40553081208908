import routes from "../../constants/routes";
import axios from "axios";
import { CallSessionSuccessResponse } from "../../components/shared/CallSession";
import { logAxiosErrorWithSentry } from "../../core/utils/axios.utils";

type Props = {
  callSessionId: string | undefined;
};

type ResponseProps = {
  callSession?: CallSessionSuccessResponse | null;
  error?: { message: string };
};

const freeSdrStatusOnCallSessionAsync = async ({
  callSessionId,
}: Props): Promise<ResponseProps> => {
  try {
    const callSession = await axios.put<CallSessionSuccessResponse>(
      routes.put.callSession.hangupNest + `/${callSessionId}`
    );
    return { callSession: callSession.data };
  } catch (e) {
    const message = "Something happened freeing the sdr status on call session";

    if (axios.isAxiosError(e)) {
      logAxiosErrorWithSentry({
        payload: e,
        description: message,
      });
    }

    return { error: { message } };
  }
};

export default freeSdrStatusOnCallSessionAsync;

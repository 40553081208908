import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import getDynamicStats from "../../api/Metric/getDynamicStats";
import { DynamicStatsSuccessResponse } from "../shared/Metric";
import { UIStatsBox } from "../../core/ui/UIStatsBox";
import { UIColumn } from "../../core/ui/UIElements";

import { HomepageMessageCircle } from "../shared/HomepageElements";

type Props = {
  onStart: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intervals: { [key: string]: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIntervals: (value: any) => void | undefined;
};

const StartContainer = ({
  onStart,
  intervals,
  setIntervals,
}: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <HomepageMessageCircle
        handleClick={onStart}
        buttonMessage="Let's start"
      />
    </>
  );
};

export default StartContainer;

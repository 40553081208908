import routes from "../../constants/routes";
import axios from "axios";
import { DialingStatusResponse } from "../../components/shared/CallSession";

type Props = {
  callSessionId: string;
};

type ResponseProps = {
  data?: DialingStatusResponse;
  error?: { message: string; status: string };
};

const getDialingStatus = async ({
  callSessionId,
}: Props): Promise<ResponseProps> => {
  try {
    const response = await axios.get<DialingStatusResponse>(
      routes.get.callSession.getDialingStatusNest + `/${callSessionId}`
    );
    return { data: response.data };
  } catch (error) {
    let errorObj;
    if (typeof error === "object" && error !== null) {
      const status = error.toString();
      errorObj = {
        error: {
          message: "Error getting dialing status, try again later.",
          status: status.includes("404") ? "404" : "",
        },
      };
    }

    return errorObj;
  }
};

export default getDialingStatus;

import routes from "../../constants/routes";
import axios from "axios";
import { User } from "../../types";
import { ApiResponse } from "../../types/ApiResponse";

type ResponseProps = {
  data?: User[] | [];
  totalPages?: number;
  totalCount?: number;
  pageNumber?: number;
  error?: { message: string };
};

const getUsersAsync = async (
  pageNumber: number,
  pageLimit: number,
  search: string,
  filters: string[]
): Promise<ApiResponse<ResponseProps>> => {
  try {
    const { data } = await axios.get(
      `${routes.get.users.getForManagementNest}`,
      {
        params: {
          page: pageNumber,
          pageLimit: pageLimit,
          search,
          filters
        },
      }
    );
    return {
      data: data,
      success: true,
    };
  } catch {
    return { success: false, error: { message: "Error trying to get users" } };
  }
};

export default getUsersAsync;

import { ApiResponse } from "../../types/ApiResponse";
import routes from "../../constants/routes";
import axios from "axios";
import { Recording } from "../../types/Recording";

const getRecordingByIdAsync = async (
  recordingId: string
): Promise<ApiResponse<Recording>> => {
  try {
    const response = await axios.get<Recording>(
      `${routes.get.recordings.getRecordingById}/${recordingId}`
    );
    return { success: true, data: response.data };
  } catch (e) {
    return {
      error: { message: "Error getting recording by ID, try again later." },
    };
  }
};

export default getRecordingByIdAsync;

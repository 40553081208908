import axios from 'axios';
import routes from '../../../../../src/constants/routes';
import { type OutreachAccount } from './dto/get-outreach-accounts.dto';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';

const getOutreachAccounts = async (userId: string, organizationId: string): Promise<ApiResponse<OutreachAccount[]>> => {
  try {
    const url = `${routes.get.outreach.getLinkedAccounts}/${organizationId}/${userId}`;
    const outreachAccounts = await axios.get<OutreachAccount[]>(url);

    return { data: outreachAccounts.data, success: true };
  } catch (e: unknown) {
    return {
      success: false,
      error: { message: 'Error while getting outreach accounts.' },
    };
  }
};

export default getOutreachAccounts;

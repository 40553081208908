import React, { type FC, type FormEvent } from 'react';
import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Modals, type OutreachMappingsViewModel } from '../OutreachMappings.viewmodel';
import { LoadingState } from '../../../../../../../../src/types/LoadingStates.type';
import ButtonIconText from '../../../../../../Common/Buttons/ButtonIconText';
import ButtonOnlyIcon from '../../../../../../Common/Buttons/ButtonOnlyIcon';
import ButtonDelete from '../../../../../../Common/Buttons/ButtonDelete';

const outcomes = [
  {
    value: 'completed',
    txt: 'Completed',
  },
  {
    value: 'no_answer',
    txt: 'No answer',
  },
];

const { LOADING } = LoadingState;

const DispositionModalComponent: FC<{
  viewModel: OutreachMappingsViewModel;
  isOpen: boolean;
}> = ({
  viewModel: { toggleModal, addCustomDisposition, updateDisposition, loadingState, modalState },
  isOpen = false,
}) => {
  const editData = modalState?.editDisposition?.data;
  const isEditModal = modalState.editDisposition.isOpen;

  const { title, outcome, name, closeAction, submitAction, deleteAction } = {
    title: `${isEditModal ? 'Edit or Delete' : 'Add'} Disposition`,
    name: isEditModal ? editData?.customName : undefined,
    outcome: isEditModal ? editData?.outcome : undefined,
    deleteAction: () => {
      toggleModal({
        name: Modals.CONFIRM_DELETE,
        data: editData,
        isOpen: true,
      });

      toggleModal({
        name: Modals.EDIT_DISPOSITION,
        data: null,
        isOpen: false,
      });
    },
    closeAction: () => {
      isEditModal
        ? toggleModal({
            name: Modals.EDIT_DISPOSITION,
            data: null,
            isOpen: false,
          })
        : toggleModal({
            name: Modals.ADD_DISPOSITION,
            data: null,
            isOpen: false,
          });
    },
    submitAction: (name: string, outcome: string): void => {
      if (isEditModal) {
        void updateDisposition(name, outcome);
      } else {
        void addCustomDisposition(name, outcome);
      }
    },
  };

  function handleSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get('name')?.toString();
    const outcome = formData.get('outcome')?.toString();

    if (name === undefined || outcome === undefined) {
      console.log('Missing properties', {
        name,
        outcome,
      });
      return;
    }

    submitAction(name, outcome);
  }

  return isOpen ? (
    <>
      <div className="grid overflow-y-auto overflow-x-hidden fixed inset-0 z-50 place-items-center w-full h-modal md:h-full bg-black bg-opacity-50">
        <div className="relative p-4 w-full max-w-xl h-full md:h-auto">
          <div className="relative p-4 bg-white rounded-lg shadow sm:p-5">
            <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5">
              <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
              <ButtonOnlyIcon icon={faClose} iconType="close-modal" onClick={closeAction} />
            </div>

            <form onSubmit={handleSubmit} className="flex flex-col gap-16">
              <div className="grid gap-4 mb-4 sm:grid-cols-1">
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    defaultValue={name}
                    className="bg-gray-50 border border-solid border-app-gray-400 text-gray-900 text-sm rounded-lg focus:outline-none focus-visible:ring-1 focus:ring-app-blue-lighter focus:border-app-blue-light block w-full p-2.5"
                    placeholder="Type disposition name"
                    required={true}
                  />
                </div>
                <div>
                  <label htmlFor="outcome" className="block mb-2 text-sm font-medium text-gray-900">
                    Call outcome
                  </label>
                  <select
                    id="outcome"
                    name="outcome"
                    className="bg-gray-50 border border-solid border-app-gray-400 text-gray-900 text-sm rounded-lg focus:outline-none focus-visible:ring-1 focus:ring-app-blue-lighter focus:border-app-blue-light block w-full p-2.5"
                    defaultValue={outcome}
                    required={true}
                  >
                    {outcomes.map((item) => (
                      <option key={item.value + item.txt} value={item.value}>
                        {item.txt}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex gap-4 justify-between">
                <span>{isEditModal && <ButtonDelete type="button" onClick={deleteAction} />}</span>
                <span className="flex gap-4 justify-end">
                  <ButtonIconText
                    icon={faPlus}
                    type="submit"
                    text="Save"
                    loading={loadingState.addCustomDisposition === LOADING}
                  />

                  <ButtonIconText icon={faClose} type="reset" text="Discard" onClick={closeAction} />
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default DispositionModalComponent;

import routes from "../../constants/routes";
import axios from "axios";
import { CallLog } from "../../types/CallLogTypes";
import updateCallLogAsync from "./updateCallLogAsync";
import { logAxiosErrorWithSentry } from "../../core/utils/axios.utils";

type Props = {
  sdrId: string | undefined;
  callId: string;
};

export type SdrCallLogSuccessResponse = {
  enterConferenceDateTime: string;
  id: string;
  sdrId: string;
  callId: string;
};

type ResponseProps = {
  callLog?: CallLog | null;
  deleteError?: { message: string };
};

const deleteSDRCallLogAndUpdateCallLogAsync = async ({
  sdrId,
  callId,
}: Props): Promise<ResponseProps> => {
  try {
    await axios.delete<SdrCallLogSuccessResponse>(
      routes.delete.twilio.deleteSdrLogNest + `/${sdrId}`
    );
    const callLog = await updateCallLogAsync({ callId, status: "finished" });
    return { callLog: callLog.data };
  } catch (e) {
    const message =
      "Something went wrong deleting the sdr callLog or updating callLog";

    if (axios.isAxiosError(e)) {
      logAxiosErrorWithSentry({
        payload: e,
        description: message,
      });
    }

    return {
      deleteError: { message },
    };
  }
};

export default deleteSDRCallLogAndUpdateCallLogAsync;

import { type FeatureFlag } from '@/src-v2/Data/DataSource/sync/feature-flags/entities/feature-flags.entity';
import { type FeaturesDto } from '../dto/features.dto';

export function transformOnlyFlags(featureFlag: FeatureFlag): FeaturesDto {
  return {
    outreach: featureFlag?.outreach,
    hubspot: featureFlag?.hubspot,
    hubspotCustom: featureFlag?.hubspotCustom,
    fourMativ: featureFlag?.fourMativ,
    numbersPool: featureFlag?.numbersPool,
    accountSettings: featureFlag?.accountSettings,
    callerId: featureFlag?.callerId,
    contactsFilter: featureFlag?.contactsFilter,
    userSettings: featureFlag?.userSettings,
  };
}

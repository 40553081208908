export function shouldHaveAtLeastOneProp(props: Record<string, unknown>): void {
  let hasAtLeastOneProp = false;
  if (props === undefined || props === null) {
    throw new Error("Props cannot be null or undefined");
  }

  for (const prop in props) {
    if (props[prop] !== undefined && props[prop] !== null) {
      hasAtLeastOneProp = true;
    }
  }

  if (!hasAtLeastOneProp) {
    throw new Error(
      `Component should have at least one defined property: ${Object.keys(
        props
      ).toString()}`
    );
  }
}

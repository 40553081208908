import routes from "../../constants/routes";
import axios from "axios";
import { ApiResponse } from "../../types/ApiResponse";
import { validateCodeResponse } from "../../types";

const validateVerificationCode = async (
  userId: string,
  verificationCode: string
): Promise<ApiResponse<validateCodeResponse>> => {
  try {
    const body = {
      userId,
      verificationCode,
    };

    const { status, data } = await axios.post(
      routes.post.users.password.validateVerificationCode,
      body
    );

    return {
      success: true,
      data: {
        status: status,
        isVerified: data.isVerified,
        verificationCode: data.verificationCode,
      },
    };
  } catch (e) {
    return {
      success: false,
      error: { message: "Verification codes do not match." },
    };
  }
};

export default validateVerificationCode;

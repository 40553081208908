import routes from "../../constants/routes";
import axios from "axios";
import { NumberPool } from "../../types";

type ResponseProps = {
  number?: NumberPool;
  error?: { message: string };
};

const deleteUserNumberAsync = async (
  numberId: string
): Promise<ResponseProps> => {
  try {
    const number = await axios.delete<NumberPool>(
      `${routes.delete.twilio.deleteUserNumber}/${numberId}`
    );

    return { number: number.data };
  } catch {
    return { error: { message: "Error trying to delete number" } };
  }
};

export default deleteUserNumberAsync;

import axios from 'axios';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import routes from '../../../../../src/constants/routes';

const deleteCustomDisposition = async (
  organizationId: string,
  nonce: string,
  callDispositionId: string,
): Promise<ApiResponse<unknown>> => {
  try {
    const url = `${routes.delete.outreach.deleteCustomDisposition}/${organizationId}/${nonce}/${callDispositionId}`;
    const mappings = await axios.delete<any>(url);

    return {
      success: true,
      data: mappings.data,
    };
  } catch (e: unknown) {
    return {
      success: false,
      error: {
        message:
          'An error occurred while deleting outreach dispositions If the issue persists, please contact customer support.',
      },
    };
  }
};

export default deleteCustomDisposition;

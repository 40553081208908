import ActiveSessionsUseCase from '@usecase/active-sessions/activeSessionsUseCase';
import { ActiveCallSession, type CallSession } from '@datasource/contacts-nest/callSession/entities/callSession.entity';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { showUIToast, UI_TOAST_TYPES } from '@/src/core/ui/UIToast';
import { type UpdateCallSessionDto } from '@datasource/contacts-nest/callSession/entities/updateCallSessionDto';
import { type ApiResponse } from '@/src/types/ApiResponse';

interface ActiveSessionsViewModelProps {
  fetchSessionInfo: () => Promise<ActiveCallSession[] | undefined>;
  sessionsData: ActiveCallSession[] | undefined;
  FormSchema: z.ZodObject<{ sessionStatus: z.ZodNullable<z.ZodString>; sdrStatus: z.ZodNullable<z.ZodString> }>;
  toggleResumePauseSession: (
    data: z.infer<z.ZodObject<{ sessionStatus: z.ZodNullable<z.ZodString>; sdrStatus: z.ZodNullable<z.ZodString> }>>,
    updatedIndex: string,
  ) => Promise<void>;
}

function ActiveSessionsViewmodel(): ActiveSessionsViewModelProps {
  const [sessionsData, setSessionsData] = useState<ActiveCallSession[] | undefined>([]);
  const { getActiveSessionsInfo, getCallSession, updateSessionStatus } = ActiveSessionsUseCase();

  const FormSchema = z.object({
    sessionStatus: z.string().nullable(),
    sdrStatus: z.string().nullable(),
  });

  async function fetchSessionInfo(): Promise<ActiveCallSession[] | undefined> {
    const { data } = await getActiveSessionsInfo();
    try {
      const sessionOverview = data?.map((session) => {
        return new ActiveCallSession(
          session.callSessionId,
          session.sdrId,
          session.status,
          session.sdrStatus,
          session.startAt,
          session.sdrName,
          session.organization,
          session.organizationId,
          session.agents,
        );
      });

      if (data?.length === 0) {
        showUIToast({
          type: UI_TOAST_TYPES.INFO,
          text: `There are not current active sessions`,
        });
      }
      setSessionsData(sessionOverview);
      return sessionOverview;
    } catch (err) {
      console.error(err);
    }
  }

  function selectUpdateBodies(
    callSessionId: string,
    session: ApiResponse<CallSession>,
    formData: z.infer<typeof FormSchema>,
  ): UpdateCallSessionDto | undefined {
    let body;
    if (formData.sessionStatus !== null && formData.sdrStatus === null) {
      body =
        session.data?.status !== formData.sessionStatus
          ? { id: callSessionId, status: formData.sessionStatus }
          : undefined;
    }

    if (formData.sessionStatus === null && formData.sdrStatus !== null) {
      body =
        session.data?.sdrStatus !== formData.sdrStatus
          ? { id: callSessionId, sdrStatus: formData.sdrStatus }
          : undefined;
    }

    if (formData.sessionStatus !== null && formData.sdrStatus !== null) {
      body = { id: callSessionId, status: formData.sessionStatus, sdrStatus: formData.sdrStatus };
    }
    return body;
  }

  async function updateSessionStatuses(data: z.infer<typeof FormSchema>, updatedIndex: string): Promise<void> {
    const callSessionId = document.getElementById(`callSessionId-${updatedIndex}`)?.innerText;
    if (callSessionId !== undefined) {
      await getCallSession(callSessionId).then(async (session) => {
        const body = selectUpdateBodies(callSessionId, session, data);

        if (body?.status !== undefined || body?.sdrStatus !== undefined) {
          await updateSessionStatus(callSessionId, body);
          showUIToast({
            type: UI_TOAST_TYPES.INFO,
            text: `Call Session updated`,
          });
          await fetchSessionInfo();
        } else {
          showUIToast({
            type: UI_TOAST_TYPES.ERROR,
            text: 'Error: Please select a status to change before submitting',
          });
        }
      });
    } else {
      showUIToast({
        type: UI_TOAST_TYPES.ERROR,
        text: 'Error on updating Call Session',
      });
      throw new Error('No callSessionId was found');
    }
  }

  useEffect(() => {
    void fetchSessionInfo();
  }, [setSessionsData]);

  return {
    fetchSessionInfo,
    sessionsData,
    toggleResumePauseSession: updateSessionStatuses,
    FormSchema,
  };
}

export default ActiveSessionsViewmodel;
